import { classNames } from '@/common/utils/classNames'
import { useShowcasesPageStore } from '../store/jobPostsPageStore.ts'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'
import { UBadge } from '@/common/components/UBadge'
import { useNavigate } from 'react-router-dom'
import { showcaseStatusNameMapper } from '@/common/mappers/showcaseStatusMapper'
import { useAppLayoutStore } from '@/layout/appLayout/AppLayout.store'
import { Button } from '@nextui-org/react'
import moment from 'moment'

const statusesClass: Record<string, string> = {
  offline: 'text-gray-500 bg-gray-100/10',
  Active: 'text-green-400 bg-green-400/10',
  Inactive: 'text-rose-400 bg-rose-400/10',
}

export const JobPostingList = () => {
  const { getShowcaseListByJobPostId, setSelectedJobPostId, jobPostList, loading } = useShowcasesPageStore()

  const { addToRecentlyVisitedJobPostsList } = useAppLayoutStore()

  const nav = useNavigate()

  const heightClass = 'h-[calc(100vh-64px-73px)] sm:h-[calc(100vh-64px-88px)]'

  return loading ? (
    <div
      className={`divide-y w-full divide-white/5 ${heightClass} flex flex-col gap-y-2 items-center justify-center overflow-y-hidden`}
    >
      <ULoadingAnimation />
      Loading...
    </div>
  ) : (
    <ul role="list" className={`divide-y divide-white/5 overflow-auto ${heightClass}`}>
      {jobPostList?.map((jobPost) => (
        <li
          key={jobPost.Id}
          className={`relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-slate-700 transition-background`}
          onClick={() => {
            nav(`/app/job-posts/${jobPost.Id}`)
            setSelectedJobPostId(jobPost.Id)
            getShowcaseListByJobPostId(jobPost.Id)
            addToRecentlyVisitedJobPostsList(jobPost)
          }}
        >
          <div className="min-w-0 flex-auto">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-3">
                <div className={classNames(statusesClass[jobPost.Status__c], 'flex-none rounded-full p-1')}>
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
                <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                  <a className="flex gap-x-2">
                    <span className="truncate">{jobPost.Name}</span>
                    <span className="absolute inset-0" />
                  </a>
                </h2>
              </div>
              <div className="hidden md:block whitespace-nowrap font-thin text-slate-400 text-xs mt-1">
                {jobPost?.CreatedDate ? <>{moment(jobPost.CreatedDate).format('MMMM DD, YYYY')}</> : ''}
              </div>
            </div>

            <div className="hidden md:block whitespace-nowrap font-thin text-slate-400 text-xs ml-7">
              {jobPost?.Job_Profile__r?.Name}
            </div>
            <div className="mt-3 flex items-center justify-between gap-x-2.5 text-xs leading-5 text-gray-400">
              {/* <p className="truncate">
                {htmlToPlainText(jobPost?.Job_Description__c ?? '')}
              </p> */}

              <div className="flex items-end flex-wrap gap-x-2">
                <Button
                  className="text-xs px-2 max-h-[24px] rounded-full bg-white/10"
                  size="sm"
                  variant="bordered"
                  onClick={() => {
                    nav(`/app/job-applicants/${jobPost.Id}`)
                  }}
                >
                  Total applicants: {jobPost?.Total_Applicants__c ?? 0}
                </Button>

                <UBadge
                  variant="default"
                  fontType="normal"
                  text={`Total showcases: ${
                    jobPost?.Showcases__r?.records.filter(
                      // NOTE => to exclude inner status such as pending and recruiter reviewed
                      (el) =>
                        el.Status__c?.toLowerCase() !== 'pending' &&
                        el.Status__c?.toLowerCase() !== 'recruiter reviewed'
                    ).length ?? 0
                  }`}
                />
                {(jobPost?.Showcases__r?.totalSize ?? 0) > 0 && (
                  <>
                    <UBadge
                      variant="success"
                      fontType="normal"
                      text={`${showcaseStatusNameMapper['client_approved']}: ${jobPost?.Showcases__r?.records.filter((showcase) => showcase.Status__c?.toLowerCase() === 'client_approved').length}`}
                    />

                    <UBadge
                      variant="warning"
                      fontType="normal"
                      text={`${showcaseStatusNameMapper['send for client review']}: ${jobPost?.Showcases__r?.records.filter((showcase) => showcase.Status__c?.toLowerCase() === 'send for client review').length}`}
                    />

                    <UBadge
                      variant="danger"
                      fontType="normal"
                      text={`${showcaseStatusNameMapper['client_rejected']}: ${jobPost?.Showcases__r?.records.filter((showcase) => showcase.Status__c?.toLowerCase() === 'client_rejected').length}`}
                    />
                  </>
                )}
              </div>

              <div className="hidden items-center gap-x-1 lg:flex">
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="whitespace-nowrap">{jobPost?.Status__c === 'Active' ? 'Open' : 'Closed'}</p>
              </div>
            </div>
          </div>

          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        </li>
      ))}
    </ul>
  )
}
