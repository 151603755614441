import { useService } from '@/common/hooks/useService'
import { getJobApplicantsList } from '@/common/services/getJobApplicantsList'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import JobApplicantsHeader from '../components/JobApplicantsHeader'
import JobApplicantsTable from '../components/JobApplicantsTable'
import { JobApplicant } from '../types/JobApplicants'
import { useJobApplicantPageStore } from '@/presentation/JobApplicants/store/jobApplicantPageStore.ts'
import { JobApplicantCharts } from '@/presentation/JobApplicants/components/JobApplicantCharts.tsx'
import { JobApplicantsCriteria } from '@/presentation/JobApplicants/components/JobApplicantsCriteria.tsx'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'

export function JobApplicantsPage() {
  const { jobPostId } = useParams()
  const { normalizeJobApplicants, newNormalizeJobApplicants, normalizedJobApplicants } = useJobApplicantPageStore()

  const { data, call, isLoading } = useService<JobApplicant[]>(getJobApplicantsList)

  useEffect(() => {
    call(jobPostId)
  }, [])

  useEffect(() => {
    if (data?.some((jobApplicant) => jobApplicant.Rubric__c && jobApplicant.AI_Score__c)) {
      // Pathway A
      newNormalizeJobApplicants(data)
    } else if (data !== null && data !== undefined) {
      // Pathway B
      normalizeJobApplicants(data)
    }
  }, [data])

  return isLoading ? (
    <div>
      <div className="divide-y w-full divide-white/5 h-[173px] sm:h-[140px] lg:h-[calc(100vh-77px-64px)] flex flex-col gap-y-4 items-center justify-center overflow-y-hidden">
        <ULoadingAnimation />
        Loading job applicants
      </div>
    </div>
  ) : (data?.length ?? 0) > 0 ? (
    <div className="p-6 max-h-[calc(100vh-64px)] overflow-y-auto w-full">
      {data && normalizedJobApplicants && <JobApplicantsHeader data={data} />}
      {data && <JobApplicantCharts />}
      {data && normalizedJobApplicants && <JobApplicantsCriteria />}
      {data && normalizedJobApplicants && <JobApplicantsTable />}
    </div>
  ) : (
    <div> Not found</div>
  )
}
