import { api } from '@/config/api'

export const updateShowcaseStatus = (data: {
  showcaseId: string
  showcaseStatus: string
  clientFeedback: string
  statusChangedBy?: string
  emailAddress?: string
}) => {
  const res = api.post('/updateShowcaseStatus', data)

  return res
}
