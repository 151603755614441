import { Column } from '@ant-design/plots'
import { useEffect, useState } from 'react'
import { useJobApplicantPageStore } from '@/presentation/JobApplicants/store/jobApplicantPageStore.ts'
import { NormalizedJobApplicant } from '@/common/types/NormalizedJobApplicant.ts'
import './CustomG2Tolltip.scss'

const getScrollbarConfig = (data: NormalizedJobApplicant[]) => {
  return data.length > 10
    ? {
        x: {
          style: {
            trackFill: '#fff',
            trackFillOpacity: 1,
            thumbFill: '#fff',
            thumbFillOpacity: 0.6,
          },
        },
      }
    : {}
}

export const JobApplicantCharts = () => {
  const { getDisplayedJobApplicants } = useJobApplicantPageStore()

  const [config, setConfig] = useState(() => ({
    animate: {
      enter: {
        type: 'growInY',
        duration: 600,
      },
    },

    yField: 'overallScore',
    xField: 'name',
    axis: {
      x: {
        // label: data.length < 10,
        labelFormatter: (v: string) => {
          return v.length > 10 ? v.slice(0, 10) + '...' : v
        },
        labelFill: '#fff',
        lineStroke: '#fff',
        line: true,
        animate: true,
      },
      y: {
        labelFill: '#fff',
        grid: true,
        gridStroke: '#fff',
      },
    },
    scale: {
      y: {
        domain: [0, 10],
        nice: true,
      },
    },
    style: {
      radiusTopLeft: 8,
      radiusTopRight: 8,
      fill: '#6366f1',
    },

    slider: {},

    // tooltip: (d: JobApplicantsCriterion) => ({
    //   value: d.overallScore,
    //   name: 'Overall score',
    // }),
  }))

  useEffect(() => {
    setTimeout(() => {
      setConfig({
        ...config,
        slider: {
          y: {
            brushable: false,

            style: {
              handleLabelFill: '#fff',
            },
            formatter: (value: number) => {
              return Math.round(10 - value * 10).toString()
            },
          },
        },
      })
    }, 600)
  }, [])

  return (
    <Column
      interaction={{
        tooltip: {
          render: (_: unknown, options: { items: NormalizedJobApplicant[]; title: string }) => {
            return `<div class="bg-gray-700 text-white rounded p-4">
                <h2 className="leading-7 text-lg font-bold"> ${options.title} </h2>
                <p> AI score: ${options.items[0].value} </p> 
            </div>`
          },
        },
      }}
      data={getDisplayedJobApplicants()}
      scrollbar={getScrollbarConfig(getDisplayedJobApplicants())}
      {...config}
      className="custom-g2-tooltip"
    />
  )
}
