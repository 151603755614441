import { api } from '@/config/api'
import { JobPosting } from '@/presentation/JobPosts/types/JobPosting'
import { trimId } from '../utils/trimId'

export const getJobPostingByJobProfileId = async (jobProfileId: string) => {
  const res = await api.get<JobPosting[]>(`/getJobPostingByJobProfileId?jobProfileId=${jobProfileId}`)

  res.data.forEach((item) => {
    item.Id = trimId(item.Id)
  })
  return res.data
}
