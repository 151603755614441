import * as Sentry from '@sentry/react'
import { Contact } from '../types/Contact'

const getContact = () => {
  return JSON.parse(localStorage.getItem('contact') || '{}') as Contact
}

export class SentryHelper {
  public static logShowcaseView(message: string, showCaseId: string, jobPost: string) {
    Sentry.captureEvent({
      message: 'SC: ' + message,
      level: 'info',
      tags: {
        type: 'interaction',
        user_name: getContact().Name ?? '',
        showcase_id: showCaseId,
        job_post: jobPost,
      },
      user: {
        email: getContact().Email ?? '',
        name: getContact().Name ?? '',
      },
    })
  }

  public static logUserLogin(message: string) {
    Sentry.captureEvent({
      message: 'LOGIN: ' + message,
      level: 'info',
      tags: {
        type: 'interaction',
        account_id: getContact()?.AccountId,
        contact_id: getContact()?.Id,
      },
      user: {
        email: getContact().Email ?? '',
        name: getContact().Name ?? '',
      },
    })
  }
}
