import { classNames } from '@/common/utils/classNames'

const statusesClass: Record<string, string> = {
  offline: 'text-gray-500 bg-gray-100/10',
  Active: 'text-green-400 bg-green-400/10',
  Inactive: 'text-rose-400 bg-rose-400/10',
}

export type Props = {
  jobPostStatus: string
  jobPostName: string
  jobProfileName: string
  humanInterviewScore?: number | null
  resumeMatchScore?: number | null
}

export const ReportPanel = (props: Props) => {
  return (
    <>
      {/* Heading */}
      <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
        <div>
          <div className="flex items-center gap-x-3">
            <div className={classNames(statusesClass[props.jobPostStatus], 'flex-none rounded-full p-1')}>
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h1 className="flex gap-x-3 text-base leading-7">
              <span className="font-semibold text-white">{props.jobPostName}</span>
            </h1>
          </div>
          <div className="hidden md:block whitespace-nowrap font-thin text-slate-400 text-xs ml-7">
            {props.jobProfileName}
          </div>
          <p className="mt-2 text-xs leading-6 text-gray-400"></p>
        </div>
      </div>

      {/* Stats */}
      <div className="grid grid-cols-1 bg-gray-700/10 md:grid-cols-2 rounded">
        {[
          {
            name: 'Screen interview score',
            value: props.humanInterviewScore,
          },
          {
            name: 'AI score',
            value: props.resumeMatchScore,
          },
        ].map((stat, statIdx) => (
          <div
            key={stat.name}
            className={classNames(
              statIdx % 2 === 1 ? 'md:border-l' : statIdx === 2 ? 'md:border-l' : '',
              'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8'
            )}
          >
            <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
            <p className="mt-2 flex items-baseline gap-x-2">
              <span className="text-4xl font-semibold tracking-tight text-white">{stat.value ?? 'N/A'}</span>
            </p>
          </div>
        ))}
      </div>
    </>
  )
}
