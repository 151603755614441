import { getAuth, fetchSignInMethodsForEmail } from 'firebase/auth'

export const checkSignInMethods = async (email: string) => {
  const auth = getAuth()
  const signInMethods = await fetchSignInMethodsForEmail(auth, email)

  const signInMethodsMap = signInMethods.map((method) => {
    switch (method) {
      case 'password':
        return 'Password'
      case 'google.com':
        return 'Google'
      case 'microsoft.com':
        return 'Microsoft'
      default:
        return method // Handle any other providers if necessary
    }
  })

  return signInMethodsMap
}
