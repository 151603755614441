import { Button } from '@nextui-org/react'
import { motion, AnimatePresence } from 'framer-motion'

type Props = {
  display: boolean
  onDelete: () => void
}

export default function RubricTableToolbox(props: Props) {
  const { display } = props
  return (
    <AnimatePresence>
      {display && (
        <motion.div
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
          transition={{ type: 'spring', stiffness: 100, damping: 20 }}
          className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8"
        >
          <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-warning-400 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <p className="text-sm leading-6 text-white">
              <a href="#">
                <strong className="font-semibold mx-2">Actions</strong>
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
            <Button color="warning" variant="faded" onClick={props.onDelete} className="bg-gray-900">
              Delete all selected guidelines
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
