import { useEffect } from 'react'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '@/config/firebase.ts'
import { api } from '@/config/api.ts'
import { Contact } from '@/common/types/Contact.ts'

export type UseAuthOptions = {
  onVerify?: (insider: boolean) => void
  onUnVerify?: () => void
}
export const useAuth = (options: UseAuthOptions) => {
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user?.email) {
        console.log('here')
        const domain = user.email.split('@')[1]
        if (domain.toLowerCase() === 'urrly.com') {
          options.onVerify?.(true)
          return true
        }

        api
          .post<Contact[]>('/findContactByEmail', {
            email: user.email,
          })
          .then((res) => {
            if (res.data.length === 0) {
              signOut(auth)
              options.onUnVerify?.()
              throw new Error('No user found')
            } else {
              options.onVerify?.(false)
              return true
            }
          })
          .catch(() => {
            signOut(auth)
            options.onUnVerify?.()
            return false
          })
      } else {
        options.onUnVerify?.()
        return false
      }
    })
  }, [])
}
