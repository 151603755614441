import { UResumePanel } from '@/common/components/Overview/UResumePanel'
import { useService } from '@/common/hooks/useService'
import { getJobApplicantById } from '@/common/services/getJobApplicantById'
import { ReportPanel } from '@/presentation/JobApplicants/components/ReportPanel'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'
import { Button } from '@nextui-org/react'
import { BackwardIcon, CalendarDaysIcon, ForwardIcon } from '@heroicons/react/24/outline'
import { ApplicantBottomBanner } from '../components/ApplicantBottomBanner'
import { useJobApplicantPageStore } from '../store/jobApplicantPageStore'
import { JobApplicant } from '../types/JobApplicants'
import { getJobApplicantsList } from '@/common/services/getJobApplicantsList'
import { updateApplicantStage } from '@/common/services/updateApplicantStage'
import { Toast, Props as ToastProps } from '../components/Toast'

export function JobApplicantByIdPage() {
  const { applicantId } = useParams()

  const accountId = localStorage.getItem('accountId')

  const { call, data, isLoading } = useService(getJobApplicantById)

  useEffect(() => {
    if (applicantId) {
      call(applicantId)
    }
  }, [applicantId])

  const nav = useNavigate()

  const { normalizeJobApplicants, newNormalizeJobApplicants, normalizedJobApplicants } = useJobApplicantPageStore()

  const { call: getJobApplicationsList, isLoading: jobApplicationLoading } =
    useService<JobApplicant[]>(getJobApplicantsList)

  const [isLast, setIsLast] = useState(false)

  const [isFirst, setIsFirst] = useState(false)

  useEffect(() => {
    if (normalizedJobApplicants?.length <= 0 && data) {
      //
      getJobApplicationsList(data.Job_Posting__c).then((res) => {
        if (res?.some((jobApplicant) => jobApplicant.Rubric__c && jobApplicant.AI_Score__c)) {
          // Pathway A
          newNormalizeJobApplicants(res)
        } else if (res !== null && res !== undefined) {
          // Pathway B
          normalizeJobApplicants(res)
        }
      })
    }
  }, [normalizedJobApplicants, data])

  const goToPreviousApplication = () => {
    setIsLast(false)
    setIsFirst(false)
    const currentJobApplicationIndex = normalizedJobApplicants.findIndex((ap) => ap.id === applicantId)

    if (currentJobApplicationIndex === -1) {
      // TODO => throw error
      console.log('not exist')
      return -1
    }

    const prevJobApplicantId = normalizedJobApplicants[currentJobApplicationIndex - 1]?.id

    if (prevJobApplicantId) {
      nav(`/app/job-applicant/${prevJobApplicantId}`)
    } else {
      setIsFirst(true)
    }
  }

  const goToNextApplication = () => {
    setIsLast(false)
    setIsFirst(false)
    const currentJobApplicationIndex = normalizedJobApplicants.findIndex((ap) => ap.id === applicantId)

    if (currentJobApplicationIndex === -1) {
      // TODO => throw error
      console.log('not exist')
      return -1
    }

    const nextJobApplicantId = normalizedJobApplicants[currentJobApplicationIndex + 1]?.id

    if (nextJobApplicantId) {
      nav(`/app/job-applicant/${nextJobApplicantId}`)
    } else {
      setIsLast(true)
    }
  }

  const { call: updateStage, isLoading: updateStageLoading } = useService(updateApplicantStage)

  const [displayToastConfig, setDisplayToastConfig] = useState<Partial<ToastProps>>({
    display: false,
  })

  const updateStageByApplicantId = () => {
    updateStage(applicantId, '4').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        setDisplayToastConfig({
          display: true,
          title: 'Success',
          text: 'Schedule meeting email sent to the applicant.',
          color: 'success',
        })
      } else {
        setDisplayToastConfig({
          display: true,
          title: 'Error',
          text: 'Failed to send email.',
          color: 'error',
        })
      }
    })
  }

  return (
    <div className="p-6 max-h-[calc(100vh-64px)] overflow-y-auto overflow-x-hidden">
      {isLoading && jobApplicationLoading ? (
        <div>
          <div className="divide-y w-full divide-white/5 h-[173px] sm:h-[140px] lg:h-[calc(100vh-77px-64px)] flex flex-col gap-y-4 items-center justify-center overflow-y-hidden">
            <ULoadingAnimation />
            Loading showcase...
          </div>
        </div>
      ) : (
        data && (
          <>
            <Toast
              display={displayToastConfig.display as boolean}
              title={displayToastConfig.title}
              text={displayToastConfig.text}
              color={displayToastConfig.color}
              onClose={() => {
                setDisplayToastConfig({ display: false })
              }}
            />
            <div className="min-w-0 flex-1 mb-6">
              <div className="flex items-center gap-2 w-full justify-between">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-1">
                    <li>
                      <div className="flex">
                        <a
                          className="text-sm font-medium text-gray-300 hover:text-orange-400 transition-colors cursor-pointer"
                          onClick={() => {
                            nav(-1)
                          }}
                        >
                          Job applicants
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <a className="ml-1 text-sm font-medium text-gray-300">{data.Name}</a>
                      </div>
                    </li>
                  </ol>
                </nav>

                <div className="hidden md:flex items-center gap-2">
                  <Button
                    size="sm"
                    variant="light"
                    className="flex items-center gap-2"
                    onClick={goToPreviousApplication}
                    isDisabled={isFirst}
                  >
                    {isFirst ? (
                      'First application'
                    ) : (
                      <>
                        <BackwardIcon className="h-4 w-4 text-white" />
                        Previous application
                      </>
                    )}
                  </Button>

                  {accountId?.substring(0, 15) === '001Rm00000DOqcQ' && (
                    <Button
                      size="sm"
                      variant="shadow"
                      color="primary"
                      className="flex items-center gap-2"
                      onClick={() => {
                        updateStageByApplicantId()
                      }}
                      isLoading={updateStageLoading}
                    >
                      <CalendarDaysIcon className="w-4 h-4 text-white" />
                      Schedule meeting
                    </Button>
                  )}
                  <Button
                    size="sm"
                    variant="light"
                    className="flex items-center gap-2"
                    onClick={goToNextApplication}
                    isDisabled={isLast}
                  >
                    {isLast ? (
                      'Last application'
                    ) : (
                      <>
                        Next application
                        <ForwardIcon className="h-4 w-4 text-white" />
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <ReportPanel
              humanInterviewScore={data?.Human_Interview_Score__c}
              resumeMatchScore={data?.AI_Score__c ?? data?.AI_Resume_Match_Score__c}
              jobPostStatus={data?.Job_Posting__r?.Status__c ?? ''}
              jobPostName={data?.Job_Posting__r?.Name ?? ''}
              jobProfileName={data?.Job_Profile__r?.Name ?? ''}
            />
            {data && (
              <UResumePanel
                PDFViewerProps={{
                  url: data.Resume_Link__c,
                }}
                ResumeMatchScoreRubricProps={{
                  data: {
                    id: data.Id,
                    resumeMatchDescription: data.AI_Resume_Match_Description__c ?? '',
                    rubric: data.Rubric__c,
                  },
                }}
              />
            )}
          </>
        )
      )}

      <ApplicantBottomBanner
        onMeetSchedule={() => {
          updateStageByApplicantId()
        }}
        onMeetScheduleLoading={updateStageLoading}
        onNext={goToNextApplication}
        onPrev={goToPreviousApplication}
        isLast={isLast}
        isFirst={isFirst}
      />
    </div>
  )
}
