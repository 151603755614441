import './UHtmlRenderer.m.scss'

type Props = {
  htmlText: string
}
export const UHtmlRenderer = (props: Props) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: props.htmlText,
      }}
      className="u-list u-h mx-2"
    ></div>
  )
}
