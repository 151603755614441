import { create } from 'zustand'
import { NormalizedJobApplicant } from '@/common/types/NormalizedJobApplicant.ts'
import { JobApplicant } from '@/presentation/JobApplicants/types/JobApplicants.ts'
import { Criteria, NewGuidelineCriteria } from '@/common/types/Criteria.ts'
import { jobApplicantStageMapper, jobApplicantValueStageMapper } from '@/common/mappers/jobApplicantStageMapper.ts'
import { getJobPostCriterionByJobPostId } from '@/common/services/getJobPostCriterionByJobPostId.ts'
import {
  NewRubricEvaluationCriteria,
  RubricEvaluationCriteria,
} from '@/common/types/RubricTypes/RubricEvaluationCriteria.ts'
import {
  NewRubricEvaluationCriterionMapper,
  RubricEvaluationCriterionMapper,
} from '@/common/mappers/RubricEvaluationCriterionMapper.ts'
import { decodeJsonString } from '@/common/utils/decodeJsonString'
import { getJobPostRubricGuideline } from '@/common/services/getJobPostRubricGuideline'

type JobApplicantPageStore = {
  sortedBy: 'stage' | 'overallScore' | 'applyDate'
  sortDirection: 'asc' | 'desc'

  criterion: Criteria[]
  changeCriteriaActiveStatus: (criteriaGuideline: string) => void

  normalizedJobApplicants: NormalizedJobApplicant[]
  recalculateOverallScoreBasedOnCriterion: (criterion: Criteria[] | NewGuidelineCriteria[]) => void
  setNormalizedJobApplicants: (jobApplicants: NormalizedJobApplicant[]) => void
  normalizeJobApplicants: (jobApplicants: JobApplicant[]) => void
  newNormalizeJobApplicants: (jobApplicants: JobApplicant[]) => void
  sortJobApplicants: (sortKey: 'stage' | 'overallScore' | 'applyDate', direction: 'asc' | 'desc') => void

  onlyShowApplicantsAboveZero: boolean
  setOnlyShowApplicantsAboveZero: (value: boolean) => void
  getDisplayedJobApplicants: () => NormalizedJobApplicant[]
}

const getNumberOfAppliedCriteria = (jobApplicant: NormalizedJobApplicant, criterionList: Criteria[]) => {
  const numberOfAppliedCriteria = criterionList.filter(
    (criteria) =>
      criteria.isActive &&
      jobApplicant.evaluationCriterion
        .filter((el) => el.score?.toString().toLowerCase() !== 'none')
        .findIndex((c) => c.guideline === criteria.guideline) !== -1
  ).length

  return numberOfAppliedCriteria === 0 ? 1 : numberOfAppliedCriteria
}

const calculateOverallScore = (jobApplicant: NormalizedJobApplicant, criterionList: Criteria[]) => {
  const jobApplicantCriterion = jobApplicant.evaluationCriterion
    .filter((criteria) => criterionList.findIndex((c) => c.guideline === criteria.guideline) !== -1)
    .filter((criteria) => criteria.score?.toString().toLowerCase() !== 'none')

  const overallScore =
    jobApplicantCriterion.reduce((acc, evalCriteria) => {
      const criteriaGuideline = evalCriteria.guideline

      const isActive = criterionList.find((criteria) => criteria.guideline === criteriaGuideline)?.isActive ?? false

      if (isActive) {
        return acc + evalCriteria.score
      } else {
        return acc
      }
    }, 0) / getNumberOfAppliedCriteria(jobApplicant, criterionList)

  return Number(overallScore.toFixed(1))
}

const newCalculateOverallScore = (
  jobApplicant: NormalizedJobApplicant,
  guidelineMap: Map<string, NewGuidelineCriteria>,
  mustHaveCount: number
) => {
  let totalWeight = 0
  let totalScore = 0
  let mustHaveMetCount = 0

  for (const evalCriteria of jobApplicant.evaluationCriterion) {
    const matchingGuideline = guidelineMap.get(evalCriteria.guideline)
    if (!matchingGuideline || evalCriteria.score === null || evalCriteria.score === undefined) continue

    const score = evalCriteria.score
    const mustHave = matchingGuideline.mustHave
    const importance = matchingGuideline.importance ?? 0

    if (mustHave) {
      if (score > 8) mustHaveMetCount++
      if (score < 8) return 0 // Reject the applicant if a must-have criterion is not sufficiently met
    } else {
      totalScore += score * importance
      totalWeight += importance
    }
  }

  if (mustHaveMetCount < mustHaveCount) return 0

  const weightedScore = totalWeight ? totalScore / totalWeight : 0
  return Number(weightedScore.toFixed(1))
}

/// 1. Retrieve criterion List and if it is empty we have to get it from jobApplicants array
/// 1.1 Consider if it was null or empty all job applicants should have N/A score
const getCriterionList = async (jobPostId: string, jobApplicants: JobApplicant[]) => {
  let criterion: Criteria[] = await getJobPostCriterionByJobPostId(jobPostId).then((data) =>
    data.map((el) => ({ ...el, isActive: true }))
  )

  if (criterion.length === 0) {
    const baseRubricEvaluationCriterion: RubricEvaluationCriteria[] = jobApplicants
      .map((el) => el.ResumeMatchRubric)
      .map((el) => [
        ...RubricEvaluationCriterionMapper('requirement', el?.Requirements),
        ...RubricEvaluationCriterionMapper('niceToHave', el?.['Nice to haves']),
      ])
      .filter((el) => el !== undefined && el?.length > 0)[0]

    criterion = baseRubricEvaluationCriterion?.map((el) => ({
      guideline: el.guideline,
      type: el.type ?? 'requirement',
      isActive: true,
    }))
  }

  return criterion ?? []
}

/// With this code we're gonna remove all extra criterion that each applicant object might carry
const normalizeEvaluationCriterion = (rubricEvaluationCriterion: RubricEvaluationCriteria[], criterion: Criteria[]) => {
  return rubricEvaluationCriterion.map((re) => {
    const matchingCriterion = criterion.find((c) => c.guideline?.substring(0, 20) === re.guideline?.substring(0, 20))
    return {
      ...re,
      guideline: matchingCriterion?.guideline ?? re.guideline,
    }
  })
}

// REGION START STORE
export const useJobApplicantPageStore = create<JobApplicantPageStore>((set, get) => ({
  onlyShowApplicantsAboveZero: true,
  setOnlyShowApplicantsAboveZero(value) {
    set({ onlyShowApplicantsAboveZero: value })
  },
  getDisplayedJobApplicants() {
    if (get().onlyShowApplicantsAboveZero) {
      return get().normalizedJobApplicants.filter((applicant) => applicant.overallScore > 0)
    } else {
      return get().normalizedJobApplicants
    }
  },

  sortedBy: 'overallScore',
  sortDirection: 'desc',

  criterion: [],
  changeCriteriaActiveStatus(criteriaGuideline: string) {
    const criterion: Criteria[] = get().criterion.map((c) =>
      c.guideline === criteriaGuideline ? { ...c, isActive: !c.isActive } : c
    )

    set({
      criterion: criterion,
    })

    get().recalculateOverallScoreBasedOnCriterion(criterion)
  },

  normalizedJobApplicants: [] as NormalizedJobApplicant[],
  setNormalizedJobApplicants(jobApplicants: NormalizedJobApplicant[]) {
    set({ normalizedJobApplicants: jobApplicants })
  },

  async newNormalizeJobApplicants(jobApplicants: JobApplicant[]) {
    console.log('NEW WAY')

    const newCriterionListRaw = await getJobPostRubricGuideline(jobApplicants[0].Job_Posting__c)

    const newCriterionList: NewGuidelineCriteria[] = newCriterionListRaw.map((el) => ({
      guideline: el.requirement,
      type: el.mustHave ? 'requirement' : 'niceToHave',
      isActive: true,
      id: el.id,
      importance: el.importance,
      mustHave: el.mustHave,
      scoringGuideline: el.scoringGuideline,
    }))

    set({ criterion: newCriterionList })

    const normalizedJobApplicants: NormalizedJobApplicant[] = jobApplicants.map((jobApplicant) => ({
      id: jobApplicant.Id,
      name: jobApplicant.Name,
      email: jobApplicant.Candidate_Email__c ?? '',
      overallScore: jobApplicant.AI_Score__c ?? 0,
      overallScoreOriginal: jobApplicant.AI_Score__c ?? 0,
      applyDate: jobApplicant.Apply_Date__c,

      evaluationCriterion: [
        ...normalizeEvaluationCriterion(
          NewRubricEvaluationCriterionMapper(
            'requirement',
            JSON.parse(decodeJsonString(jobApplicant.Rubric__c ?? '{}'))?.requirements as NewRubricEvaluationCriteria[]
          ),
          newCriterionList
        ),
      ],

      resumeLink: jobApplicant.Resume_Link__c,
      stage: jobApplicantStageMapper[jobApplicant.Stage__c ?? ''] ?? 'N/A',

      pathway: 'new',
    }))

    const postProcessedNormalizedJobApplicants = normalizedJobApplicants
      .map((jobApplicant) => {
        const updatedJobApplicant = { ...jobApplicant }
        const mustHaveCount = newCriterionList.filter((el) => el.mustHave && el.isActive).length
        const guidelineMap = new Map(newCriterionList.filter((c) => c.isActive).map((c) => [c.guideline, c]))

        const overallScore = newCalculateOverallScore(jobApplicant, guidelineMap, mustHaveCount)

        updatedJobApplicant.overallScore = overallScore
        updatedJobApplicant.overallScoreOriginal = overallScore

        return updatedJobApplicant
      })
      .sort((a, b) => b.overallScore - a.overallScore)

    set({ normalizedJobApplicants: postProcessedNormalizedJobApplicants })

    set({ sortedBy: 'overallScore', sortDirection: 'desc' })

    // Code : A simple test to check if the calculated scores are the same or no
    // const oldNormalizedJobApplicants = get().normalizedJobApplicants
    // const oldOverallScores = oldNormalizedJobApplicants.map((el) => el.overallScore)
    // const newOverallScores = postProcessedNormalizedJobApplicants.map((el) => el.overallScore)

    // for (let i = 0; i < oldOverallScores.length; i++) {
    //   if (oldOverallScores[i] !== newOverallScores[i]) {
    //     console.log('Old:', oldOverallScores[i], 'New:', newOverallScores[i])
    //   } else {
    //     console.log("ok")
    //   }
    // }
  },

  // Change should start here...
  // 4. we need to refactor the calculation logic as well.
  async normalizeJobApplicants(jobApplicants: JobApplicant[]) {
    console.log('OLD WAY')

    // 1. get criterion list
    const criterionList = await getCriterionList(jobApplicants[0].Job_Posting__c, jobApplicants)

    set({ criterion: criterionList })

    // 2. normalize job applicants
    const normalizedJobApplicants: NormalizedJobApplicant[] = jobApplicants.map((jobApplicant) => ({
      id: jobApplicant.Id,
      name: jobApplicant.Name,
      email: jobApplicant.Candidate_Email__c ?? '',
      overallScore: jobApplicant.AI_Resume_Match_Score__c,
      overallScoreOriginal: jobApplicant.AI_Resume_Match_Score__c,
      applyDate: jobApplicant.Apply_Date__c,

      evaluationCriterion: [
        ...normalizeEvaluationCriterion(
          RubricEvaluationCriterionMapper('requirement', jobApplicant.ResumeMatchRubric?.Requirements),
          criterionList
        ),
        ...normalizeEvaluationCriterion(
          RubricEvaluationCriterionMapper('niceToHave', jobApplicant.ResumeMatchRubric?.['Nice to haves']),
          criterionList
        ),
      ],

      resumeLink: jobApplicant.Resume_Link__c,
      stage: jobApplicantStageMapper[jobApplicant.Stage__c ?? ''] ?? 'N/A',

      pathway: 'old',
    }))

    // 3. calculate overall score for each job applicant and auto sort it based on the overall score
    const postProcessedNormalizedJobApplicants = normalizedJobApplicants
      .map((jobApplicant) => {
        const updatedJobApplicant = { ...jobApplicant }

        // if criterion is empty we have to set the score to N/A
        const overallScore = calculateOverallScore(jobApplicant, criterionList)

        updatedJobApplicant.overallScore = overallScore
        updatedJobApplicant.overallScoreOriginal = overallScore

        return updatedJobApplicant
      })
      .sort((a, b) => b.overallScore - a.overallScore)

    set({ normalizedJobApplicants: postProcessedNormalizedJobApplicants })
    set({ sortedBy: 'overallScore', sortDirection: 'desc' })
  },

  sortJobApplicants(sortKey, direction) {
    if (sortKey === 'overallScore') {
      const normalizedJobApplicants = get().normalizedJobApplicants
      const sortedJobApplicants = normalizedJobApplicants.sort((a, b) => {
        if (direction === 'asc') {
          return a[sortKey] - b[sortKey]
        } else {
          return b[sortKey] - a[sortKey]
        }
      })
      set({ normalizedJobApplicants: sortedJobApplicants })
      set({
        sortDirection: direction,
        sortedBy: sortKey,
      })
    }

    if (sortKey === 'stage') {
      const normalizedJobApplicants = get().normalizedJobApplicants
      const sortedJobApplicants = normalizedJobApplicants.sort((a, b) => {
        if (direction === 'asc') {
          return jobApplicantValueStageMapper[a.stage] - jobApplicantValueStageMapper[b.stage]
        } else {
          return jobApplicantValueStageMapper[b.stage] - jobApplicantValueStageMapper[a.stage]
        }
      })

      set({ normalizedJobApplicants: sortedJobApplicants })
      set({
        sortDirection: direction,
        sortedBy: sortKey,
      })
    }

    if (sortKey === 'applyDate') {
      const normalizedJobApplicants = get().normalizedJobApplicants
      const sortedJobApplicants = normalizedJobApplicants.sort((a, b) => {
        if (direction === 'asc') {
          return new Date(a.applyDate).getTime() - new Date(b.applyDate).getTime()
        } else {
          return new Date(b.applyDate).getTime() - new Date(a.applyDate).getTime()
        }
      })

      set({ normalizedJobApplicants: sortedJobApplicants })
      set({
        sortDirection: direction,
        sortedBy: sortKey,
      })
    }
  },

  recalculateOverallScoreBasedOnCriterion(criterionList: Criteria[]) {
    const normalizedJobApplicants = get().normalizedJobApplicants

    const updatedJobApplicants = normalizedJobApplicants
      .map((jobApplicant) => {
        const updatedJobApplicant = { ...jobApplicant }
        if (jobApplicant.pathway === 'new') {
          const mustHaveCount = (criterionList as NewGuidelineCriteria[]).filter(
            (el) => el.mustHave && el.isActive
          ).length
          const guidelineMap = new Map(
            (criterionList as NewGuidelineCriteria[]).filter((c) => c.isActive).map((c) => [c.guideline, c])
          )

          const overallScore = newCalculateOverallScore(jobApplicant, guidelineMap, mustHaveCount)

          updatedJobApplicant.overallScore = Number(overallScore.toFixed(1))

          return updatedJobApplicant
        }
        const overallScore = calculateOverallScore(jobApplicant, criterionList)

        updatedJobApplicant.overallScore = Number(overallScore.toFixed(1))

        return updatedJobApplicant
      })
      .sort((a, b) => b.overallScore - a.overallScore)

    set({ normalizedJobApplicants: updatedJobApplicants })
  },
}))
