import { Card, CardBody } from '@nextui-org/react'
import { ReactNode } from 'react'
import { classNames } from '../utils/classNames'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export type Props = {
  title: string
  stat: string | number
  suffix: ReactNode
  className?: string
  statClassName?: string
  suffixClassName?: string
  titleClassName?: string
  loading?: boolean
}

export const UStatCard = (props: Props) => {
  return (
    <Card
      className={classNames(
        'bg-slate-800 p-1 sm:p-4 shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl',
        props.className ?? ''
      )}
    >
      <CardBody className="overflow-y-hidden flex flex-col justify-between">
        <div className={classNames('text-base font-semibold', props.titleClassName ?? '')}>{props.title}</div>

        <div className="flex items-end justify-end w-full flex-wrap">
          <div className={classNames('text-7xl', props.statClassName ?? '')}>
            {props.loading ? <AiOutlineLoading3Quarters className={'animate-spin text-4xl mr-2'} /> : props.stat}
          </div>
          <div className={classNames('text-sm mb-3', props.suffixClassName ?? '')}>
            {props.suffix?.toString().toUpperCase()}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
