import { useEffect, useState } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useShowcasesPageStore } from '@/presentation/JobPosts/store/jobPostsPageStore'
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { ShowcasesTable } from '@/presentation/Showcase/components/ShowcasesTable.tsx'
import { useAppLayoutStore } from '@/layout/appLayout/AppLayout.store.ts'
import { ShowcaseModal } from '@/presentation/JobPosts/components/ShowcaseModal/ShowcaseModal.tsx'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'
import { useParams } from 'react-router-dom'

const sortFieldName: Record<string, string> = {
  score: 'AI Score',
  status: 'Status',
  createdAt: 'Applied at',
}

export function ShowcasesPage() {
  const {
    getShowCaseListByAccountId,
    sortShowcaseList,
    showcaseSortField,
    searchShowcase,
    setSelectedShowcase,
    showcaseListLoading,
    getShowcaseListByJobPostId,
    selectedJobPostId,
    setSelectedJobPostId,
    getJobPosts,
  } = useShowcasesPageStore()

  useEffect(() => {
    getShowCaseListByAccountId()
  }, [])

  const { searchKeywords, setSearchKeywords } = useAppLayoutStore()

  useEffect(() => {
    searchShowcase(searchKeywords)
  }, [searchKeywords])

  useEffect(() => {
    setSearchKeywords('')
  }, [])

  // REGION => Showcase modal loading part.
  const { jobPostId, showcaseId } = useParams<{
    jobPostId?: string
    showcaseId?: string
  }>()

  useEffect(() => {
    getJobPosts().then(() => {
      if (jobPostId) {
        setSelectedJobPostId(jobPostId)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedJobPostId) {
      getShowcaseListByJobPostId(selectedJobPostId).then((data) => {
        if (showcaseId) {
          const showcase = data.find((showcase) => showcase.Id === showcaseId)
          if (showcase) {
            setSelectedShowcase(showcase)
            setIsOpen(true)
          }
        }
      })
    }
  }, [selectedJobPostId])

  const [open, setIsOpen] = useState(false)

  return showcaseListLoading ? (
    <div className="divide-y w-full divide-white/5 h-[173px] sm:h-[140px] lg:h-[calc(100vh-220px)] flex items-center justify-center overflow-y-hidden flex-col gap-y-4">
      <ULoadingAnimation />
      Loading...
    </div>
  ) : (
    <main className="w-full h-[calc(100vh-64px)] overflow-y-auto pb-8">
      <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:h-[89px]">
        <h3 className="text-2xl font-bold tracking-tight">Showcases</h3>

        <Dropdown
          classNames={{
            content: 'bg-slate-800',
          }}
        >
          <DropdownTrigger>
            <Button variant="light" className="px-2">
              Sorted by {sortFieldName[showcaseSortField]}
              <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Single selection example"
            variant="flat"
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={new Set([showcaseSortField])}
            onSelectionChange={(selection) =>
              sortShowcaseList([...selection][0] as 'score' | 'status' | 'createdAt', 'desc')
            }
          >
            <DropdownItem key="score">AI Score</DropdownItem>
            <DropdownItem key="status">Status</DropdownItem>
            <DropdownItem key="createdAt">Created at</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </header>

      <div className="md:mx-8">
        <ShowcasesTable
          selectShowcase={(showcase) => {
            setSelectedShowcase(showcase)
            setIsOpen(true)
          }}
        />
      </div>

      <ShowcaseModal
        isOpen={open}
        setIsOpen={setIsOpen}
        onClose={() => {
          setSelectedShowcase(null)
        }}
      />
    </main>
  )
}
