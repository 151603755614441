import { useEffect, useState } from 'react'
import { ShowcaseModal } from '../components/ShowcaseModal/ShowcaseModal'
import { useShowcasesPageStore } from '../store/jobPostsPageStore.ts'
import { JobPostingList } from '../components/JobPostingList'
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateJobPostModal } from '../components/CreateJobPostModal.tsx'
import { UAlert } from '@/common/components/UAlert.tsx'
import { useAppLayoutStore } from '@/layout/appLayout/AppLayout.store.ts'

const sortFieldName: Record<string, string> = {
  status: 'activity status',
  createdAt: 'created at',
}

export function JobPostsPage() {
  const [open, setIsOpen] = useState(false)

  // TODO => Should get deprecated soon. we're gonna add showing showcase functionality into the showcases page.
  const { jobPostId, showcaseId } = useParams<{
    jobPostId?: string
    showcaseId?: string
  }>()

  const nav = useNavigate()

  const {
    getShowcaseListByJobPostId,
    selectedJobPostId,
    setSelectedJobPostId,
    getJobPosts,
    sortJobPostsField,
    setSortJobPostsField,
    setSelectedShowcase,
    searchJobPost,
  } = useShowcasesPageStore()

  useEffect(() => {
    getJobPosts().then(() => {
      // TODO => Should get deprecated soon. we're gonna add showing showcase functionality into the showcases page.
      if (jobPostId) {
        setSelectedJobPostId(jobPostId)
      }
    })
  }, [])

  // TODO => Should get deprecated soon. we're gonna add showing showcase functionality into the showcases page.
  useEffect(() => {
    if (selectedJobPostId) {
      getShowcaseListByJobPostId(selectedJobPostId).then((data) => {
        if (showcaseId) {
          const showcase = data.find((showcase) => showcase.Id === showcaseId)
          if (showcase) {
            setSelectedShowcase(showcase)
            setIsOpen(true)
          }
        }
      })
    }
  }, [selectedJobPostId])

  // Search functionality
  const { searchKeywords, setSearchKeywords } = useAppLayoutStore()

  useEffect(() => {
    searchJobPost(searchKeywords)
  }, [searchKeywords])

  useEffect(() => {
    setSearchKeywords('')
  }, [])
  //

  const [openCreateJobPostModal, setOpenCreateJobPostModal] = useState(false)

  const [alertType, setAlertType] = useState<'success' | 'error' | 'warning'>('success')
  const [alertMessage, setAlertMessage] = useState('')
  const [displayAlert, setDisplayAlert] = useState(false)

  // TODO => we don't need selected job post id anymore.

  return (
    <>
      <UAlert message={alertMessage} open={displayAlert} setOpen={setDisplayAlert} type={alertType} />
      <main>
        <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:h-[89px]">
          {/* <h1 className="text-base font-semibold leading-7 text-white">Roles</h1> */}
          <h3 className="text-2xl font-bold tracking-tight">Job posts</h3>

          <div className="flex items-center gap-x-4">
            <Dropdown
              classNames={{
                content: 'bg-slate-800',
              }}
            >
              <DropdownTrigger>
                <Button variant="light" className="px-0">
                  Sorted by {sortFieldName[[...sortJobPostsField][0] as string]}
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={sortJobPostsField}
                onSelectionChange={(selection) => setSortJobPostsField(selection)}
              >
                <DropdownItem key="status">Status</DropdownItem>
                <DropdownItem key="createdAt">Created at</DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Button
              onClick={() => {
                setOpenCreateJobPostModal(true)
              }}
              color="primary"
              className="hidden md:block"
            >
              Add a new job post
            </Button>
          </div>
        </header>

        <JobPostingList />
      </main>

      <ShowcaseModal
        isOpen={open}
        setIsOpen={setIsOpen}
        onClose={() => {
          nav(`/app/showcases`)
          setSelectedShowcase(null)
        }}
      />

      <CreateJobPostModal
        onCancel={() => {
          setOpenCreateJobPostModal(false)
        }}
        onOk={(type) => {
          setOpenCreateJobPostModal(false)
          setAlertType(type)
          if (type === 'success') {
            setAlertMessage('Job post created successfully.')
            setDisplayAlert(true)
          } else {
            setAlertMessage('Error creating job post.')
            setDisplayAlert(true)
          }
        }}
        open={openCreateJobPostModal}
        onOpenChange={setOpenCreateJobPostModal}
      />
    </>
  )
}
