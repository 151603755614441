import { api } from '@/config/api'
import { Analytics } from '@/presentation/Analytics/store/analyticsPageStore'

export const getAnalyticsByJobPostId = async (jobPostId: string) => {
  const res = await api.get<Analytics['totalApplicantsPerDay']>(`/getAnalyticsByJobPostId?jobId=${jobPostId}`)

  res.data.forEach((d, i) => {
    const previousIndex = i - 1 < 0 ? 0 : i - 1
    d.Applicants += Number(res.data?.[previousIndex].Applicants ?? 0)
  })

  return res.data
}
