import './ULoadingAnimation.m.css'

export const ULoadingAnimation = () => {
  return (
    <div className="container">
      <span className="circle"></span>
      <span className="circle"></span>
      <span className="circle"></span>
      <span className="circle"></span>
    </div>
  )
}
