import { getJobProfileListByAccountId } from '@/common/services/getJobProfileListByAccountId'
import { JobProfile } from '@/presentation/JobPosts/types/JobProfile'
import { create } from 'zustand'
export type JobProfilesPageStore = {
  jobProfilesList?: JobProfile[]
  displayJobProfileList: JobProfile[]
  jobProfilesListLoading: boolean
  getJobProfiles: () => Promise<JobProfile[]>
  selectedJobProfile?: JobProfile | null
  setSelectedJobProfile: (jobProfile: JobProfile | null) => void
  searchJobProfiles: (searchText: string) => void
}

export const useJobProfilesPageStore = create<JobProfilesPageStore>((set, get) => ({
  jobProfilesList: [],
  displayJobProfileList: [],
  jobProfilesListLoading: false,
  getJobProfiles: async () => {
    const accountId = localStorage.getItem('accountId') as string
    set({ jobProfilesListLoading: true })
    if (accountId === null || accountId === undefined) {
      console.error('Account Id is not available')
      return []
    }
    const jobProfiles = await getJobProfileListByAccountId(accountId)
    set({ jobProfilesList: jobProfiles })
    set({ displayJobProfileList: jobProfiles })
    set({ jobProfilesListLoading: false })
    return jobProfiles
  },

  searchJobProfiles: (searchText) => {
    const jobProfiles = get().jobProfilesList
    if (jobProfiles === undefined || jobProfiles === null) {
      return
    }

    if (searchText === '') {
      set({ displayJobProfileList: jobProfiles })
    } else {
      const filteredJobProfiles = jobProfiles.filter((jobProfile) =>
        jobProfile.Name.toLowerCase().includes(searchText.toLowerCase())
      )
      set({ displayJobProfileList: filteredJobProfiles })
    }
  },

  selectedJobProfile: null,
  setSelectedJobProfile: (jobProfile) => set({ selectedJobProfile: jobProfile }),
}))
