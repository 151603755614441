import { ULoadingAnimation } from './ULoading/ULoadingAnimation.tsx'
import { FaPlay, FaPause, FaExpand } from 'react-icons/fa'
import { Button, Select, SelectItem } from '@nextui-org/react'
import { ReactEventHandler, useEffect, useRef, useState } from 'react'

type Props = {
  src: string
  className?: string
  onLoad?: ReactEventHandler<HTMLVideoElement>
  onLoaded?: ReactEventHandler<HTMLVideoElement>
  currentStartTime?: number
}

export const UVideoPlayer = ({ src, className, ...props }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [loading, setLoading] = useState(false)
  const [playbackSpeed, setPlaybackSpeed] = useState(1)
  const [device, setDevice] = useState('')

  useEffect(() => {
    setDevice(window.navigator.userAgent)
  }, [])

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.addEventListener('play', () => setIsPlaying(true))
      video.addEventListener('pause', () => setIsPlaying(false))
    }

    return () => {
      if (video) {
        video.removeEventListener('play', () => setIsPlaying(true))
        video.removeEventListener('pause', () => setIsPlaying(false))
      }
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackSpeed
    }
  }, [playbackSpeed])

  const toggleFullscreen = () => {
    const video = videoRef.current
    if (!video) return

    if (!document.fullscreenElement) {
      video.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable fullscreen mode: ${err.message}`)
      })
    } else {
      document.exitFullscreen()
    }
  }

  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const handleRateChange = () => {
      setPlaybackSpeed(video.playbackRate)
    }

    video.addEventListener('ratechange', handleRateChange)

    return () => {
      video.removeEventListener('ratechange', handleRateChange)
    }
  }, [])

  useEffect(() => {
    if (videoRef.current && props.currentStartTime) {
      videoRef.current.currentTime = props.currentStartTime
      videoRef.current.play()
    }
  }, [props.currentStartTime])

  return (
    <div className={`${className} ${loading ? 'hide-controls' : 'show-controls'}`}>
      <div className="mt-2 w-full bg-black flex items-center justify-stretch rounded-xl h-full relative">
        {loading && (
          <div className="absolute z-10 flex items-center justify-center  bg-slate-800/60 w-full h-full">
            <ULoadingAnimation />
          </div>
        )}

        {device.toLowerCase().indexOf('iphone') || device.toLowerCase().indexOf('ipad') ? (
          <video itemType="video/mp4" controls={true} ref={videoRef} className={`w-full h-full`} src={src}></video>
        ) : (
          <video
            src={src}
            itemType="video/mp4"
            ref={videoRef}
            playsInline
            className={`w-full h-full`}
            preload="metadata"
            onLoadStart={(e) => {
              props?.onLoad?.(e)
              setLoading(true)
            }}
            onLoadedData={(e) => {
              props?.onLoaded?.(e)
              setLoading(false)
            }}
            onWaiting={(e) => {
              props?.onLoad?.(e)
              setLoading(true)
            }}
            onPlaying={(e) => {
              props?.onLoad?.(e)
              setLoading(false)
            }}
            controls
          />
        )}
      </div>

      <div className="flex gap-2 items-center justify-center mt-6">
        <Button
          size="lg"
          isIconOnly
          variant="flat"
          color="default"
          className="sm:w-16 sm:h-16"
          onClick={() => {
            if (isPlaying) videoRef?.current?.pause()
            else videoRef?.current?.play()
          }}
          isDisabled={loading}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </Button>
        <Button
          size="lg"
          isIconOnly
          variant="flat"
          color="default"
          className="sm:w-16 sm:h-16"
          onClick={toggleFullscreen} // Fullscreen toggle button
          isDisabled={loading}
        >
          <FaExpand />
        </Button>

        <Select
          placeholder="Speed"
          selectionMode="single"
          selectedKeys={[playbackSpeed.toString()]}
          className="max-w-[200px] h-[64px]"
          onChange={(e) => setPlaybackSpeed(parseFloat(e.target.value))}
          classNames={{
            trigger: 'bg-slate-800 h-[64px] text-base transition-all hover:bg-slate-700',
            listbox: 'bg-slate-800',
            popoverContent: 'bg-slate-800',
          }}
          color="default"
          aria-label="playback speed"
        >
          <SelectItem key={'0.25'} value={'0.25'}>
            Playback speed 0.25x
          </SelectItem>

          <SelectItem key={'0.5'} value={'0.5'}>
            Playback speed 0.5x
          </SelectItem>

          <SelectItem key={'0.75'} value={'0.75'}>
            Playback speed 0.75x
          </SelectItem>

          <SelectItem key={'1'} value={'1'}>
            Playback speed 1x
          </SelectItem>

          <SelectItem key={'1.25'} value={'1.25'}>
            Playback speed 1.25x
          </SelectItem>

          <SelectItem key={'1.5'} value={'1.5'}>
            Playback speed 1.5x
          </SelectItem>

          <SelectItem key={'1.75'} value={'1.75'}>
            Playback speed 1.75x
          </SelectItem>

          <SelectItem key={'2'} value={'2'}>
            Playback speed 2x
          </SelectItem>
        </Select>
      </div>
    </div>
  )
}
