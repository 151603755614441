import { Card, CardBody, CardHeader } from '@nextui-org/react'
import { ApplicantPerJobPostBarChart } from '../components/ApplicantPerJobPostBarChart'
import { useAnalyticsPageStore, Analytics } from '../store/analyticsPageStore'
import { useEffect } from 'react'
import { ApplicantsPerDayChart } from '../components/ApplicantsPerDayChart'
import { ShowcasesPerDayChart } from '../components/ShowcasesPerDayChart'

const getTotalActiveCandidatePerJobPost = (analyticsData: Analytics['totalActiveApplicantPerJobPost']) => {
  return Math.round(
    analyticsData.reduce((acc: number, cur) => (acc += Number(cur.ActiveCandidates)), 0) / analyticsData.length
  )
}

const getTotalShowcasesPerJobPost = (analyticsData: Analytics['totalShowcasesPerJobPost']) => {
  return Math.round(
    analyticsData.reduce((acc: number, cur) => (acc += Number(cur.TotalShowcases)), 0) / analyticsData.length
  )
}

export function AnalyticsPage() {
  const { getAnalytics, analyticsData } = useAnalyticsPageStore()

  useEffect(() => {
    getAnalytics()
  }, [])

  return (
    <main className="h-[calc(100vh-64px)] overflow-y-auto">
      <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:h-[89px]">
        <h3 className="text-2xl font-bold tracking-tight">Analytics</h3>
      </header>

      <div className="p-0 sm:p-8 h-full grid grid-cols-6 grid-rows-auto sm:gap-4 divide-y-1 divide-gray-700 sm:divide-none w-full">
        <Card className="bg-slate-800 p-1 sm:p-4 row-span-1 col-span-6 h-[320px] shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl">
          <CardHeader>
            <div className="text-base font-semibold mb-4 mx-0">Total applicants</div>
          </CardHeader>
          <CardBody className="overflow-hidden p-0 h-full w-full">
            {/* <ApplicantPerJobPostBarChart /> */}
            <ApplicantsPerDayChart data={(analyticsData as Analytics)?.totalApplicantsPerDay ?? []} />
          </CardBody>
        </Card>

        <Card className="bg-slate-800 p-1 sm:p-4 row-span-2 col-span-6 xl:col-span-4 h-full min-h-[600px] shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl">
          <CardHeader>
            <div className="text-base font-semibold mb-4 mx-0">Recent jobs applicants</div>
          </CardHeader>
          <CardBody className="overflow-hidden p-0 h-full w-full">
            <ApplicantPerJobPostBarChart data={(analyticsData as Analytics)?.totalApplicantsPerJobPosts ?? []} />
          </CardBody>
        </Card>

        <Card className="bg-slate-800 p-1 sm:p-4 row-span-1 col-span-6 md:col-span-3 xl:col-span-2  h-[320px] shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl">
          <CardBody className="overflow-y-hidden flex flex-col justify-between">
            <div className="text-base font-semibold">Total active applicants</div>

            <div className="flex items-end justify-end w-full">
              <div className="text-teal-500 text-9xl text">
                {analyticsData?.totalActiveApplicantPerJobPost &&
                  getTotalActiveCandidatePerJobPost((analyticsData as Analytics)?.totalActiveApplicantPerJobPost)}
              </div>
              <div className="text-sm mb-3">Avg.</div>
            </div>
          </CardBody>
        </Card>

        <Card className="bg-slate-800 p-1 sm:p-4 row-span-1 col-span-6 md:col-span-3 xl:col-span-2 h-[320px] shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl">
          <CardBody className="overflow-y-hidden flex flex-col justify-between">
            <div className="text-base font-semibold">Total showcases per job post</div>

            <div className="flex items-end justify-end w-full">
              <div className="text-indigo-500 text-9xl text">
                {analyticsData?.totalShowcasesPerJobPost &&
                  getTotalShowcasesPerJobPost(analyticsData?.totalShowcasesPerJobPost)}
              </div>
              <div className="text-sm mb-3">Avg.</div>
            </div>
          </CardBody>
        </Card>

        <Card className="bg-slate-800 p-1 sm:p-4 row-span-1 col-span-6 h-[320px] shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl">
          <CardHeader>
            <div className="text-base font-semibold mb-4 mx-0">Total showcases</div>
          </CardHeader>
          <CardBody className="overflow-hidden p-0 h-full w-full">
            {/* <ApplicantPerJobPostBarChart /> */}
            {/* <ApplicantsPerDayChart data={(analyticsData as Analytics)?.totalApplicantsPerDay ?? []} /> */}
            <ShowcasesPerDayChart data={(analyticsData as Analytics)?.totalShowcasesPerDay ?? []} />
          </CardBody>
        </Card>

        {/* <Card className="bg-slate-800 p-1 sm:p-4 row-span-1 col-span-6 h-[320px] shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl">
          <CardBody className="overflow-y-hidden flex flex-col justify-between">
            <div className="text-base font-semibold">Number of active job post</div>

            <div className="flex items-end justify-end w-full">
              <div className="text-indigo-400 text-9xl text">23</div>
              <div className="text-sm mb-3">Today</div>
            </div>
          </CardBody>
        </Card> */}

        <div className="h-0 sm:h-4"> </div>
      </div>
    </main>
  )
}
