import { useJobApplicantPageStore } from '@/presentation/JobApplicants/store/jobApplicantPageStore.ts'
import { NormalizedJobApplicant } from '@/common/types/NormalizedJobApplicant.ts'
import { useNavigate } from 'react-router-dom'
import { IoChevronDownOutline } from 'react-icons/io5'
import { Button, Switch } from '@nextui-org/react'
import moment from 'moment'

const getJobAppliantTableRowClass = (applicant: NormalizedJobApplicant) => {
  return applicant.overallScore > applicant.overallScoreOriginal
    ? 'bg-green-500/10'
    : applicant.overallScore < applicant.overallScoreOriginal
      ? 'bg-red-500/10'
      : ''
}

export default function Table() {
  const {
    sortDirection,
    sortedBy,
    sortJobApplicants,
    onlyShowApplicantsAboveZero,
    setOnlyShowApplicantsAboveZero,
    getDisplayedJobApplicants,
  } = useJobApplicantPageStore()

  const nav = useNavigate()

  return (
    <div className="mt-8 sm:-mx-0 overflow-x-auto">
      <h3 className="text-2xl font-bold tracking-tight mb-4">Applicants list</h3>
      <Switch
        isSelected={onlyShowApplicantsAboveZero}
        onChange={() => {
          setOnlyShowApplicantsAboveZero(!onlyShowApplicantsAboveZero)
        }}
        size="sm"
      >
        {onlyShowApplicantsAboveZero ? 'Show all applicants' : 'Show only applicants with AI score above 0'}
      </Switch>
      <table className="min-w-full divide-y divide-gray-700">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
              Name
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold sm:table-cell">
              Email
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
              <div className="flex items-center gap-x-2">
                AI Score
                <Button
                  isIconOnly
                  size="sm"
                  variant="light"
                  color={sortedBy === 'overallScore' ? 'primary' : 'default'}
                  onClick={() => {
                    sortJobApplicants('overallScore', sortDirection === 'asc' ? 'desc' : 'asc')
                  }}
                >
                  <IoChevronDownOutline
                    size={16}
                    className={`mt-1 transition-all ${
                      sortedBy === 'overallScore' && sortDirection === 'asc'
                        ? 'rotate-180'
                        : sortedBy === 'overallScore' && sortDirection === 'desc'
                          ? ''
                          : ''
                    }`}
                  />
                </Button>
              </div>
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
              <div className="flex items-center gap-x-2">
                Stage
                <Button
                  isIconOnly
                  size="sm"
                  variant="light"
                  color={sortedBy === 'stage' ? 'primary' : 'default'}
                  onClick={() => {
                    sortJobApplicants('stage', sortDirection === 'asc' ? 'desc' : 'asc')
                  }}
                >
                  <IoChevronDownOutline
                    size={16}
                    className={`mt-1 transition-all ${
                      sortedBy === 'stage' && sortDirection === 'asc'
                        ? 'rotate-180'
                        : sortedBy === 'stage' && sortDirection === 'desc'
                          ? ''
                          : ''
                    }`}
                  />
                </Button>
              </div>
            </th>

            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
              <div className="flex items-center gap-x-2">
                Apply date
                <Button
                  isIconOnly
                  size="sm"
                  variant="light"
                  color={sortedBy === 'applyDate' ? 'primary' : 'default'}
                  onClick={() => {
                    sortJobApplicants('applyDate', sortDirection === 'asc' ? 'desc' : 'asc')
                  }}
                >
                  <IoChevronDownOutline
                    size={16}
                    className={`mt-1 transition-all ${
                      sortedBy === 'applyDate' && sortDirection === 'asc'
                        ? 'rotate-180'
                        : sortedBy === 'applyDate' && sortDirection === 'desc'
                          ? ''
                          : ''
                    }`}
                  />
                </Button>
              </div>
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-700">
          {getDisplayedJobApplicants().map((applicant) => (
            <tr className={`${getJobAppliantTableRowClass(applicant) ?? ''} hover:bg-slate-800`} key={applicant.id}>
              <td
                className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-0"
                onClick={() => {
                  nav(`/app/job-applicant/${applicant.id}`)
                }}
              >
                {applicant.name}
                <dl className="font-normal lg:hidden">
                  <dt className="sr-only sm:hidden">Email</dt>
                  <dd className="mt-1 truncate text-gray-300 sm:hidden">{applicant.email ?? ''}</dd>
                  <dt className="sr-only">Title</dt>
                  <dd className="mt-1 truncate">Resume match score: {applicant.overallScore ?? 'N/A'}</dd>
                  {/*  */}
                  <dd className="mt-1 truncate">Stage: {applicant.stage}</dd>
                  <dd className="mt-1 truncate">
                    Applied at: {applicant.applyDate ? moment(applicant.applyDate).format('MM/DD/YYYY') : ''}
                  </dd>
                </dl>
              </td>
              <td
                className="hidden px-3 py-4 text-sm text-gray-300 sm:table-cell"
                onClick={() => {
                  nav(`/app/job-applicant/${applicant.id}`)
                }}
              >
                {applicant.email}
              </td>
              <td
                className="hidden px-3 py-4 text-sm text-gray-300 lg:table-cell"
                onClick={() => {
                  nav(`/app/job-applicant/${applicant.id}`)
                }}
              >
                {applicant.overallScore ?? 'N/A'}
              </td>
              {/*  */}
              <td
                className="hidden px-3 py-4 text-sm text-gray-300 lg:table-cell"
                onClick={() => {
                  nav(`/app/job-applicant/${applicant.id}`)
                }}
              >
                {applicant.stage}
              </td>

              <td
                className="hidden px-3 py-4 text-sm text-gray-300 lg:table-cell"
                onClick={() => {
                  nav(`/app/job-applicant/${applicant.id}`)
                }}
              >
                {applicant.applyDate ? moment(applicant.applyDate).format('MM/DD/YYYY') : ''}
              </td>

              <td className="pr-3 py-4 text-right text-sm font-medium whitespace-nowrap">
                <a
                  target="_blank"
                  href={applicant.resumeLink}
                  className="text-indigo-400 hover:text-indigo-500 transition-transform-colors"
                >
                  Download resume
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
