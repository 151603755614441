import { getAnalytics } from '@/common/services/getAnalytics'
import { create } from 'zustand'

export type Analytics = {
  totalApplicantsPerJobPosts: {
    Id: string
    Name: string
    CreatedDate: string
    TotalApplicants: string
  }[]
  totalApplicantsPerDay: {
    Date: string
    Applicants: number
  }[]
  totalActiveApplicantPerJobPost: {
    Id: string
    Name: string
    CreatedDate: string
    ActiveCandidates: string
  }[]
  totalScreenInterviewPerJobPost: {
    Id: string
    Name: string
    CreatedDate: string
    TotalScreenInterview: string
  }[]
  totalApplyToShowcaseRatePerJobPost: {
    Id: string
    Name: string
    CreatedDate: string
    TotalApplyToShowcaseRate: string
  }[]
  interviewToShowcaseRatePerJobPost: {
    Id: string
    Name: string
    CreatedDate: string
    InterviewToShowcaseRate: string
  }[]
  totalShowcasesPerJobPost: {
    Id: string
    Name: string
    CreatedDate: string
    TotalShowcases: string
  }[]
  interviewAdvancementRatePerJobPost: {
    Id: string
    Name: string
    CreatedDate: string
    InterviewAdvancementRate: string
  }[]
  totalAdvancedCandidatesPerJobPost: {
    Id: string
    Name: string
    CreatedDate: string
    TotalAdvancedCandidates: string
  }[]
  totalShowcasesPerDay: {
    Date: string
    Showcases: number
  }[]
}

export type AnalyticsPageStore = {
  analyticsData?: Analytics
  getAnalyticsLoading: boolean
  getAnalytics: () => Promise<Analytics>
  lastUpdated: Date | null
  accountId: string
}

export const useAnalyticsPageStore = create<AnalyticsPageStore>((set, get) => ({
  analyticsData: undefined,
  getAnalyticsLoading: false,
  lastUpdated: null,
  accountId: '',
  async getAnalytics() {
    const accountId = localStorage.getItem('accountId') as string
    const shouldRetrieve =
      !get().lastUpdated ||
      new Date().getTime() - (get().lastUpdated?.getTime() ?? new Date().getTime()) > 60000 ||
      accountId !== get().accountId

    if (accountId !== get().accountId) {
      set({ analyticsData: undefined })
    }

    if (shouldRetrieve) {
      set({ accountId })
      set({ getAnalyticsLoading: true })
      const analytics = await getAnalytics()
      set({ analyticsData: analytics })
      set({ lastUpdated: new Date() })
      set({ getAnalyticsLoading: false })
      return analytics as Analytics
    } else {
      return get().analyticsData as Analytics
    }
  },
}))
