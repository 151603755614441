import { MouseEvent } from 'react'
import { classNames } from '../utils/classNames'

type Props<J> = {
  className?: string
  items: J[]
  onRenderItem: (item: J) => JSX.Element
  onItemClick?: (item: J, event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => void
}

export function UList<T>(props: Props<T>) {
  return (
    <ul role="list" className={classNames('divide-y divide-white/5', props.className ?? '')}>
      {props.items.map((el) => (
        <li
          key={Math.random().toString()}
          className="relative p-4 bg-gray-800 hover:bg-slate-700 transition-background cursor-pointer"
          onClick={(eve) => props.onItemClick?.(el, eve)}
        >
          {props.onRenderItem(el)}
        </li>
      ))}
    </ul>
  )
}
