import { UVideoPlayer } from '@/common/components/UVideoPlayer'
import { useShowcasesPageStore } from '../../store/jobPostsPageStore.ts'
import { useEffect, useState } from 'react'
import { UList } from '@/common/components/UList'
import { Highlight } from '../../types/Showcase'
import { seekToTime } from '@/common/utils/seekToTime.ts'
import { makeFirstLetterOfEachSentenceUppercase } from '@/common/utils/makeFirstLetterOfEachSentenceUppercase.ts'

export const HighlightPanel = () => {
  const { selectedShowCase } = useShowcasesPageStore()

  const [highlights, setHighlights] = useState<Highlight[]>([])

  const [currentStartTime, setCurrentStartTime] = useState<number>(0)

  useEffect(() => {
    if (selectedShowCase?.highlight_transcript__c) {
      try {
        const parsed = JSON.parse(selectedShowCase?.highlight_transcript__c)
        setHighlights(parsed)
      } catch (e) {
        // TODO => Populate an error state
      }
    } else {
      // TODO => Populate an error state
    }
  }, [selectedShowCase?.highlight_transcript__c])

  return (
    <div className="flex flex-wrap h-full gap-6 mt-8">
      <UVideoPlayer
        currentStartTime={currentStartTime}
        src={selectedShowCase?.full_video_url__c ?? ''}
        className="w-screen h-full lg:w-[calc(50%-12px)] min-h-max"
      />
      <div className="w-full lg:w-[calc(50%-12px)] h-full bg-gray-800 rounded-xl mt-2">
        {/* <ActivityFeed /> */}
        {
          <UList
            className="max-h-[500px] overflow-y-auto rounded-xl"
            items={highlights}
            onItemClick={(item) => setCurrentStartTime(seekToTime(item.start))}
            onRenderItem={(el) => (
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="min-w-0 text-sm font-bold sm:text-base sm:font-semibold leading-8 text-white">
                    {makeFirstLetterOfEachSentenceUppercase(el.reason)}
                  </h2>
                  {/* <div className="text-sm font-normal text-gray-300">
                    Relevancy:{' '}
                    {` ${el.relevance} ${el.relevance === 'high' ? '🔥' : '❄️'}`}
                  </div> */}
                </div>
                <div className="flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                  <p className="truncate">
                    {el.start} - {el.end}
                  </p>
                </div>
              </div>
            )}
          />
        }
      </div>
    </div>
  )
}
