import { classNames } from '../utils/classNames'

type Props = {
  variant: 'success' | 'warning' | 'danger' | 'default' | 'white'
  fontType?: 'thin' | 'light' | 'medium' | 'bold' | 'normal'
  text: string
  className?: string
  onClick?: () => void
}

const variantClass: Record<string, string> = {
  warning: 'text-yellow-400  bg-yellow-400/10 ring-gray-400/20',
  success: 'text-success-400  bg-success-400/10 ring-gray-400/20',
  danger: 'text-red-400  bg-red-400/10 ring-gray-400/20',
  default: 'text-indigo-400 bg-indigo-400/10 ring-indigo-400/30',
  white: 'text-white bg-white/10 ring-white/20',
}

const fontTypeClasses = {
  thin: 'font-thin',
  light: 'font-light',
  medium: 'font-medium',
  bold: 'font-bold',
  normal: 'font-normal',
}

export const UBadge = (props: Props) => {
  return (
    <div
      className={classNames(
        variantClass[props.variant ?? ''],
        'rounded-full flex-none py-1 px-2 text-xs ring-1 ring-inset max-w-max mt-2',
        fontTypeClasses[props.fontType ?? 'medium'],
        props.className ?? ''
      )}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  )
}
