import { useAppLayoutStore } from '@/layout/appLayout/AppLayout.store.ts'
import UrrlyLogo from '@/assets/urrly-logo.png'
import { classNames } from '@/common/utils/classNames.ts'
import EasyHealthImage from '@/assets/easyhealthsquare.png'
import { signOut } from 'firebase/auth'
import { auth } from '@/config/firebase'
import { VscSignOut } from 'react-icons/vsc'
import { useNavigate, useParams } from 'react-router-dom'
import { useShowcasesPageStore } from '@/presentation/JobPosts/store/jobPostsPageStore.ts'
import { Select, SelectItem } from '@nextui-org/react'
import { useService } from '@/common/hooks/useService.ts'
import { getAllAccountList } from '@/common/services/getAllAccountList.ts'
import { useEffect, useState } from 'react'

const isContactFromUrrly = (email: string) => {
  const domain = email.split('@')[1]
  return domain?.toLowerCase() === 'urrly.com'
}

export const DesktopSidebar = () => {
  const nav = useNavigate()
  const {
    navigationItems,
    contact,
    getRecentlyVisitedJobPostsList,
    addToRecentlyVisitedJobPostsList,
    clearRecentlyVisitedJobPostsList,
    markNavigationItemAsCurrent,
  } = useAppLayoutStore()
  const { getShowcaseListByJobPostId, setSelectedJobPostId } = useShowcasesPageStore()

  const { jobPostId } = useParams()

  const { call: getAccountList, data: accountList, isLoading: isAccountListLoading } = useService(getAllAccountList)
  const [selectedAccount, setSelectedAccount] = useState<string[]>([localStorage.getItem('accountId') ?? ''])

  useEffect(() => {
    getAccountList()
  }, [])

  useEffect(() => {
    const path = window.location.pathname
    const navigationItem = navigationItems.find((item) => item.href === path)
    if (navigationItem) {
      markNavigationItemAsCurrent(navigationItem.name)
    }
  }, [])

  // Static sidebar for desktop
  return (
    <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
      {/* Sidebar component */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
        <div className="flex h-16 shrink-0 items-center">
          <img className="h-8 w-auto" src={UrrlyLogo} alt="Your Company" />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigationItems.map((item) => (
                  <li
                    key={item.name}
                    onClick={() => {
                      nav(item.href)
                      markNavigationItemAsCurrent(item.name)
                    }}
                    className="cursor-pointer"
                  >
                    <a
                      className={classNames(
                        item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                        'flex items-center justify-between'
                      )}
                    >
                      <div className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        {item.name}
                      </div>
                      {!item.name.toLowerCase().includes('job posts') &&
                        !item.name.toLowerCase().includes('showcases') &&
                        !item.name.toLowerCase().includes('job profiles') &&
                        !item.name.toLowerCase().includes('analytics') && (
                          <span className="inline-flex items-center rounded-full bg-orange-200/10 px-2 py-1 text-xs font-medium text-orange-500">
                            Coming soon
                          </span>
                        )}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">Recently visited roles</div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                {getRecentlyVisitedJobPostsList().map((recentItem) => (
                  <li key={recentItem.Name}>
                    <a
                      className={classNames(
                        recentItem.Id === jobPostId
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-400 hover:text-white hover:bg-gray-800',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center cursor-pointer'
                      )}
                      onClick={() => {
                        nav(`/app/job-posts/${recentItem.Id}`)
                        setSelectedJobPostId(recentItem.Id)
                        getShowcaseListByJobPostId(recentItem.Id)
                        addToRecentlyVisitedJobPostsList(recentItem)
                      }}
                    >
                      <span
                        className={`flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border  bg-gray-800 text-[0.625rem] font-medium   ${recentItem.Status__c === 'Active' ? 'border-green-400 text-green-400' : 'text-red-400 border-red-400'}`}
                      >
                        {recentItem.Name.slice(0, 1).toUpperCase()}
                      </span>

                      <span className="truncate">{recentItem.Name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>

            <div className="-mx-6 mt-auto">
              <li className="cursor-pointer px-6 w-full">
                {isContactFromUrrly(contact?.Email ?? '') && accountList?.length && (
                  <Select
                    label="Account"
                    variant="flat"
                    selectionMode="single"
                    placeholder="Select an account"
                    className="w-full sm:w-full"
                    classNames={{
                      trigger: 'bg-slate-800',
                      listbox: 'bg-slate-800',
                      popoverContent: 'bg-slate-800',
                    }}
                    selectedKeys={selectedAccount}
                    isLoading={isAccountListLoading}
                    isDisabled={isAccountListLoading}
                    onChange={(e) => {
                      setSelectedAccount([e.target.value])
                      clearRecentlyVisitedJobPostsList()
                      localStorage.setItem('accountId', e.target.value)
                      setSelectedJobPostId('')
                      nav('/app')
                    }}
                  >
                    {accountList.map((account) => (
                      <SelectItem key={account.Id} value={account.Id}>
                        {account.Name}
                      </SelectItem>
                    ))}
                  </Select>
                )}
              </li>

              <li className=" cursor-pointer">
                <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800  cursor-pointer">
                  <img className="h-6 w-6 rounded-full bg-gray-800" src={EasyHealthImage} alt="easy health image" />
                  <span className="sr-only">Your profile</span>
                  <div className="flex flex-col min-w-0">
                    <span aria-hidden="true" className="truncate">
                      {contact?.Name}
                    </span>
                    <span className="text-xs font-thin">{contact?.Email}</span>
                  </div>
                </a>
              </li>

              <li
                onClick={() => {
                  signOut(auth)
                  localStorage.removeItem('accountId')
                }}
                className=" cursor-pointer"
              >
                <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800  cursor-pointer">
                  <VscSignOut className="h-6 w-6 shrink-0" aria-hidden="true" />
                  <span aria-hidden="true">Sign out</span>
                </a>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </div>
  )
}
