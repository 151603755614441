import UrrlyLogo from '@/assets/urrly-logo-2.png'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { Button /*Spinner*/ } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { useAuthLayoutStore } from './AuthLayout.store'
import { useNavigate } from 'react-router-dom'
import { signInWithGoogle } from '@/common/services/signInWithGoogle'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '@/config/firebase'
import { api } from '@/config/api'
import { Contact } from '@/common/types/Contact'
import { FcGoogle } from 'react-icons/fc'
import { FaMicrosoft } from 'react-icons/fa'
import { signInWithMicrosoft } from '@/common/services/signInWithMicrosoft'
import { useService } from '@/common/hooks/useService.ts'
import { getAllAccountList } from '@/common/services/getAllAccountList.ts'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'
import { LoginUsingUserNameAndPassword } from './components/LoginUsingUserNameAndPassword'
import { SentryHelper } from '@/common/utils/SentryHelper'

export function AuthLayout() {
  const nav = useNavigate()

  const { markUserAsNotAuthenticated } = useAuthLayoutStore()
  const [error, setError] = useState('')
  const [showAccountPicker, setShowAccountPicker] = useState(false)

  const { call: getAccountList, data: accountList, isLoading: isAccountListLoading } = useService(getAllAccountList)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user?.email) {
        // Check whether is email from urrly or not.
        // if it is launch a request to get the accounts list and set the account id in local storage
        const domain = user.email.split('@')[1]
        if (domain.toLowerCase() === 'urrly.com') {
          setShowAccountPicker(true)
          getAccountList()
          SentryHelper.logUserLogin(`user with email ${user.email} logged in.`)
          return
        }

        api
          .post<Contact[]>('/findContactByEmail', {
            email: user.email,
          })
          .then((res) => {
            if (res.data.length === 0) {
              signOut(auth)
              throw new Error('No user found')
            } else {
              nav('/app')
              localStorage.setItem('accountId', res.data[0].AccountId)
              SentryHelper.logUserLogin(`user with email ${res.data[0].Email} logged in.`)
            }
          })
          .catch(() => {
            setError('User not found please contact admin.')
            signOut(auth)
          })
      } else {
        nav('/auth')
      }
    })
  }, [])

  useEffect(() => {
    markUserAsNotAuthenticated()
  }, [])

  const [isScrollAtTop, setIsScrollAtTop] = useState(true)

  const [showOtherOptions, setShowOtherOptions] = useState(true)

  return showAccountPicker ? (
    <div className={'flex flex-col items-center justify-center w-screen h-screen bg-slate-900'}>
      <div
        onScroll={(e) => {
          console.log(e)
        }}
        className="bg-slate-800 shadow-lg shadow-gray-700 rounded-xl max-h-[80vh] md:max-h-[50vh] w-[90vw] md:w-[60vw] overflow-y-hidden overflow-x-hidden"
      >
        <h1 className={`px-4 pt-4 pb-2 transition-shadow ${isScrollAtTop ? '' : 'shadow-lg'}`}>
          Pick an account to log in as:
        </h1>

        {isAccountListLoading && (
          <div className="w-full h-full min-h-[256px] flex items-center justify-center flex-col">
            <ULoadingAnimation />
            <div className="mt-2">Loading...</div>
          </div>
        )}

        <div
          className="divide-y divide-white/5 flex flex-col p-4 max-h-[calc(80vh-48px)] md:max-h-[calc(50vh-48px)] overflow-y-auto scroll-smooth"
          onScroll={(e) => {
            if ((e.target as Element).scrollTop ?? 0) {
              setIsScrollAtTop(false)
              return
            }
            setIsScrollAtTop(true)
          }}
        >
          {accountList?.map((account) => (
            <div
              className="justify-center flex flex-col min-h-12 cursor-pointer hover:bg-slate-700 py-2 transition-colors"
              onClick={() => {
                nav('/app')
                localStorage.setItem('accountId', account.Id)
                localStorage.setItem('recentlyVisitedJobPostList', JSON.stringify([]))
              }}
            >
              <div key={account.Id} className="text-sm font-medium text-white flex flex-col" color="secondary">
                {account.Name}
              </div>
              {account.BillingCountry && account.BillingCity && (
                <div className="whitespace-nowrap font-thin text-slate-400 text-xs">
                  {account.BillingCountry}, {account.BillingCity}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-screen h-screen overflow-auto bg-gray-900 ">
      <div className="hidden lg:flex items-center justify-center w-full h-screen">
        <img src={UrrlyLogo} alt="Urrly logo" />
      </div>

      <div className="flex flex-col gap-y-16 items-center justify-center w-full h-screen p-8 lg:p-16 bg-gray-50">
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Login/Sign up
          </label>

          {/* REGION => ALERT */}
          {error && (
            <div className="w-full mt-2">
              <div className="rounded-md bg-red-50 p-4 w-full">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{error}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* REGION => ALERT */}

          <div className="mt-2 flex flex-col gap-y-4 rounded-md  min-w-[280px]">
            <LoginUsingUserNameAndPassword hideOtherOptions={() => setShowOtherOptions(false)} />

            {showOtherOptions && (
              <>
                <div className="my-4 text-slate-500 text-center flex items-center gap-x-2">
                  <div className="bg-slate-500 w-full h-[1px]" />
                  <div className="text-base min-w-max w-full">Continue with</div>

                  <div className="bg-slate-500 w-full h-[1px]" />
                </div>

                <Button
                  onClick={() => {
                    setError('')
                    signInWithGoogle()
                  }}
                  size="lg"
                  className="flex items-center hover:bg-slate-900 transition-all font-medium min-w-[280px] w-full"
                  color="secondary"
                >
                  <FcGoogle size={28} />
                  Google
                </Button>
                <Button
                  onClick={() => {
                    setError('')
                    signInWithMicrosoft()
                  }}
                  size="lg"
                  className="flex items-center hover:bg-slate-900 transition-all font-medium min-w-[280px] w-full"
                  color="secondary"
                >
                  <FaMicrosoft size={28} />
                  Microsoft
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
