import { Radio, RadioProps, cn } from '@nextui-org/react'

export const UCardRadio = (props: RadioProps) => {
  const { children, ...otherProps } = props

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          'inline-flex m-0 items-center justify-between',
          'flex-row-reverse min-w-[100%] cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent',
          'data-[selected=true]:border-primary'
        ),
      }}
      className="bg-slate-800 hover:bg-slate-800/50"
    >
      {children}
    </Radio>
  )
}
