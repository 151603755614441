import { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { NewResumeMatchRubric, ResumeMatchRubric } from '@/common/types/RubricTypes/ResumeMatchRubric.ts'
import { decodeHTMLEntities, decodeJsonString } from '@/common/utils/decodeJsonString'
import { RubricEvaluationCriteria } from '@/common/types/RubricTypes/RubricEvaluationCriteria.ts'

export type Props = {
  className?: string
  data: {
    id: string
    resumeMatchDescription: string
    rubric?: string | null
  }
}

export function UResumeMatchScoreRubric(props: Props) {
  const [resumeMatchRubric, setResumeMatchRubric] = useState<ResumeMatchRubric | null>()
  const [newResumeMatchRubric, setNewResumeMatchRubric] = useState<NewResumeMatchRubric | null>()

  useEffect(() => {
    if (props.data?.id) {
      try {
        const decodedJson = decodeJsonString(props.data?.resumeMatchDescription)

        setResumeMatchRubric(JSON.parse(decodedJson) as ResumeMatchRubric)
      } catch (e) {
        console.log('error', e)
      }
    }

    if (props.data?.rubric) {
      try {
        const decodedJson = decodeJsonString(props.data?.rubric)

        setNewResumeMatchRubric(JSON.parse(decodedJson) as NewResumeMatchRubric)
      } catch (e) {
        console.log('error', e)
      }
    }
  }, [props.data])

  return (newResumeMatchRubric?.requirements?.length ?? 0) > 0 ? (
    // Pathway A
    <div className={'mt-8 flow-root' + ' ' + props.className}>
      <h3 className="text-2xl font-bold tracking-tight mb-4">Match scores </h3>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-700">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                  Requirement
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold xl:table-cell">
                  Reason
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold xl:table-cell">
                  Score
                </th>
              </tr>
            </thead>
            <tbody>
              {newResumeMatchRubric?.requirements?.map((criteria) => (
                <tr key={Math.random().toString()} className="w-full">
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-0">
                    {decodeHTMLEntities(criteria.requirement)}
                    <dl className="font-normal xl:hidden">
                      <dd className="mt-1 truncate text-gray-300 text-wrap">
                        <strong>Reason:</strong> {decodeHTMLEntities(criteria.reasonDescription ?? '')}
                      </dd>
                      <dd className="mt-1 truncate font-bold">
                        <strong>Score:</strong> {criteria.score ?? 'N/A'}
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-300 xl:table-cell text-wrap">
                    {decodeHTMLEntities(criteria.reasonDescription)}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-300 xl:table-cell font-bold">
                    {criteria.score ?? ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    // Pathway B
    <div className={'mt-8 flow-root' + ' ' + props.className}>
      <h3 className="text-2xl font-bold tracking-tight mb-4">Match scores </h3>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-700">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                  Guideline
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold xl:table-cell">
                  Reason
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold xl:table-cell">
                  Score
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Requirements and Nice to haves */}
              {['Requirements', 'Nice to haves'].map((dataKey) => (
                <Fragment key={dataKey}>
                  <tr className="border-t border-gray-500">
                    <th
                      colSpan={5}
                      scope="colgroup"
                      className="bg-gray-800 py-2 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3"
                    >
                      {dataKey}
                    </th>
                  </tr>
                  {(resumeMatchRubric?.[dataKey] as RubricEvaluationCriteria[])?.map((criteria) => (
                    <tr key={Math.random().toString()} className="w-full">
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-0">
                        {decodeHTMLEntities(criteria.guideline)}
                        <dl className="font-normal xl:hidden">
                          <dd className="mt-1 truncate text-gray-300 text-wrap">
                            <strong>Reason:</strong> {decodeHTMLEntities(criteria.reason_description ?? '')}
                          </dd>
                          <dd className="mt-1 truncate font-bold">
                            <strong>Score:</strong> {criteria.score}
                          </dd>
                        </dl>
                      </td>

                      <td className="hidden px-3 py-4 text-sm text-gray-300 xl:table-cell text-wrap">
                        {decodeHTMLEntities(criteria.reason_description)}
                      </td>

                      <td className="hidden px-3 py-4 text-sm text-gray-300 xl:table-cell font-bold">
                        {criteria.score}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
