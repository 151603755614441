import BottomBanner from '../components/BottomBanner'
import { useQuery } from '@/common/hooks/useQuery'
import { classNames } from '@/common/utils/classNames.ts'
import { Accordion, AccordionItem } from '@nextui-org/react'
import { getDeviceType } from '@/common/services/getDeviceType.ts'
import { UMarkdownRenderer } from '@/common/components/UMarkdownRenderer.tsx'
import { useService } from '@/common/hooks/useService.ts'
import { getShowcaseSc } from '@/common/services/getShowcaseSc.ts'
import { jobPostStatusesClassMapper } from '@/common/mappers/jobPostingStatusMapper.ts'
import { useEffect, useState } from 'react'
import { UVideoPlayer } from '@/common/components/UVideoPlayer.tsx'
import { UList } from '@/common/components/UList.tsx'
import { Highlight } from '@/presentation/JobPosts/types/Showcase.ts'
import { seekToTime } from '@/common/utils/seekToTime.ts'
import { makeFirstLetterOfEachSentenceUppercase } from '@/common/utils/makeFirstLetterOfEachSentenceUppercase.ts'
import { UResumePanel } from '@/common/components/Overview/UResumePanel.tsx'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'
import { useAuth } from '@/common/hooks/useAuth.ts'
import { useNavigate } from 'react-router-dom'
import { trimId } from '@/common/utils/trimId.ts'

export function SharedShowcasePage() {
  const query = useQuery()
  const { call: getShowcase, data: selectedShowcase, isLoading } = useService(getShowcaseSc)

  const nav = useNavigate()

  const [selectedKeys, setSelectedKeys] = useState<string[]>(['1'])

  const [highlights, setHighlights] = useState<Highlight[]>([])

  const [currentStartTime, setCurrentStartTime] = useState<number>(0)

  useEffect(() => {
    if (selectedShowcase?.highlight_transcript__c) {
      try {
        const parsed = JSON.parse(selectedShowcase?.highlight_transcript__c)
        setHighlights(parsed)
      } catch (e) {
        // TODO => Populate an error state
      }
    } else {
      // TODO => Populate an error state
    }
  }, [selectedShowcase?.highlight_transcript__c])

  // TODO => add authentication check here.

  useAuth({
    onUnVerify: () => {
      if (query.get('sc') === null) return

      getShowcase(query.get('sc')).catch((err) => console.error(err))
    },
    onVerify: (isInsider) => {
      getShowcase(query.get('sc'))
        .then((res) => {
          if (isInsider) {
            localStorage.setItem('accountId', trimId(res.Related_Client_Account__c ?? ''))
            localStorage.setItem('recentlyVisitedJobPostList', JSON.stringify([]))
          }
          nav(`/app/showcases/${trimId(res.Job_Post__c)}/${trimId(res.Id)}`)
          // nav(`/app/showcases/${trimId(res.Id)}`)
        })
        .catch((err) => console.error(err))
    },
  })

  return isLoading ? (
    <div>
      <div className="divide-y w-full divide-white/5 h-[173px] sm:h-[140px] lg:h-[calc(100vh-77px-64px)] flex flex-col gap-y-4 items-center justify-center overflow-y-hidden">
        <ULoadingAnimation />
        Loading showcase...
      </div>
    </div>
  ) : (
    selectedShowcase && (
      <div className={'py-2 px-6 overflow-y-auto'}>
        <h1 className={'leading-9 text-xl font-semibold my-10'}>{selectedShowcase.Job_Applicant__r.Name}</h1>

        {/* <UResumePanel /> */}
        <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
          <div>
            <div className="flex items-center gap-x-3">
              <div
                className={classNames(
                  jobPostStatusesClassMapper[selectedShowcase.Job_Post__r.Status__c],
                  'flex-none rounded-full p-1'
                )}
              >
                <div className="h-2 w-2 rounded-full bg-current" />
              </div>
              <h1 className="flex gap-x-3 text-base leading-7">
                <span className="font-semibold text-white">
                  {selectedShowcase.Job_Post__r.Name}
                  {/*{jobPostList?.find((el) => el.Id === selectedJobPostId)?.Name}*/}
                </span>
              </h1>
            </div>
            <div className="hidden md:block whitespace-nowrap font-thin text-slate-400 text-xs ml-7">
              {selectedShowcase?.Job_Profile__r?.Name}
            </div>
            <p className="mt-2 text-xs leading-6 text-gray-400"></p>
          </div>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-1 bg-gray-700/10 md:grid-cols-3 rounded">
          {[
            {
              name: 'AI interview score',
              value: selectedShowcase.AI_interview_Score__c,
            },
            {
              name: 'Screen interview score',
              value: selectedShowcase.Human_Interview_Score__c,
            },
            {
              name: 'Resume match score',
              value: selectedShowcase.Resume_Match_Score__c,
            },
          ].map((stat, statIdx) => (
            <div
              key={stat.name}
              className={classNames(
                statIdx % 2 === 1 ? 'md:border-l' : statIdx === 2 ? 'md:border-l' : '',
                'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8'
              )}
            >
              <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-white">{stat.value ?? 'N/A'}</span>
              </p>
            </div>
          ))}
        </div>

        {/* Activity list */}
        <div className="border-t border-white/10 pt-11">
          {selectedShowcase?.Client_Feedback__c && (
            <Accordion variant="light" className="bg-gray-800 rounded-xl">
              <AccordionItem
                key="1"
                aria-label="Accordion 1"
                title={
                  <div>
                    Your feedback:{' '}
                    <div className="text-xs font-thin">
                      {getDeviceType() === 'Mobile' || getDeviceType() === 'Tablet' ? 'Tap ' : 'Click '}
                      to open
                    </div>
                  </div>
                }
              >
                <div className="px-2">
                  <UMarkdownRenderer markdown={selectedShowcase?.Client_Feedback__c ?? ''} />
                </div>
              </AccordionItem>
            </Accordion>
          )}
          <Accordion variant="light" className="bg-gray-800 rounded-xl my-4" selectedKeys={selectedKeys}>
            <AccordionItem
              onPress={() => setSelectedKeys((prev) => (prev.includes('1') ? [] : ['1']))}
              key="1"
              aria-label="Accordion 1"
              title={
                <div>
                  Candidate showcase notes:{' '}
                  <div className="text-xs font-thin">
                    {getDeviceType() === 'Mobile' || getDeviceType() === 'Tablet' ? 'Tap ' : 'Click '}
                    to {selectedKeys.find((el) => el === '1') ? 'close' : 'open'}
                  </div>
                </div>
              }
            >
              <div className="px-2">
                <UMarkdownRenderer markdown={selectedShowcase?.Candidate_Showcase_Notes__c ?? ''} />
              </div>
            </AccordionItem>
          </Accordion>
        </div>

        {/*  HIGHLIGHT PANEL */}
        <div className="flex flex-wrap h-full gap-6 mt-8">
          <UVideoPlayer
            currentStartTime={currentStartTime}
            src={selectedShowcase?.full_video_url__c ?? ''}
            className="w-screen h-full lg:w-[calc(50%-12px)] min-h-max"
          />
          <div className="w-full lg:w-[calc(50%-12px)] h-full bg-gray-800 rounded-xl mt-2">
            {/* <ActivityFeed /> */}
            {
              <UList
                className="max-h-[500px] overflow-y-auto rounded-xl"
                items={highlights}
                onItemClick={(item) => setCurrentStartTime(seekToTime(item.start))}
                onRenderItem={(el) => (
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="min-w-0 text-sm font-bold sm:text-base sm:font-semibold leading-8 text-white">
                        {makeFirstLetterOfEachSentenceUppercase(el.reason)}
                      </h2>
                      {/* <div className="text-sm font-normal text-gray-300">
                    Relevancy:{' '}
                    {` ${el.relevance} ${el.relevance === 'high' ? '🔥' : '❄️'}`}
                  </div> */}
                    </div>
                    <div className="flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                      <p className="truncate">
                        {el.start} - {el.end}
                      </p>
                    </div>
                  </div>
                )}
              />
            }
          </div>
        </div>

        <UResumePanel
          PDFViewerProps={{
            url: selectedShowcase?.Resume_URL__c ?? '',
          }}
          ResumeMatchScoreRubricProps={{
            data: {
              id: selectedShowcase?.Id ?? '',
              resumeMatchDescription: selectedShowcase?.Job_Applicant__r.AI_Resume_Match_Description__c ?? '',
            },
          }}
        />

        <BottomBanner />
      </div>
    )
  )
}
