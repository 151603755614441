import { api } from '@/config/api.ts'

export type JobPostCriteria = {
  guideline: string
  type: 'requirement' | 'niceToHave'
}

export const getJobPostCriterionByJobPostId = async (jobPostId: string) => {
  const res = await api.get<JobPostCriteria[]>('/getJobPostCriterionByJobPostId?jobPostId=' + jobPostId)

  return res.data
}
