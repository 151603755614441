import { BackwardIcon, CalendarDaysIcon, ForwardIcon } from '@heroicons/react/24/outline'
import { Button } from '@nextui-org/react'

type Props = {
  onNext: () => void
  onPrev: () => void
  onMeetSchedule: () => void
  onMeetScheduleLoading: boolean
  isLast: boolean
  isFirst: boolean
}

export const ApplicantBottomBanner = (props: Props) => {
  const accountId = localStorage.getItem('accountId')

  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
      <div className="pointer-events-auto flex bg-slate-800 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 md:hidden items-center justify-center gap-2">
        <Button
          size="sm"
          variant="light"
          className="flex items-center gap-2 w-1/4"
          onClick={props.onPrev}
          isDisabled={props.isFirst}
        >
          {props.isFirst ? (
            'First application'
          ) : (
            <>
              <BackwardIcon className="h-4 w-4 text-white" />
            </>
          )}
        </Button>

        {accountId?.substring(0, 15) === '001Rm00000DOqcQ' && (
          <Button
            size="sm"
            variant="shadow"
            color="primary"
            className="flex items-center gap-2"
            onClick={props.onMeetSchedule}
            isLoading={props.onMeetScheduleLoading}
          >
            <CalendarDaysIcon className="w-4 h-4 text-white" />
            Schedule meeting
          </Button>
        )}

        <Button
          size="sm"
          variant="light"
          className="flex items-center gap-2 w-1/4"
          onClick={props.onNext}
          isDisabled={props.isLast}
        >
          {props.isLast ? (
            'Last application'
          ) : (
            <>
              <ForwardIcon className="h-4 w-4 text-white" />
            </>
          )}
        </Button>
      </div>
    </div>
  )
}
