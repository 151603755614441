import { api } from '@/config/api'
import { JobApplicant } from '@/presentation/JobApplicants/types/JobApplicants.ts'
import { ResumeMatchRubric } from '@/common/types/RubricTypes/ResumeMatchRubric.ts'
import { decodeJsonString } from '../utils/decodeJsonString'
import { trimId } from '../utils/trimId'

export const getJobApplicantsList = async (jobId: string) => {
  const res = await api.get<JobApplicant[]>(`/getJobApplicantsByJobPostId/?jobId=${jobId}`)

  // REGION => parsing AI_Resume_Match_Description__c to Object
  res.data.forEach((jobApplicant) => {
    jobApplicant.AI_Resume_Match_Description__c = decodeJsonString(jobApplicant.AI_Resume_Match_Description__c ?? '[]')

    try {
      jobApplicant.ResumeMatchRubric = JSON.parse(jobApplicant.AI_Resume_Match_Description__c) as ResumeMatchRubric
    } catch (e) {
      jobApplicant.ResumeMatchRubric = null
      console.error('Error parsing JSON', e)
    }
  })

  res.data.forEach((item) => {
    item.Id = trimId(item.Id)
  })
  // ENDREGION

  return res.data
}
