import { ShowcaseSidebar, ShowcaseSidebarMain } from '@/presentation/JobPosts/components/ShowcaseSidebar.tsx'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useShowcasesPageStore } from '@/presentation/JobPosts/store/jobPostsPageStore.ts'
import { Button, Card, CardBody, CardHeader, Checkbox, Slider } from '@nextui-org/react'
import { FaPlus } from 'react-icons/fa6'
import { RubricGuideline } from '@/presentation/JobPosts/types/JobPosting.ts'
import RubricTableToolbox from '../components/RubricTableToolbox'
import { TableInput } from '../components/TableInput'
import { useService } from '@/common/hooks/useService'
import { patchJobPost } from '@/common/services/patchJobPost'
import { ShowcaseModal } from '../components/ShowcaseModal/ShowcaseModal'
import { UStatCard } from '@/common/components/UStatCard'
import { getAnalyticsByJobPostId } from '@/common/services/getAnalyticsByJobPostId'
import { ApplicantsPerDayChart } from '@/presentation/Analytics/components/ApplicantsPerDayChart'
import { ULoading } from '@/common/components/ULoading'

type RubricGuidelineC = { requirements: RubricGuideline[] }

const replaceNewlinesWithBr = (input: string): string => {
  return input.replace(/\\n/g, '')
}

export function JobPostPage() {
  const { call: patchJobPostById } = useService(patchJobPost)

  const { jobPostId } = useParams<{ jobPostId: string }>()

  const [, setOpen] = useState(false)

  const {
    setSelectedJobPostId,
    getJobPosts,
    jobPostList,
    selectedJobPostId,
    getShowcaseListByJobPostId,
    selectedShowCase,
    setSelectedShowcase,
    loading,
  } = useShowcasesPageStore()

  useEffect(() => {
    if (jobPostList?.length === 0) {
      getJobPosts().then(() => {
        if (jobPostId) {
          setSelectedJobPostId(jobPostId)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (selectedJobPostId) {
      getShowcaseListByJobPostId(selectedJobPostId)
    }
  }, [selectedJobPostId])

  const selectedJobPost = useMemo(
    () => jobPostList?.find((jobPost) => jobPost.Id === jobPostId) ?? null,
    [jobPostList, jobPostId]
  )

  const [rubricGuideline, setRubricGuideline] = useState<RubricGuideline[]>([])

  useEffect(() => {
    if (selectedJobPost) {
      const rubricGuideline = JSON.parse(selectedJobPost?.Rubric_Guideline__c ?? '{}') as RubricGuidelineC

      const rg =
        rubricGuideline?.requirements?.map((el) => ({
          ...el,
          scoringGuideline: replaceNewlinesWithBr(el.scoringGuideline),
          requirement: replaceNewlinesWithBr(el.requirement),
          importance: el.importance ?? 0,
          id: Math.random().toString(),
        })) ?? []

      setRubricGuideline(rg)
    }
  }, [selectedJobPost])

  const [selectedGuidelines, setSelectedGuidelines] = useState<string[]>([])

  const handleDeleteGuideline = (selectedIds: string[]) => {
    // rubricGuideline
    const rubricGuidelinesClone = JSON.parse(JSON.stringify(rubricGuideline))
    const rubricGuidelineAfterDeletion = rubricGuidelinesClone?.filter(
      (el: RubricGuideline) => !selectedIds.includes(el.id)
    )
    setRubricGuideline(rubricGuidelineAfterDeletion)

    setSelectedGuidelines([])

    // Sync data with server whenver data modification is done by the user.
    if (selectedJobPost?.Id) {
      patchJobPostById(selectedJobPost.Id, {
        Rubric_Guideline__c: JSON.stringify({ requirements: rubricGuidelineAfterDeletion }),
      })
    }
  }

  // REGION: Sync data with server whenver data modification is done by the user.

  useEffect(() => {
    const handleSync = () => {
      if (selectedJobPost?.Id) {
        patchJobPostById(selectedJobPost.Id, {
          Rubric_Guideline__c: JSON.stringify({ requirements: rubricGuideline }),
        })
      }
    }

    const debounceTimeout = setTimeout(() => {
      handleSync()
    }, 3_000) // 10 seconds debounce

    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [rubricGuideline, selectedJobPost?.Id])

  // REGION: Handle data modification

  const handleSettingMustHave = (id: string, isChecked: boolean) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              mustHave: isChecked,
            }
          : el2
      )
    )
  }

  const handleSettingImportance = (id: string, value: number) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              importance: value,
            }
          : el2
      )
    )
  }

  const handleCheckTableRow = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedGuidelines((prev) => [...prev, id])
    }
    if (!isChecked) {
      setSelectedGuidelines((prev) => prev.filter((sgl) => sgl !== id))
    }
  }

  const handleSettingRequirement = (id: string, value: string) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              requirement: value,
            }
          : el2
      )
    )
  }

  const handleSettingGuideline = (id: string, value: string) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              scoringGuideline: value,
            }
          : el2
      )
    )
  }

  const handleAddingNewGuideline = () => {
    setRubricGuideline((prev) => [
      ...prev,
      {
        requirement: '',
        scoringGuideline: '',
        importance: 0,
        mustHave: false,
        id: Math.random().toString(),
      },
    ])
  }

  // REGION END: Handle data modification

  // REGION Start: Analytics part

  const {
    call: getAnalysis,
    data: analysisData,
    isLoading: getAnalysisDataLoading,
  } = useService(getAnalyticsByJobPostId)

  useEffect(() => {
    getAnalysis(jobPostId)
  }, [])

  // REGION END: Analytics part

  return (
    <>
      <main className="h-[calc(100vh-64px)]  overflow-y-auto ">
        <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:h-[89px]">
          <h3 className="text-2xl font-bold tracking-tight">{selectedJobPost?.Name}</h3>
        </header>

        <div className="2xl:pr-96 min-h-max">
          <div className="py-10 px-4 sm:px-6 lg:px-8 lg:py-6">
            <div className="flex items-center gap-x-4">
              <h2 className="text-xl font-bold tracking-tight">Analytics and stats</h2>
            </div>

            <div className="mt-8 h-full grid grid-cols-6 grid-rows-auto sm:gap-4 divide-y-1 divide-gray-700 sm:divide-none w-full">
              <Card className="bg-slate-800 p-1 sm:p-4 row-span-1 col-span-6 h-[320px] shadow-none rounded-none sm:shadow-2xl sm:rounded-2xl">
                <CardHeader>
                  <div className="text-base font-semibold mb-4 mx-0">Applicant growth</div>
                </CardHeader>
                <CardBody className="overflow-hidden p-0 h-full w-full">
                  {getAnalysisDataLoading ? <ULoading /> : <ApplicantsPerDayChart data={analysisData ?? []} />}
                </CardBody>
              </Card>

              <UStatCard
                loading={loading}
                title="Total applicants"
                stat={Math.round(selectedJobPost?.Total_Applicants__c ?? 0)}
                suffix="applicants"
                statClassName="text-teal-500"
                suffixClassName="mb-[2px]"
                className="row-span-1 col-span-6 md:col-span-3 xl:col-span-2 h-[200px]"
              />

              <UStatCard
                loading={loading}
                title="Total hires made"
                stat={Math.round(selectedJobPost?.Total_Hires_Made__c ?? 0)}
                suffix="hires"
                statClassName="text-orange-500"
                suffixClassName="mb-[2px]"
                className="row-span-1 col-span-6 md:col-span-3 xl:col-span-2 h-[200px]"
              />

              <UStatCard
                loading={loading}
                title="Total offers"
                stat={Math.round(selectedJobPost?.Total_Offers__c ?? 0)}
                suffix="Offers"
                statClassName="text-indigo-500"
                suffixClassName="mb-[2px]"
                className="row-span-1 col-span-6 md:col-span-3 xl:col-span-2 h-[200px]"
              />

              <UStatCard
                loading={loading}
                title="Showcase to job offer rate"
                stat={Math.round(selectedJobPost?.Showcase_to_Job_Offer_Rate__c ?? 0)}
                suffix="%"
                statClassName="text-indigo-500"
                suffixClassName="mb-[2px]"
                className="row-span-1 col-span-6 md:col-span-3 xl:col-span-2 h-[200px]"
              />

              <UStatCard
                loading={loading}
                title="Interview to showcase rate"
                stat={Math.round(selectedJobPost?.Interview_to_Showcase_Rate__c ?? 0)}
                suffix="%"
                statClassName="text-indigo-500"
                suffixClassName="mb-[2px]"
                className="row-span-1 col-span-6 md:col-span-3 xl:col-span-2 h-[200px]"
              />

              <UStatCard
                loading={loading}
                title="Total advanced candidates"
                stat={Math.round(selectedJobPost?.Total_Advanced_Candidates__c ?? 0)}
                suffix="candidates"
                statClassName="text-indigo-500"
                suffixClassName="mb-[2px]"
                className="row-span-1 col-span-6 md:col-span-3 xl:col-span-2 h-[200px]"
              />
            </div>
          </div>

          {/*
            TODO => 5. Make the search work on the job post page.
          */}
          <div className="w-full px-4 py-4 sm:px-6 lg:px-8 2xl:hidden">
            <h2 className="text-xl font-bold tracking-tight">Showcases</h2>
            <div className="bg-slate-800 rounded-l-2xl rounded-r-2xl mt-8 max-h-[40vh] overflow-y-auto custom-showcases-card-scrollbar">
              <ShowcaseSidebarMain setIsOpen={() => {}} />
            </div>
          </div>

          <div className="py-10 px-4 sm:px-6 lg:px-8 lg:py-6">
            <div className="flex items-center gap-x-4">
              <h2 className="text-xl font-bold tracking-tight">Guidelines</h2>
              <p className="text-sm text-gray-500">Click to edit each column</p>
            </div>
            {/*REGION TABLE*/}
            <div className="mt-8 sm:-mx-0 p-4 bg-slate-800 rounded-2xl">
              <table className="h-full w-full">
                <thead>
                  <tr className="bg-slate-900 shadow-lg">
                    <th scope="col" className="w-[24px]  rounded-l-2xl">
                      <span className="sr-only">Select</span>
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold rounded-r-2xl sm:rounded-r-none "
                    >
                      Requirement
                    </th>

                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                      <div className="flex items-center gap-x-2 min-w-max">Guideline</div>
                    </th>

                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                      Importance
                    </th>

                    <th
                      scope="col"
                      className="hidden px-3 text-center py-3.5 text-sm font-semibold sm:table-cell rounded-r-2xl"
                    >
                      Must have
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="h-1" key={Math.random().toString()}></tr>
                  {rubricGuideline?.map((el) => (
                    <tr key={el.id} className="border-b-1 border-gray-200/10 last:border-0">
                      <td className="align-center max-w-6">
                        <dt className="sr-only">Select</dt>
                        <dd>
                          <Checkbox
                            size="sm"
                            className="max-w-6"
                            onChange={(eve) => handleCheckTableRow(el.id, eve.target.checked)}
                          />
                        </dd>
                      </td>

                      <td className="py-0 px-4 lg:px-2 text-sm font-normal sm:w-[256px] align-top pt-2">
                        <dt className="lg:hidden lg:sr-only font-bold text-gray-50">Requirement</dt>
                        <dd>
                          <TableInput
                            value={el.requirement}
                            onChange={(value) => handleSettingRequirement(el.id, value)}
                          />
                        </dd>

                        <dl className="lg:hidden">
                          <dt className="font-bold text-gray-50">Guideline</dt>
                          <dd>
                            <TableInput
                              value={el.scoringGuideline}
                              onChange={(value) => handleSettingGuideline(el.id, value)}
                            />
                          </dd>

                          <dt className="font-bold text-gray-50">Importance</dt>
                          <dd className="my-2">
                            <Slider
                              size="lg"
                              color="primary"
                              classNames={{
                                thumb: 'after:bg-gray-100',
                              }}
                              defaultValue={0}
                              value={el.importance as number}
                              className="w-full"
                              aria-label={'Importance'}
                              maxValue={100}
                              minValue={0}
                              step={1}
                              onChange={(value) => handleSettingImportance(el.id, value as number)}
                            />
                          </dd>

                          <dt className="sr-only font-bold text-gray-50">Must have</dt>
                          <dd className="sm:hidden pb-2 flex items-top gap-2 mt-8">
                            <div className="font-bold text-gray-50">Must have:</div>
                            <Checkbox
                              size="lg"
                              isSelected={el.mustHave}
                              onChange={(eve) => handleSettingMustHave(el.id, eve.target.checked)}
                            />
                          </dd>
                        </dl>
                      </td>

                      <td className="hidden max-w-0 py-0 pl-2 pr-2 text-sm font-normal sm:w-auto lg:table-cell align-top pt-2">
                        <TableInput
                          value={el.scoringGuideline}
                          onChange={(value) => handleSettingGuideline(el.id, value)}
                        />
                      </td>

                      <td className="hidden py-0 pl-2 pr-2 text-sm font-normal sm:w-[128px]  lg:table-cell pt-2 align-middle">
                        <Slider
                          size="lg"
                          color="primary"
                          classNames={{
                            thumb: 'after:bg-gray-100',
                          }}
                          defaultValue={100}
                          className="max-w-[128px]"
                          aria-label={'Importance'}
                          maxValue={100}
                          minValue={0}
                          step={1}
                          value={el.importance as number}
                          onChange={(value) => handleSettingImportance(el.id, value as number)}
                        />
                      </td>

                      <td className="hidden py-0 pl-2 pr-2 text-sm font-normal sm:w-[128px] sm:table-cell text-center">
                        <Checkbox
                          size="lg"
                          isSelected={el.mustHave}
                          onChange={(eve) => handleSettingMustHave(el.id, eve.target.checked)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Button
                variant="light"
                className="flex items-center gap-x-1 font-light ml-6"
                onClick={() => handleAddingNewGuideline()}
              >
                <FaPlus />
                Add new guideline
              </Button>
            </div>
            {/*REGION TABLE*/}
          </div>
        </div>

        <RubricTableToolbox
          onDelete={() => handleDeleteGuideline(selectedGuidelines)}
          display={selectedGuidelines.length > 0}
        />
      </main>

      <aside className="fixed h-[calc(100vh-64px)] top-16 right-0 hidden px-4 py-6 sm:px-6 lg:px-8 2xl:block inset-y-0 w-96 overflow-y-auto">
        {/* Secondary column (hidden on smaller screens) */}
        <ShowcaseSidebar setIsOpen={setOpen} />
      </aside>

      <ShowcaseModal
        isOpen={!!selectedShowCase}
        onClose={() => {
          setSelectedShowcase(null)
        }}
        setIsOpen={() => {}}
      />
    </>
  )
}
