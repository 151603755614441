import { auth } from '@/config/firebase'
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'
import { getDeviceType } from './getDeviceType'

const provider = new GoogleAuthProvider()

export const signInWithGoogle = async () => {
  try {
    if (getDeviceType() === 'Mobile' || getDeviceType() === 'Tablet') {
      const googleSignupRes = await signInWithPopup(auth, provider)
      const credential = GoogleAuthProvider.credentialFromResult(googleSignupRes)
      if (credential === null) {
        throw new Error('credential is null')
      }

      return true
    }

    const googleSignupRes = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(googleSignupRes)
    if (credential === null) {
      throw new Error('credential is null')
    }

    return true
  } catch (e) {
    // Handle Errors here.
    const error = e as Error
    const errorMessage = error.message
    console.error(errorMessage)

    signOut(auth)

    return false
  }
}
