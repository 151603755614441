import { classNames } from '@/common/utils/classNames'
import { useShowcasesPageStore } from '../../store/jobPostsPageStore.ts'
import { Accordion, AccordionItem, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import { updateShowcaseStatus } from '@/common/services/updateShowcaseStatus'
import { UMarkdownRenderer } from '@/common/components/UMarkdownRenderer'
import { getDeviceType } from '@/common/services/getDeviceType'
import { ClientFeedbackModal } from './ClientFeedbackModal.tsx'

const statusesClass: Record<string, string> = {
  offline: 'text-gray-500 bg-gray-100/10',
  Active: 'text-green-400 bg-green-400/10',
  Inactive: 'text-rose-400 bg-rose-400/10',
}

export const ReportPanel = () => {
  const {
    selectedShowCase,
    selectedJobPostId,
    jobPostList,
    setJobPostList,
    showcaseListForJobPost,
    setShowcaseListForJobPost,
  } = useShowcasesPageStore()

  const [selectedStatusLoading, setSelectedStatusLoading] = useState(false)
  const [selectedShowCaseStatus, setSelectedShowCaseStatus] = useState<string[]>(() => [
    selectedShowCase?.Status__c ?? '',
  ])

  const [temporarySetStatus, setTemporarySetStatus] = useState<string>('')
  const [isClientFeedbackModalOpen, setIsClientFeedbackModalOpen] = useState(false)

  const updateStatus = async (status: string, feedbackText: string) => {
    setSelectedStatusLoading(true)
    updateShowcaseStatus({
      showcaseId: selectedShowCase?.Id ?? '',
      clientFeedback: feedbackText ? feedbackText : ' ',
      showcaseStatus: status,
      statusChangedBy: JSON.parse(localStorage.getItem('contact') ?? '{}')?.Name ?? undefined,
      emailAddress: JSON.parse(localStorage.getItem('contact') ?? '{}')?.Email ?? undefined,
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setSelectedShowCaseStatus([status])
          // REGION => update showcase
          const showcaseClone = [...showcaseListForJobPost]
          const showcaseIndex = showcaseClone.findIndex((el) => el.Id === selectedShowCase?.Id)
          showcaseClone[showcaseIndex].Status__c = status
          setShowcaseListForJobPost(showcaseClone)
          // REGION => update job post
          if (jobPostList) {
            const jobPostClone = [...(jobPostList ?? [])]
            const jobPostIndex = jobPostClone.findIndex((el) => el.Id === selectedJobPostId)
            const showcaseIndex =
              jobPostClone[jobPostIndex].Showcases__r?.records?.findIndex((el) => el.Id === selectedShowCase?.Id) ?? -1
            if (jobPostIndex !== -1 && showcaseIndex !== -1) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              jobPostClone[jobPostIndex].Showcases__r.records[showcaseIndex].Status__c = status
            }
            setJobPostList(jobPostClone)
            setTemporarySetStatus('')
            setIsClientFeedbackModalOpen(false)
          }
        }
      })
      .finally(() => {
        setSelectedStatusLoading(false)
      })
  }

  const [selectedKeys, setSelectedKeys] = useState<string[]>(['1'])

  return (
    selectedShowCase && (
      <>
        {/* Heading */}
        <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
          <div>
            <div className="flex items-center gap-x-3">
              <div
                className={classNames(
                  statusesClass[selectedShowCase.Job_Post__r.Status__c],
                  'flex-none rounded-full p-1'
                )}
              >
                <div className="h-2 w-2 rounded-full bg-current" />
              </div>
              <h1 className="flex gap-x-3 text-base leading-7">
                <span className="font-semibold text-white">{selectedShowCase.Job_Post__r.Name}</span>
              </h1>
            </div>
            <div className="hidden md:block whitespace-nowrap font-thin text-slate-400 text-xs ml-7">
              {jobPostList?.find((el) => el.Id === selectedJobPostId)?.Job_Profile__r?.Name}
            </div>
            <p className="mt-2 text-xs leading-6 text-gray-400"></p>
          </div>

          <Select
            color={
              selectedShowCaseStatus[0] === 'Client_Approved'
                ? 'success'
                : selectedShowCaseStatus[0] === 'Send For Client Review' // NOTE Send for client review is the same as pending, but because pending is a reserved keyword at recruiter team we changed it into Send For Client Review
                  ? 'warning'
                  : 'danger'
            }
            label="Approval status"
            variant="flat"
            selectionMode="single"
            placeholder="Select a status"
            className="w-full sm:w-[200px]"
            selectedKeys={selectedShowCaseStatus}
            isLoading={selectedStatusLoading}
            isDisabled={selectedStatusLoading}
            onChange={(selection) => {
              if (!selection.target.value) {
                return
              }
              if (selection.target.value === 'Send For Client Review') {
                updateStatus('Send For Client Review', ' ')
                return
              }
              setTemporarySetStatus(selection.target.value)
              setIsClientFeedbackModalOpen(true)
            }}
          >
            <SelectItem key={'Client_Approved'} value={'Client_Approved'}>
              Approved
            </SelectItem>
            <SelectItem key={'Client_Rejected'} value={'Client_Rejected'}>
              Rejected
            </SelectItem>
            <SelectItem key={'Send For Client Review'} value={'Send For Client Review'}>
              Pending
            </SelectItem>
          </Select>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-1 bg-gray-700/10 md:grid-cols-3 rounded">
          {[
            {
              name: 'AI interview score',
              value: selectedShowCase.AI_interview_Score__c,
            },
            {
              name: 'Screen interview score',
              value: selectedShowCase.Human_Interview_Score__c,
            },
            {
              name: 'Resume match score',
              value: selectedShowCase.Resume_Match_Score__c,
            },
          ].map((stat, statIdx) => (
            <div
              key={stat.name}
              className={classNames(
                statIdx % 2 === 1 ? 'md:border-l' : statIdx === 2 ? 'md:border-l' : '',
                'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8'
              )}
            >
              <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-white">{stat.value ?? 'N/A'}</span>
              </p>
            </div>
          ))}
        </div>

        {/* Activity list */}
        <div className="border-t border-white/10 pt-11">
          {/* <h2 className=" text-base font-semibold leading-7 text-white">
            
          </h2>
          <div className="mt-6 w-full"> */}
          {/* <UHtmlRenderer
              htmlText={selectedShowCase?.Candidate_Showcase_Notes__c ?? ''}
            /> */}
          {/* <UMarkdownRenderer
              markdown={selectedShowCase?.Candidate_Showcase_Notes__c ?? ''}
            />
          </div> */}

          {selectedShowCase?.Client_Feedback__c && (
            <Accordion variant="light" className="bg-gray-800 rounded-xl">
              <AccordionItem
                key="1"
                aria-label="Accordion 1"
                title={
                  <div>
                    Your feedback:{' '}
                    <div className="text-xs font-thin">
                      {getDeviceType() === 'Mobile' || getDeviceType() === 'Tablet' ? 'Tap ' : 'Click '}
                      to open
                    </div>
                  </div>
                }
              >
                <div className="px-2">
                  <UMarkdownRenderer markdown={selectedShowCase?.Client_Feedback__c ?? ''} />
                </div>
              </AccordionItem>
            </Accordion>
          )}
          <Accordion variant="light" className="bg-gray-800 rounded-xl my-4" selectedKeys={selectedKeys}>
            <AccordionItem
              onPress={() => setSelectedKeys((prev) => (prev.includes('1') ? [] : ['1']))}
              key="1"
              aria-label="Accordion 1"
              title={
                <div>
                  Candidate showcase notes:{' '}
                  <div className="text-xs font-thin">
                    {getDeviceType() === 'Mobile' || getDeviceType() === 'Tablet' ? 'Tap ' : 'Click '}
                    to {selectedKeys.find((el) => el === '1') ? 'close' : 'open'}
                  </div>
                </div>
              }
            >
              <div className="px-2">
                <UMarkdownRenderer markdown={selectedShowCase?.Candidate_Showcase_Notes__c ?? ''} />
              </div>
            </AccordionItem>
          </Accordion>
        </div>

        <ClientFeedbackModal
          onCancel={() => {
            setTemporarySetStatus('')
            setIsClientFeedbackModalOpen(false)
          }}
          onOk={(feedbackText) => {
            updateStatus(temporarySetStatus, feedbackText)
          }}
          feedbackText={
            temporarySetStatus === 'Client_Approved'
              ? 'Thank you for working with Urrly. Please let us know what you think about the product and process? (This is optional feel free to click on the submit button)'
              : "Help us find you better candidates. What skills or experience is this candidate missing that you'd like to see in future candidates?"
          }
          open={isClientFeedbackModalOpen}
          onOpenChange={(open) => setIsClientFeedbackModalOpen(open)}
        />
      </>
    )
  )
}
