import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea } from '@nextui-org/react'
import { useState } from 'react'

export type Props = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onOk: (feedbackText: string) => void
  onCancel: () => void

  feedbackText: string
}

export const ClientFeedbackModal = (props: Props) => {
  const [clientFeedback, setClientFeedback] = useState<string>('')

  return (
    <Modal
      isOpen={props.open}
      onOpenChange={props.onOpenChange}
      className="bg-gray-900"
      classNames={{
        backdrop: 'bg-white/10 z-50',
      }}
    >
      <ModalContent>
        {(_) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Feedback</ModalHeader>
            <ModalBody>
              <p>{props.feedbackText}</p>
              <Textarea
                label="Description"
                placeholder="Enter your description"
                className="outline-none"
                classNames={{
                  input: 'border-none focus:ring-0 p-0',
                }}
                value={clientFeedback}
                onChange={(e) => setClientFeedback(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={props.onCancel}>
                Cancel
              </Button>
              <Button
                color="primary"
                onPress={() => {
                  props.onOk(clientFeedback)
                  setClientFeedback('')
                }}
              >
                Submit
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
