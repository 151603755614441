import { useShowcasesPageStore } from '@/presentation/JobPosts/store/jobPostsPageStore.ts'
import { showcaseStatusNameMapper } from '@/common/mappers/showcaseStatusMapper.ts'
import moment from 'moment'
import { Showcase } from '@/presentation/JobPosts/types/Showcase.ts'

type Props = {
  selectShowcase: (showcase: Showcase) => void
}

export const ShowcasesTable = (props: Props) => {
  const { showcaseList } = useShowcasesPageStore()

  return (
    <div className="mt-8 sm:-mx-0 p-4 bg-slate-800 rounded-2xl h-full">
      <table className="h-full w-full divide-gray-700">
        <thead>
          <tr className="bg-slate-900 shadow-lg">
            <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold rounded-l-2xl">
              Name
            </th>

            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell" min-w-max>
              <div className="flex items-center gap-x-2 min-w-max">AI Score</div>
            </th>

            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold sm:table-cell">
              Status
            </th>

            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold sm:table-cell min-w-max">
              <div className="flex items-center gap-x-2  min-w-max">Applied at</div>
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 rounded-r-2xl">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr className="h-4"></tr>
          {showcaseList.map((showcase) => (
            <tr className={`hover:bg-slate-700`} key={showcase.Id}>
              <td
                className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none rounded-l-xl"
                onClick={() => {
                  props.selectShowcase(showcase)
                }}
              >
                {`${showcase.Job_Applicant__r.Name} / ${showcase.Job_Post__r.Name}` ?? ''}

                <dl className="font-normal lg:hidden">
                  <dt className="sr-only">Title</dt>
                  <dd className="mt-1 truncate">AI score: {showcase.Resume_Match_Score__c ?? 'N/A'}</dd>

                  <dt className="sr-only sm:hidden">Applied at</dt>
                  <dd className="mt-1 truncate text-gray-300 sm:hidden">
                    {moment(showcase.Job_Applicant__r.CreatedDate).format('MM/DD/yyyy')}
                  </dd>

                  <dt className="sr-only sm:hidden">Status</dt>
                  <dd className="mt-1 truncate text-gray-300 sm:hidden">
                    <div className="flex items-center gap-2">
                      {showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? ''].toLowerCase() === 'pending' && (
                        <div className="bg-warning-400/10 w-4 h-4 flex items-center justify-center rounded-full">
                          <div className="h-2 w-2 rounded-full bg-warning-400" />
                        </div>
                      )}

                      {showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? ''].toLowerCase() ===
                        'approved' && (
                        <div className="bg-green-400/10 w-4 h-4 flex items-center justify-center rounded-full">
                          <div className="h-2 w-2 rounded-full bg-green-400" />
                        </div>
                      )}

                      {showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? ''].toLowerCase() ===
                        'rejected' && (
                        <div className="bg-red-400/10 w-4 h-4 flex items-center justify-center rounded-full">
                          <div className="h-2 w-2 rounded-full bg-red-400" />
                        </div>
                      )}
                      {showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? '']}
                    </div>
                  </dd>
                </dl>
              </td>

              <td
                className="hidden px-3 py-4 text-sm text-gray-300 lg:table-cell"
                onClick={() => {
                  props.selectShowcase(showcase)
                }}
              >
                {showcase.Resume_Match_Score__c ?? 'N/A'}
              </td>

              <td
                className="hidden px-3 py-4 text-sm text-gray-300 sm:table-cell"
                onClick={() => {
                  props.selectShowcase(showcase)
                }}
              >
                <div className="flex items-center gap-2">
                  {showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? ''].toLowerCase() === 'pending' && (
                    <div className="bg-warning-400/10 w-4 h-4 flex items-center justify-center rounded-full">
                      <div className="h-2 w-2 rounded-full bg-warning-400" />
                    </div>
                  )}

                  {showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? ''].toLowerCase() === 'approved' && (
                    <div className="bg-green-400/10 w-4 h-4 flex items-center justify-center rounded-full">
                      <div className="h-2 w-2 rounded-full bg-green-400" />
                    </div>
                  )}

                  {showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? ''].toLowerCase() === 'rejected' && (
                    <div className="bg-red-400/10 w-4 h-4 flex items-center justify-center rounded-full">
                      <div className="h-2 w-2 rounded-full bg-red-400" />
                    </div>
                  )}

                  <div>{showcaseStatusNameMapper[showcase.Status__c.toLowerCase() ?? '']}</div>
                </div>
              </td>

              <td
                className="hidden px-3 py-4 text-sm text-gray-300 sm:table-cell"
                onClick={() => {
                  props.selectShowcase(showcase)
                }}
              >
                {moment(showcase.Job_Applicant__r.CreatedDate).format('MM/DD/yyyy')}
              </td>

              <td className="pr-3 py-4 text-right text-sm font-medium whitespace-nowrap rounded-r-xl">
                <a
                  target="_blank"
                  href={showcase.Job_Applicant__r?.Resume_Link__c}
                  className="text-indigo-400 hover:text-indigo-500 transition-transform-colors"
                >
                  Download resume
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
