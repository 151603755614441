import { api } from '@/config/api'

export type NewRequirement = {
  requirement: string
  scoringGuideline: string
  importance: number
  mustHave: boolean
  id: string
}

export type NewRequirementsResponse = {
  requirements: NewRequirement[]
}

export const getJobPostRubricGuideline = async (jobPostId: string) => {
  const res = await api.get<NewRequirementsResponse>(`getJobPostRubricGuideline/?jobPostId=${jobPostId}`)

  return res.data.requirements
}
