import { Outlet, useNavigate } from 'react-router-dom'
import { MobileSidebar } from '@/layout/appLayout/components/MobileSidebar.tsx'
import { DesktopSidebar } from '@/layout/appLayout/components/DesktopSidebar.tsx'
import { StickySearchHeader } from '@/layout/appLayout/components/StickySearchHeader.tsx'
// import { useAuthLayoutStore } from '../authLayout/AuthLayout.store'
import { useEffect } from 'react'
import { api } from '@/config/api'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '@/config/firebase'
import { Contact } from '@/common/types/Contact'
import { useAppLayoutStore } from './AppLayout.store'

export function AppLayout() {
  const nav = useNavigate()
  const { setContact } = useAppLayoutStore()

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user?.email) {
        const domain = user.email.split('@')[1]
        if (domain.toLowerCase() === 'urrly.com') {
          setContact({
            Email: user.email,
            FirstName: user?.displayName ?? '',
            LastName: '',
            AccountId: '',
            Id: '',
            IsDeleted: false,
            Name: user?.displayName ?? '',
            attributes: {
              type: 'Contact',
              url: '/services/data/v54.0/sobjects/Contact/',
            },
          })
          return
        }

        api
          .post<Contact[]>('/findContactByEmail', {
            email: user.email,
          })
          .then((res) => {
            if (res.data.length === 0) {
              signOut(auth)
              throw new Error('No user found')
            } else {
              setContact(res.data[0])
            }
          })
          .catch(() => {
            signOut(auth)
          })
      } else {
        nav('/auth')
      }
    })
  }, [])

  return (
    <div className="bg-gray-900 h-screen">
      <MobileSidebar />

      <DesktopSidebar />

      <div className="xl:pl-72">
        <StickySearchHeader />

        {/* Page content */}
        <Outlet />
      </div>
    </div>
  )
}
