import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'
import { useNavigate } from 'react-router-dom'
import { useJobProfilesPageStore } from '../store/jobProfilesPageStore.ts'
import { marked } from 'marked'

function stripMarkdown(markdownText: string): string {
  // Use the marked library to parse Markdown to HTML
  const html = marked(markdownText)

  // Create a temporary DOM element to hold the HTML
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html as string

  // Use the textContent property to extract plain text
  const plainText = tempDiv.textContent || tempDiv.innerText || ''

  return plainText
}

export const JobProfileList = () => {
  const { displayJobProfileList, jobProfilesListLoading, setSelectedJobProfile } = useJobProfilesPageStore()

  const nav = useNavigate()

  const heightClass = 'h-[calc(100vh-64px-73px)] sm:h-[calc(100vh-64px-88px)]'

  return jobProfilesListLoading ? (
    <div
      className={`divide-y w-full divide-white/5 ${heightClass} flex flex-col gap-y-2 items-center justify-center overflow-y-hidden`}
    >
      <ULoadingAnimation />
      Loading...
    </div>
  ) : (
    <ul role="list" className={`divide-y divide-white/5 overflow-auto ${heightClass}`}>
      {displayJobProfileList?.map((jobProfile) => (
        <li
          key={jobProfile.Id}
          className={`relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-slate-700 transition-background`}
          onClick={() => {
            setSelectedJobProfile(jobProfile)
            nav(`/app/job-profiles/${jobProfile.Id}`)
          }}
        >
          <div className="min-w-0 flex-auto">
            <div className="flex items-center gap-x-3">
              <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                <a className="flex gap-x-2">
                  <span className="truncate">{jobProfile.Name}</span>
                  <span className="absolute inset-0" />
                </a>
              </h2>
            </div>

            <div className="mt-3 flex items-center justify-between gap-x-2.5 text-xs leading-5 text-gray-400">
              <p className="truncate">{stripMarkdown(jobProfile?.Job_Description__c ?? '')}</p>

              <div className="flex items-end flex-wrap gap-x-2"></div>
            </div>
          </div>

          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        </li>
      ))}
    </ul>
  )
}
