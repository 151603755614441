export const jobApplicantStageMapper: Record<string, string> = {
  '1': 'Applied',
  '2': 'Candidate on Hold - Resume Scan',
  '3': 'Recruiter Decline - Post Resume Scan',
  '4': 'ADVANCE - Schedule Screening Interview',
  '5': 'Screening Interview Scheduled',
  '6': 'Screening Interview No Show',
  '7': 'Recruiter Decline - Post Screening Interview',
  '8': 'Candidate Decline - Post Screening Interview',
  '9': 'Send Showcase to Client',
  '10': 'Client Decline - Candidate Showcase',
  '11': 'Schedule Hiring Manager Interview',
  '12': 'Hiring Manager Interview Scheduled',
  '13': 'Client Decline - Post Client Interview',
  '14': 'Candidate Declined - Post Client Interview',
  '15': 'On Hold with Hiring Manager',
  '16': 'Send Offer Letter',
  '17': 'Offer Letter Sent - Awaiting Signature',
  '18': 'Offer Letter Signed - Onboarding',
  '19': 'Placement',
}

export const jobApplicantValueStageMapper: Record<string, number> = {
  'N/A': 0,
  Applied: 1,
  'Candidate on Hold - Resume Scan': 2,
  'Recruiter Decline - Post Resume Scan': 3,
  'ADVANCE - Schedule Screening Interview': 4,
  'Screening Interview Scheduled': 5,
  'Screening Interview No Show': 6,
  'Recruiter Decline - Post Screening Interview': 7,
  'Candidate Decline - Post Screening Interview': 8,
  'Send Showcase to Client': 9,
  'Client Decline - Candidate Showcase': 10,
  'Schedule Hiring Manager Interview': 11,
  'Hiring Manager Interview Scheduled': 12,
  'Client Decline - Post Client Interview': 13,
  'Candidate Declined - Post Client Interview': 14,
  'On Hold with Hiring Manager': 15,
  'Send Offer Letter': 16,
  'Offer Letter Sent - Awaiting Signature': 17,
  'Offer Letter Signed - Onboarding': 18,
  Placement: 19,
}
