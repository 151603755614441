export const decodeJsonString = (json: string) => {
  return json.replace(/&quot;/g, '"')
}

export function decodeHTMLEntities(text: string) {
  const entities: Record<string, string> = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    // Add more entities as needed
  }

  return text?.replace(/&[^;]+;/g, (entity) => {
    return entities[entity] || entity
  })
}
