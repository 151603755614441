import React from 'react'
import './UPDFViewer.css'
import { Document, Page, pdfjs } from 'react-pdf'

// It's crucial for the library to work in a web environment.
pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js'

export type Props = {
  url: string
}

export const UPDFViewer = ({ url }: Props) => {
  const [numPages, setNumPages] = React.useState<number | null>(null)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  return (
    <div className="flex items-center justify-center bg-slate-950 w-[calc(100vw-48px)] lg:w-auto">
      <div className="flex items-center overflow-x-auto">
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess} className="pdf-document">
          {Array.from(new Array(numPages), (_, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </div>
  )
}
