import { Bar } from '@ant-design/plots'
import { Analytics } from '../store/analyticsPageStore'

type ApplicantPerJobPost = Analytics['totalApplicantsPerJobPosts'][0]

type Props = {
  data: Analytics['totalApplicantsPerJobPosts']
}

export const ApplicantPerJobPostBarChart = (props: Props) => {
  const config = {
    data: props.data.slice(0, 10),
    xField: 'Id',
    yField: 'TotalApplicants',

    axis: {
      x: {
        labelFill: '#fff',
        lineStroke: '#fff',
        labelFormatter: (id: string) => props.data.find((d) => d.Id === id)?.Name ?? id,
        line: true,
        animate: true,
      },
      y: {
        labelFill: '#fff',
        grid: true,
        gridStroke: '#fff',
      },
    },
    tooltip: {
      title: (d: ApplicantPerJobPost) => d.Name,
      items: [{ channel: 'y', name: 'Number of total applicants' }],
    },
    style: {
      radiusTopLeft: 8,
      radiusTopRight: 8,
      fill: '#6366f1',
    },
  }
  return <Bar className="max-h-full p-0" {...config} />
}
