import { UPDFViewer, Props as UPDFViewerProps } from '@/common/components/UPDFViewer'
import { UResumeMatchScoreRubric, Props as UResumeMatchScoreRubricProps } from './UResumeMatchScoreRubric'
import { Button } from '@nextui-org/react'
import { FaFileDownload } from 'react-icons/fa'

export type Props = {
  ResumeMatchScoreRubricProps: UResumeMatchScoreRubricProps
  PDFViewerProps: UPDFViewerProps
}

export const UResumePanel = (props: Props) => {
  return (
    <div className="w-full h-full overflow-x-hidden">
      <UResumeMatchScoreRubric
        className={`lg:hidden ${props.ResumeMatchScoreRubricProps.className}`}
        data={props.ResumeMatchScoreRubricProps.data}
      />
      <div className="flex w-full h-full gap-6 mt-8">
        <div>
          <div className="flex items-center justify-between">
            <h2 className=" text-base font-semibold leading-7 text-white mb-4">Candidate resume</h2>
            <Button
              className="text-sm font-semibold bg-transparent p-0 text-indigo-400"
              onClick={() => {
                window.open(props.PDFViewerProps.url, '_blank')
              }}
            >
              <FaFileDownload />
              Download
            </Button>
          </div>
          <UPDFViewer {...props.PDFViewerProps} />
        </div>

        <UResumeMatchScoreRubric
          data={props.ResumeMatchScoreRubricProps.data}
          className={`hidden lg:block ${props.ResumeMatchScoreRubricProps.className}`}
        />
      </div>
    </div>
  )
}
