import { Navigate, Route, Routes } from 'react-router-dom'
import { JobPostsPage } from '@/presentation/JobPosts/pages/JobPostsPage'
import { AppLayout } from './layout/appLayout/AppLayout'
import { AuthLayout } from './layout/authLayout/AuthLayout'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { JobApplicantsPage } from './presentation/JobApplicants/pages/JobApplicantsPage'
import { JobApplicantByIdPage } from './presentation/JobApplicants/pages/JobApplicantByIdPage'
import { AnalyticsPage } from './presentation/Analytics/pages/AnalyticsPage'
import { SharedShowcasePage } from './presentation/Showcase/pages/SharedShowcasePage'
import { GeneralLayout } from '@/layout/generalLayout/GeneralLayout.tsx'
import { NotFoundPage } from './presentation/NotFound/pages/NotFoundPage'
import { ShowcasesPage } from './presentation/Showcase/pages/ShowcasesPage'
import { ShowcasePage } from './presentation/Showcase/pages/ShowcasePage'
import { JobPostPage } from './presentation/JobPosts/pages/JobPostPage'
import { JobProfilesPage } from './presentation/JobProfiles/pages/JobProfilesPage'
import { JobProfilePage } from './presentation/JobProfiles/pages/JobProfilePage'

function App() {
  return (
    <Routes>
      <Route index element={<Navigate to="/auth" />} />

      <Route path="/app" element={<AppLayout />}>
        <Route index element={<Navigate to="/app/job-posts" />} />

        <Route path="job-profiles" element={<JobProfilesPage />} />
        <Route path="job-profiles/:jobProfileId" element={<JobProfilePage />} />

        <Route path="job-posts" element={<JobPostsPage />} />
        <Route path="job-posts/:jobPostId" element={<JobPostPage />} />
        <Route path="job-posts/:jobPostId/:showcaseId" element={<JobPostsPage />} />

        <Route path="job-applicants/:jobPostId" element={<JobApplicantsPage />} />
        <Route path="job-applicant/:applicantId" element={<JobApplicantByIdPage />} />

        <Route path="analytics" element={<AnalyticsPage />} />

        <Route path="showcases" element={<ShowcasesPage />} />
        <Route path="showcases/:showcaseId" element={<ShowcasePage />} />

        <Route path="showcases/:jobPostId/:showcaseId" element={<ShowcasesPage />} />
      </Route>

      <Route path="/auth" element={<AuthLayout />} />

      <Route
        path="/showcase"
        element={
          // TODO => Later on we can refactor this and put it on top of the application layer.
          <GeneralLayout>
            <SharedShowcasePage />
          </GeneralLayout>
        }
      />

      {/* <Route path="/:company-slug/jobs" element={<Jobs />} /> */}

      <Route path="/404" element={<NotFoundPage />} />

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  )
}

export default App
