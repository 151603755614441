import { auth } from '@/config/firebase'
import { OAuthProvider, signInWithPopup, signInWithRedirect, signOut } from 'firebase/auth'
import { getDeviceType } from './getDeviceType'

const provider = new OAuthProvider('microsoft.com')
provider.setCustomParameters({
  prompt: 'consent',
  tenant: 'common',
})

export const signInWithMicrosoft = async () => {
  try {
    if (getDeviceType() === 'Mobile' || getDeviceType() === 'Tablet') {
      await signInWithRedirect(auth, provider)
      return true
    }

    await signInWithPopup(auth, provider)

    return true
  } catch (e) {
    // Handle Errors here.
    const error = e as Error
    const errorMessage = error.message
    console.error(errorMessage)

    signOut(auth)

    return false
  }
}
