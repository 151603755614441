import { api } from '@/config/api'
import { Account } from '@/common/types/Account.ts'
import { trimId } from '../utils/trimId'

export const getAllAccountList = async () => {
  const res = await api.get<Account[]>('/getAllAccountList')

  res.data.forEach((item) => {
    item.Id = trimId(item.Id)
  })

  return res.data
}
