import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import './UMarkdownRenderer.m.scss'

type Props = {
  markdown: string
}

export const UMarkdownRenderer = (props: Props) => {
  return <ReactMarkdown className="custom-list-class " remarkPlugins={[gfm]} children={props.markdown} />
}
