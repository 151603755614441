import { useEffect, useRef } from 'react'

type InputProps = {
  value?: string
  onChange?: (value: string) => void
}

export const TableInput: React.FC<InputProps> = ({ value = '', onChange }) => {
  const spanRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (spanRef.current) {
      const formattedValue = value.replace(/\n/g, '<br>')
      if (spanRef.current.innerHTML !== formattedValue) {
        spanRef.current.innerHTML = formattedValue
      }
    }
  }, [value])

  const handleInput = (e: React.FormEvent<HTMLSpanElement>) => {
    const newValue = e.currentTarget.innerHTML.replace(/<br>/g, '\n')
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <span
      ref={spanRef}
      contentEditable={true}
      suppressContentEditableWarning={true}
      className="transition-colors block ring-0 w-full border-0 py-3 lg:px-2 text-gray-100 bg-transparent placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 font-sans resize-none focus:border-0 outline-0 h-full"
      onInput={handleInput}
    />
  )
}
