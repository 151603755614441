import { decodeHTMLEntities } from '@/common/utils/decodeJsonString'
import { useJobApplicantPageStore } from '@/presentation/JobApplicants/store/jobApplicantPageStore.ts'
import { Chip } from '@nextui-org/react'
import { TbSquare, TbSquareRoundedCheck } from 'react-icons/tb'

export const JobApplicantsCriteria = () => {
  const { criterion, changeCriteriaActiveStatus } = useJobApplicantPageStore()

  return (
    <div className="flex items-center my-8 flex-wrap gap-2 overflow-x-auto">
      {criterion.map((criteria) => (
        <Chip
          key={Math.random().toString()}
          className={`cursor-pointer select-none ${criteria.isActive ? 'bg-warning' : 'bg-gray-700'} tap-highlight-transparent`}
          onClick={() => {
            changeCriteriaActiveStatus(criteria.guideline ?? '')
          }}
        >
          <div className="flex items-center gap-1">
            {criteria.isActive ? (
              <TbSquareRoundedCheck className="-ml-0.5 h-5 w-5 text-white" aria-hidden="true" />
            ) : (
              <TbSquare className="-ml-0.5 h-5 w-5 text-white" aria-hidden="true" />
            )}
            {decodeHTMLEntities(criteria.guideline ?? '')}
          </div>
        </Chip>
      ))}
    </div>
  )
}
