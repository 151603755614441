import { BriefcaseIcon, ChevronRightIcon, MapPinIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { UHtmlRenderer } from '@/common/components/UHtmlRenderer'
import { Accordion, AccordionItem } from '@nextui-org/react'
import { getDeviceType } from '@/common/services/getDeviceType.ts'
import { JobApplicant } from '@/presentation/JobApplicants/types/JobApplicants.ts'

type Props = {
  data: JobApplicant[]
}

export default function JobApplicantsHeader({ data }: Props) {
  const nav = useNavigate()

  return (
    <>
      <div className="lg:flex lg:items-center lg:justify-between ml-2">
        <div className="min-w-0 flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-1">
              <li>
                <div className="flex">
                  <a
                    className="text-sm font-medium text-gray-300 hover:text-orange-400 transition-colors cursor-pointer"
                    onClick={() => {
                      nav(-1)
                    }}
                  >
                    Job posts
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a className="ml-1 text-sm font-medium text-gray-300">
                    {data?.[0].Job_Posting__r.Breezy_Position_Category__c ??
                      data?.[0].Job_Posting__r.Name.split('-')?.[0] ??
                      ''}
                  </a>
                </div>
              </li>
            </ol>
          </nav>
          <h2 className="mt-2 text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
            {data?.[0]?.Job_Posting__r?.Name ?? ''}
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {data?.[0].Job_Posting__r.Breezy_Position_Type__c}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {data?.[0].Job_Posting__r.Breezy_Job_Posting_Location__c
                ? data?.[0].Job_Posting__r.Breezy_Job_Posting_Location__c
                : data?.[0].Job_Posting__r.Job_Location__c
                  ? data?.[0].Job_Posting__r.Job_Location__c
                  : 'Location: N/A'}
            </div>
            {/* <div className="mt-2 flex items-center text-sm text-gray-500">
            <BeakerIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            AI interview score: 
          </div> */}
            {/*
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            Closing on January 9, 2020
          </div> */}
          </div>
        </div>
        {/* <div className="mt-5 flex lg:ml-4 lg:mt-0"> */}
        {/* <span className="hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PencilIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Edit
          </button>
        </span>

        <span className="ml-3 hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <LinkIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            View
          </button>
        </span>

        <span className="sm:ml-3">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Publish
          </button>
        </span> */}
      </div>

      <Accordion variant="light" className="bg-gray-800 rounded-xl mt-8 overflow-hidden">
        <AccordionItem
          key="1"
          aria-label="Accordion 1"
          title={
            <div>
              Job post description
              <div className="text-xs font-thin">
                {getDeviceType() === 'Mobile' || getDeviceType() === 'Tablet' ? 'Tap ' : 'Click '}
                to see more
              </div>
            </div>
          }
        >
          <UHtmlRenderer htmlText={data?.[0].Job_Posting__r.Job_Description__c ?? ''} />
        </AccordionItem>
      </Accordion>
    </>
  )
}
