import moment from 'moment'
import { LuMousePointerClick } from 'react-icons/lu'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'
import { useShowcasesPageStore } from '../store/jobPostsPageStore.ts'
import { FaRegCircleUser } from 'react-icons/fa6'
import { classNames } from '@/common/utils/classNames'
import { useNavigate } from 'react-router-dom'
import { showcaseStatusNameMapper } from '@/common/mappers/showcaseStatusMapper'

type Props = {
  setIsOpen: (isOpen: boolean) => void
}

const Score = ({ score }: { score: number | null }) => {
  if (score === null || score === undefined) {
    return 'N/A'
  }
  if (score >= 0 && score < 4) {
    return <span className="text-red-400">{score}</span>
  }
  if (score >= 4 && score < 6) {
    return <span className="text-yellow-400">{score}</span>
  }
  if (score >= 6) {
    return <span className="text-success-400">{score}</span>
  }
}

const jobPostingStatusClass: Record<string, string> = {
  'send for client review': 'text-yellow-400  bg-yellow-400/10 ring-gray-400/20',
  client_approved: 'text-success-400  bg-success-400/10 ring-gray-400/20',
  client_rejected: 'text-red-400  bg-red-400/10 ring-gray-400/20',
  Production: 'text-indigo-400 bg-indigo-400/10 ring-indigo-400/30',
}

export const ShowcaseSidebarMain = ({ setIsOpen }: Props) => {
  const { selectedJobPostId, showcaseListForJobPost, showcaseListLoading, setSelectedShowcase } =
    useShowcasesPageStore()

  return (
    <>
      {showcaseListLoading || !selectedJobPostId ? (
        <div className="divide-y w-full divide-white/5 flex items-center justify-center overflow-y-hidden min-h-[184px]">
          <ULoadingAnimation />
        </div>
      ) : showcaseListForJobPost.length > 0 ? (
        <ul role="list" className="divide-y divide-white/5 overflow-y-auto">
          {showcaseListForJobPost.map((item) => (
            <li
              key={item.Id}
              onClick={() => {
                setIsOpen(true)
                setSelectedShowcase(item)
              }}
              className="px-4 py-4 sm:px-6 lg:px-8 hover:bg-gray-800"
            >
              <div className="flex items-center gap-x-3">
                <FaRegCircleUser className="inline-block h-5 w-5 rounded-full" />

                <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-white">
                  {item?.Job_Applicant__r?.Name}
                </h3>
                <div
                  className={classNames(
                    jobPostingStatusClass[item?.Status__c?.toLowerCase() ?? ''],
                    'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset max-w-max mt-2'
                  )}
                >
                  {showcaseStatusNameMapper[item.Status__c?.toLowerCase() ?? '']}
                </div>
              </div>
              <time dateTime={item?.Job_Applicant__r?.CreatedDate} className="flex-none text-xs text-gray-600">
                {moment(item?.Job_Applicant__r?.CreatedDate ?? '').format('MM/DD/YYYY')}
              </time>
              <p className="mt-3 truncate text-sm text-gray-500">
                AI Resume score: <Score score={item.Resume_Match_Score__c} />
              </p>
              <p className="mt-3 truncate text-sm text-gray-500">
                AI interview score: <Score score={item.AI_interview_Score__c} />
              </p>
              <p className="mt-3 truncate text-sm text-gray-500">
                Screen call interview score: <Score score={item.Human_Interview_Score__c} />
              </p>
            </li>
          ))}
        </ul>
      ) : (
        <div className="divide-y w-full divide-white/5 flex items-center justify-center overflow-y-hidden min-h-[184px]">
          No showcase to be shown for this job post.
        </div>
      )}
    </>
  )
}

export const ShowcaseSidebar = ({ setIsOpen }: Props) => {
  const { selectedJobPostId, showcaseListForJobPost, showcaseListLoading, setSelectedShowcase } =
    useShowcasesPageStore()

  const nav = useNavigate()

  return (
    <aside className="bg-black/10 lg:fixed lg:bottom-0 lg:right-0 lg:top-16 lg:w-96 lg:overflow-y-hidden lg:border-l lg:border-white/5">
      <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:h-[89px]">
        <h2 className="text-base font-semibold leading-7 text-white">Showcases</h2>

        {selectedJobPostId && (
          <a
            className="text-sm font-semibold leading-6 text-indigo-400 cursor-pointer"
            onClick={() => {
              nav(`/app/job-applicants/${selectedJobPostId}`)
            }}
          >
            View all applicants
          </a>
        )}
      </header>

      {selectedJobPostId ? (
        showcaseListLoading ? (
          <div className="divide-y w-full divide-white/5 h-[173px] sm:h-[140px] lg:h-[calc(100vh-90px-64px)] flex items-center justify-center overflow-y-hidden">
            <ULoadingAnimation />
          </div>
        ) : showcaseListForJobPost.length > 0 ? (
          <ul
            role="list"
            className="divide-y divide-white/5 max-h-[173px] sm:max-h-[140px] lg:max-h-[calc(100vh-77px-64px)] overflow-y-auto"
          >
            {showcaseListForJobPost.map((item) => (
              <li
                key={item.Id}
                onClick={() => {
                  setIsOpen(true)
                  setSelectedShowcase(item)
                }}
                className="px-4 py-4 sm:px-6 lg:px-8 hover:bg-gray-800"
              >
                <div className="flex items-center gap-x-3">
                  <FaRegCircleUser className="inline-block h-5 w-5 rounded-full" />

                  <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-white">
                    {item?.Job_Applicant__r?.Name}
                  </h3>
                  <div
                    className={classNames(
                      jobPostingStatusClass[item?.Status__c?.toLowerCase() ?? ''],
                      'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset max-w-max mt-2'
                    )}
                  >
                    {showcaseStatusNameMapper[item.Status__c?.toLowerCase() ?? '']}
                  </div>
                </div>
                <time dateTime={item?.Job_Applicant__r?.CreatedDate} className="flex-none text-xs text-gray-600">
                  {moment(item?.Job_Applicant__r?.CreatedDate ?? '').format('MM/DD/YYYY')}
                </time>
                <p className="mt-3 truncate text-sm text-gray-500">
                  AI Resume score: <Score score={item.Resume_Match_Score__c} />
                </p>
                <p className="mt-3 truncate text-sm text-gray-500">
                  AI interview score: <Score score={item.AI_interview_Score__c} />
                </p>
                <p className="mt-3 truncate text-sm text-gray-500">
                  Screen call interview score: <Score score={item.Human_Interview_Score__c} />
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <div className="divide-y w-full divide-white/5 h-[173px] sm:h-[140px] lg:h-[calc(100vh-77px-64px)] flex items-center justify-center overflow-y-hidden">
            No showcase to be shown for this job post.
          </div>
        )
      ) : (
        <div className="flex w-full flex-col divide-y divide-white/5 max-h-[173px] sm:max-h-[140px] lg:max-h-full overflow-y-auto justify-center items-center md:h-[calc(100vh-80px-64px)]">
          <LuMousePointerClick size={64} className="text-orange-500 mb-4" />
          Select a role to get the showcase list.
        </div>
      )}
    </aside>
  )
}
