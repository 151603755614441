import { UCardRadio } from '@/common/components/UCardRadio'
import { useService } from '@/common/hooks/useService'
import { notifyNewJobPostByEmail } from '@/common/services/notifyNewJobPostByEmail'
import { useAppLayoutStore } from '@/layout/appLayout/AppLayout.store'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  RadioGroup,
  Textarea,
} from '@nextui-org/react'
import { useEffect, useState } from 'react'

export type Props = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onOk: (type: 'success' | 'error') => void
  onCancel: () => void
}

type Step = 0 | 1

export const CreateJobPostModal = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const [step, setStep] = useState<Step>(0)
  const [jobCreationType, setJobCreationType] = useState<string>('' as string)

  const { contact } = useAppLayoutStore()

  const [jobLink, setJobLink] = useState<string>('')

  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [positionType, setPositionType] = useState<string>('')

  const { call: notifyNewJobPost } = useService(notifyNewJobPostByEmail)

  useEffect(() => {
    if (props.open) {
      setStep(0)
      setJobCreationType('')
    }
  }, [props.open])

  return (
    <Modal
      isOpen={props.open}
      onOpenChange={props.onOpenChange}
      className="bg-gray-900"
      classNames={{
        backdrop: 'bg-white/10 z-50',
      }}
    >
      <ModalContent>
        {(_) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Post a New Role</ModalHeader>
            <ModalBody>
              {step === 0 && (
                <RadioGroup
                  label="Create a new role using:"
                  description="We will reach out if we need more details."
                  onChange={(e) => {
                    setJobCreationType(e.target.value)
                    setStep(1)
                  }}
                  className="mb-4"
                >
                  <UCardRadio
                    description="It can be any link from Linkedin, Indeed or from your career website."
                    value="link"
                  >
                    An already posted link
                  </UCardRadio>

                  <UCardRadio description="Fill the data that we need to create a new job post for you." value="fields">
                    Start from scratch
                  </UCardRadio>
                </RadioGroup>
              )}

              {step === 1 && jobCreationType === 'link' && (
                <div>
                  <nav className="flex" aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                      <li>
                        <div className="flex">
                          <a
                            className="text-sm font-medium text-gray-300 hover:text-orange-400 transition-colors cursor-pointer"
                            onClick={() => {
                              setStep(0)
                            }}
                          >
                            Select post type
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a className="ml-1 text-sm font-medium text-gray-300">Job post link</a>
                        </div>
                      </li>
                    </ol>
                  </nav>

                  <Input
                    className="outline-none mt-4"
                    classNames={{
                      input: 'border-none focus:ring-0 p-0',
                    }}
                    type="text"
                    label="Link"
                    placeholder="Please enter your job link here."
                    value={jobLink}
                    onChange={(e) => setJobLink(e.target.value)}
                  />
                </div>
              )}
              {step === 1 && jobCreationType === 'fields' && (
                <div>
                  <nav className="flex" aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                      <li>
                        <div className="flex">
                          <a
                            className="text-sm font-medium text-gray-300 hover:text-orange-400 transition-colors cursor-pointer"
                            onClick={() => {
                              setStep(0)
                            }}
                          >
                            Select post type
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a className="ml-1 text-sm font-medium text-gray-300">Start from scratch</a>
                        </div>
                      </li>
                    </ol>
                  </nav>

                  <Input
                    className="outline-none mt-4"
                    classNames={{
                      input: 'border-none focus:ring-0 p-0',
                    }}
                    type="text"
                    label="Title"
                    placeholder="Please enter your job title here."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <Textarea
                    className="outline-none mt-4"
                    classNames={{
                      input: 'border-none focus:ring-0 p-0',
                    }}
                    type="text"
                    label="Description"
                    placeholder="Please enter your job description here."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <Input
                    className="outline-none mt-4"
                    classNames={{
                      input: 'border-none focus:ring-0 p-0',
                    }}
                    type="text"
                    label="Location"
                    placeholder="Please enter your job location here."
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />

                  <Input
                    className="outline-none mt-4"
                    classNames={{
                      input: 'border-none focus:ring-0 p-0',
                    }}
                    type="text"
                    label="Position type"
                    placeholder="Please enter your position type here."
                    value={positionType}
                    onChange={(e) => setPositionType(e.target.value)}
                  />
                </div>
              )}
            </ModalBody>

            {step !== 0 && (
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={() => {
                    setStep(0)

                    setJobLink('')
                    setTitle('')
                    setDescription('')
                    setLocation('')
                    setPositionType('')

                    props.onCancel()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={loading}
                  color="primary"
                  onPress={() => {
                    setLoading(true)
                    notifyNewJobPost({
                      jobTitle: title,
                      jobDescription: description,
                      jobLocation: location,
                      positionType: positionType,
                      jobLink: jobLink,
                      submittedBy: contact?.Email ?? '',
                    })
                      .then((_) => {
                        props.onOk('success')
                      })
                      .catch(() => {
                        props.onOk('error')
                      })
                      .finally(() => {
                        setStep(0)

                        setJobLink('')
                        setTitle('')
                        setDescription('')
                        setLocation('')
                        setPositionType('')
                        setLoading(false)
                      })
                  }}
                >
                  Submit
                </Button>
              </ModalFooter>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
