import { useEffect } from 'react'

export type Props = {
  message: string
  type: 'success' | 'error' | 'warning'
  open: boolean
  setOpen: (open: boolean) => void
}

export const UAlert = (props: Props) => {
  const getAlertType = () => {
    switch (props.type) {
      case 'success':
        return 'bg-success-400 text-white text-sm'
      case 'error':
        return 'bg-danger-400 text-white text-sm'
      case 'warning':
        return 'bg-warning-400 text-white text-sm'
      default:
        return 'bg-indigo-400 text-white text-sm'
    }
  }

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        props.setOpen(false)
      }, 5000)
    }
  }, [props.open])

  return (
    props.open && (
      <div
        className={`rounded-md p-4 fixed w-max top-[24px] left-[50%] translate-x-[-50%] shadow-xl ${getAlertType()}`}
        style={{
          zIndex: 9999,
        }}
      >
        <p>{props.message}</p>
      </div>
    )
  )
}
