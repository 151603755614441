import { api } from '@/config/api'
import { JobApplicant } from '@/presentation/JobApplicants/types/JobApplicants'
import { trimId } from '../utils/trimId'

export const getJobApplicantById = async (id: string) => {
  const response = await api.get<JobApplicant[]>(`/getJobApplicantById?applicantId=${id}`)

  response.data.forEach((item) => {
    item.Id = trimId(item.Id)
  })

  return response.data[0]
}
