import { Button } from '@nextui-org/react'
import { motion, AnimatePresence } from 'framer-motion'
import { IoCloseCircleOutline } from 'react-icons/io5'

export type Props = {
  display: boolean
  title?: string
  text?: string
  onClose: () => void
  color?: 'success' | 'warning' | 'error'
}

export function Toast(props: Props) {
  const { display } = props
  return (
    <AnimatePresence>
      {display && (
        <motion.div
          initial={{ y: '-100%' }}
          animate={{ y: 0 }}
          exit={{ y: '-100%' }}
          transition={{ type: 'spring', stiffness: 100, damping: 20 }}
          className="pointer-events-none fixed inset-x-0 top-20 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8"
        >
          <div
            className={`pointer-events-auto flex items-center justify-between gap-x-6 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 ${props.color === 'success' ? 'bg-success-400' : props.color === 'warning' ? 'bg-warning-400' : 'bg-red-400'}`}
          >
            <p className="text-sm leading-6 text-white">
              <a href="#">
                <strong className="font-semibold mx-2">{props.title}</strong>
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
            <p>{props.text}</p>
            <Button
              color={props.color === 'success' ? 'success' : props.color === 'warning' ? 'warning' : 'danger'}
              variant="faded"
              onClick={props.onClose}
              className="bg-gray-900"
              isIconOnly={true}
            >
              <IoCloseCircleOutline className="w-4 h-4" />
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
