import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'

type Props = {
  loadingText?: string | null
}
export const ULoading = ({ loadingText = 'Loading...' }: Props) => {
  return (
    <div className="w-full h-full flex items-center justify-center flex-col gap-y-2">
      <ULoadingAnimation />
      <div className="mt-2">{loadingText}</div>
    </div>
  )
}
