import { create } from 'zustand'

type useAuthLayoutStore = {
  authenticated: boolean
  authenticate: (userName: string, password: string) => boolean

  isUsernameVerified: boolean
  verifyUserName: (userName: string) => boolean

  markUserAsNotAuthenticated: () => void
}

export const useAuthLayoutStore = create<useAuthLayoutStore>((set) => ({
  authenticated: false,
  isUsernameVerified: false,

  markUserAsNotAuthenticated() {
    set({ isUsernameVerified: false })
    set({ authenticated: false })
  },

  verifyUserName(userName: string) {
    set({ isUsernameVerified: false })
    if (userName.toLowerCase() === import.meta.env.VITE_VERIFIED_USER_NAME.toLowerCase()) {
      set({ isUsernameVerified: true })
      return true
    }
    return false
  },

  authenticate(userName, password) {
    set({ authenticated: false })
    if (
      userName.toLowerCase() === import.meta.env.VITE_VERIFIED_USER_NAME.toLowerCase() &&
      password === import.meta.env.VITE_VERIFIED_PASSWORD
    ) {
      set({ authenticated: true })

      return true
    }

    return false
  },
}))
