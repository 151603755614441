import { api } from '@/config/api'
import { JobPosting } from '@/presentation/JobPosts/types/JobPosting'
import { trimId } from '../utils/trimId'

export const getJobPostingList = async () => {
  const accountId = localStorage.getItem('accountId')
  const res = await api.post<JobPosting[]>('/getJobPostingList', {
    accountId: accountId,
  })

  res.data.forEach((item) => {
    item.Id = trimId(item.Id)
  })

  return res.data
}
