import { api } from '@/config/api'
import { JobProfile } from '@/presentation/JobPosts/types/JobProfile'
import { trimId } from '../utils/trimId'

export const getJobProfileListByAccountId = async (accountId: string) => {
  const res = await api.get<JobProfile[]>(`/getJobProfileListByAccountId?ac=${accountId}`).then((res) => {
    res.data.forEach((item) => {
      item.Id = trimId(item.Id)
    })
    return res
  })

  return res.data
}
