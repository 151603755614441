import { useState } from 'react'
import { checkSignInMethods } from '@/common/services/checkSignInMethods' // Create this service
import { useService } from '@/common/hooks/useService'
import { loginUsingEmailAndPassword } from '@/common/services/loginUsingEmailAndPassword'
import {
  signUpUsingEmailAndPassword,
  updatePasswordForExistingUser,
} from '@/common/services/signUpUsingEmailAndPassword'
import { User } from 'firebase/auth'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { Spinner } from '@nextui-org/react'
import { findContactByEmail } from '@/common/services/findContactByEmail'
import './CustomInput.scss'

export type Props = {
  onUserAuthenticated?: (user: User) => void
  hideOtherOptions?: () => void
  changeToSignUp?: () => void
}

export function LoginUsingUserNameAndPassword(props: Props) {
  const [step, setStep] = useState<'login' | 'signUp' | 'showSetPasswordMessage' | 'enterEmail'>('enterEmail')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [error, setError] = useState('')
  const [showResetPasswordMessage, setShowResetPasswordMessage] = useState(false)

  const { call: findContact, isLoading: findContactLoading } = useService(findContactByEmail)

  const { call: checkLoginMethods, isLoading: checkLoginMethodsLoading } = useService(checkSignInMethods)

  const { call: login, isLoading: loginLoading, error: loginError } = useService(loginUsingEmailAndPassword)

  const { call: signUp, isLoading: signUpLoading, error: signUpError } = useService(signUpUsingEmailAndPassword)

  const {
    call: resetPassword,
    isLoading: resetPasswordLoading,
    error: resetPasswordError,
  } = useService(updatePasswordForExistingUser)

  const handleEmailSubmit = async () => {
    try {
      // Check email in Salesforce
      const res = await findContact(email)

      if (res.data.length === 0) {
        setError('User not found in Salesforce database.')
        return
      }

      // Check if user is already signed up in the firebase and their methods to sign in
      const signInMethods = await checkLoginMethods(email)

      if (signInMethods.some((method) => method === 'Password')) {
        setStep('login')
      } else if (signInMethods.length === 0) {
        setStep('signUp')
      } else {
        resetPassword(email).then(() => {
          setStep('showSetPasswordMessage')
        })
      }
    } catch {
      setError('Error occurred while checking the user.')
    } finally {
      props.hideOtherOptions?.()
    }
  }

  return (
    <>
      <div className="text-red-500">
        {(loginError as { message: string })?.message}
        {(signUpError as { message: string })?.message}
        {(resetPasswordError as { message: string })?.message}
        {error}
      </div>

      {step === 'enterEmail' && (
        <div className="w-full">
          <div className="flex items-center gap-0 mt-2 relative w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <input
              key="enter-email-phase-email-input"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="Enter your email address"
              required
              className="ring-0 border-0 mx-1 bg-transparent focus:ring-0 w-4/5 h-full custom-input-fill"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleEmailSubmit}
              className="rounded absolute right-0 w-1/5 border-1 hover:text-warning-400 transition-all flex items-center justify-center h-full"
            >
              {findContactLoading || checkLoginMethodsLoading || resetPasswordLoading ? (
                <Spinner size="sm" color="warning" />
              ) : (
                <MdOutlineNavigateNext size={24} />
              )}
            </button>
          </div>
        </div>
      )}

      {step === 'signUp' && (
        <div>
          <div className="flex items-center gap-0 mt-2 relative w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="ring-0 border-0 focus:ring-0 w-full h-full mx-1 bg-transparent custom-input-fill"
            />
          </div>
          <div className="flex items-center gap-0 mt-2 relative w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <input
              type="password"
              placeholder="Re-enter password"
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
              className="ring-0 border-0 focus:ring-0 w-full h-full mx-1 bg-transparent custom-input-fill"
            />
          </div>

          <button
            onClick={() => {
              signUp(email, password, rePassword).then((data) => {
                props.onUserAuthenticated?.(data.user)
              })
            }}
            className="w-full bg-orange-400 hover:bg-slate-900 hover:text-slate-100 text-white transition-all text-center py-3 font-medium rounded-xl mt-2"
          >
            {signUpLoading ? <Spinner size="sm" color="white" /> : 'Sign up'}
          </button>
        </div>
      )}

      {step === 'login' && (
        <div>
          <div className="flex items-center gap-0 mt-2 relative w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="Enter you email address"
              required
              className="ring-0 border-0 focus:ring-0 w-full h-full mx-1 bg-transparent custom-input-fill"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="flex items-center gap-0 mt-2 relative w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="ring-0 border-0 focus:ring-0 w-full h-full mx-1 bg-transparent custom-input-fill"
            />
          </div>

          {!showResetPasswordMessage && (
            <button
              onClick={() => {
                resetPassword(email).then(() => {
                  setShowResetPasswordMessage(true)
                })
              }}
              className="hover:text-warning-400 text-slate-500 transition-all text-center py-3 text-sm"
            >
              {resetPasswordLoading ? <Spinner size="sm" color="white" /> : 'Reset password'}
            </button>
          )}

          <div className="text-warning-400">
            {showResetPasswordMessage && "We've sent you an email to reset your password."}
          </div>

          <button
            onClick={() => {
              login(email, password).then((data) => {
                props.onUserAuthenticated?.(data.user)
              })
            }}
            className="w-full bg-orange-400 hover:bg-slate-900 hover:text-slate-100 text-white transition-all text-center py-3 font-medium rounded-xl mt-2"
          >
            {loginLoading ? <Spinner size="sm" color="white" /> : 'Login'}
          </button>
        </div>
      )}

      {step === 'showSetPasswordMessage' && (
        <div className="text-gray-900 max-w-[280px]">
          Because you're already signed up, you need to set a password to login. we will send you an email to set your
          password.
          <br />
          Whenever you set your password comeback to this page and click on the below button.
          <br />
          <button
            onClick={() => {
              setStep('login')
            }}
            className="w-full bg-orange-400 hover:bg-slate-900 hover:text-slate-100 text-white transition-all text-center py-3 font-medium rounded-xl mt-2"
          >
            Go back to login
          </button>
        </div>
      )}
    </>
  )
}
