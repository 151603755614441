import { ReportPanel } from './ReportPanel'
import { HighlightPanel } from './HighlightPanel'
import { useShowcasesPageStore } from '../../store/jobPostsPageStore.ts'
import { UResumePanel } from '@/common/components/Overview/UResumePanel'

export const ShowcaseModalBody = () => {
  const { selectedShowCase } = useShowcasesPageStore()

  return (
    <main>
      {/* TODO => make everything single page. */}
      <div className="w-full">
        <ReportPanel />
        <HighlightPanel />

        <UResumePanel
          PDFViewerProps={{
            url: selectedShowCase?.Resume_URL__c ?? '',
          }}
          ResumeMatchScoreRubricProps={{
            data: {
              id: selectedShowCase?.Id ?? '',
              resumeMatchDescription: selectedShowCase?.Job_Applicant__r.AI_Resume_Match_Description__c ?? '',
            },
          }}
        />
      </div>
    </main>
  )
}
