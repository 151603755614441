export const getDeviceType = () => {
  const ua = navigator.userAgent
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)) {
    return 'Mobile'
  } else if (/tablet|ipad|playbook|silk/i.test(ua)) {
    return 'Tablet'
  } else {
    return 'Desktop'
  }
}
