import { api } from '@/config/api'

export type RequestData = {
  submittedBy: string

  jobTitle?: string
  jobDescription?: string
  jobLocation?: string
  positionType?: string

  jobLink?: string

  jobFile?: string
}

export const notifyNewJobPostByEmail = async (requestData: RequestData) => {
  return await api.post('/notifyNewJobPostByEmail', requestData)
}
