import { useAppLayoutStore } from '@/layout/appLayout/AppLayout.store.ts'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import UrrlyLogo from '@/assets/urrly-logo.png'
import { classNames } from '@/common/utils/classNames.ts'
import EasyHealthImage from '@/assets/easyhealthsquare.png'
import { VscSignOut } from 'react-icons/vsc'
import { signOut } from 'firebase/auth'
import { auth } from '@/config/firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { useShowcasesPageStore } from '@/presentation/JobPosts/store/jobPostsPageStore.ts'
import { Select, SelectItem } from '@nextui-org/react'
import { useService } from '@/common/hooks/useService.ts'
import { getAllAccountList } from '@/common/services/getAllAccountList.ts'

const isContactFromUrrly = (email: string) => {
  const domain = email.split('@')[1]
  return domain?.toLowerCase() === 'urrly.com'
}

export const MobileSidebar = () => {
  const {
    setIsSidebarOpen,
    isSidebarOpen,
    navigationItems,
    contact,
    getRecentlyVisitedJobPostsList,
    addToRecentlyVisitedJobPostsList,
    clearRecentlyVisitedJobPostsList,
    markNavigationItemAsCurrent,
  } = useAppLayoutStore()
  const { getShowcaseListByJobPostId, setSelectedJobPostId } = useShowcasesPageStore()

  const nav = useNavigate()

  const { jobPostId } = useParams()

  const { call: getAccountList, data: accountList, isLoading: isAccountListLoading } = useService(getAllAccountList)
  const [selectedAccount, setSelectedAccount] = useState<string[]>([localStorage.getItem('accountId') ?? ''])

  useEffect(() => {
    getAccountList()
  }, [])

  useEffect(() => {
    const path = window.location.pathname
    const navigationItem = navigationItems.find((item) => item.href === path)
    if (navigationItem) {
      markNavigationItemAsCurrent(navigationItem.name)
    }
  }, [])

  return (
    <Transition.Root show={isSidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 xl:hidden" onClose={setIsSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" className="-m-2.5 p-2.5" onClick={() => setIsSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <img className="h-8 w-auto" src={UrrlyLogo} alt="Your Company" />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigationItems.map((item) => (
                          <li
                            key={item.name}
                            onClick={() => {
                              nav(item.href)
                              setIsSidebarOpen(false)
                              markNavigationItemAsCurrent(item.name)
                            }}
                            className="cursor-pointer"
                          >
                            <a
                              className={classNames(
                                item.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              )}
                            >
                              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">Roles you visited recently</div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {getRecentlyVisitedJobPostsList().map((recentItem) => (
                          <li key={recentItem.Name}>
                            <a
                              className={classNames(
                                recentItem.Id === jobPostId
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center cursor-pointer'
                              )}
                              onClick={() => {
                                nav(`/app/job-posts/${recentItem.Id}`)
                                setSelectedJobPostId(recentItem.Id)
                                getShowcaseListByJobPostId(recentItem.Id)
                                addToRecentlyVisitedJobPostsList(recentItem)
                              }}
                            >
                              <span
                                className={`flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border  bg-gray-800 text-[0.625rem] font-medium   ${recentItem.Status__c === 'Active' ? 'border-green-400 text-green-400' : 'text-red-400 border-red-400'}`}
                              >
                                {recentItem.Name.slice(0, 1).toUpperCase()}
                              </span>

                              <span className="truncate">{recentItem.Name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>

                    {/* REGION => FOOTER START */}
                    <div className="-mx-6 mt-auto">
                      <li className="cursor-pointer px-6 w-full">
                        {isContactFromUrrly(contact?.Email ?? '') && accountList?.length && (
                          <Select
                            label="Account"
                            variant="flat"
                            selectionMode="single"
                            placeholder="Select an account"
                            className="w-full sm:w-full"
                            classNames={{
                              trigger: 'bg-slate-800',
                              listbox: 'bg-slate-800',
                              popoverContent: 'bg-slate-800',
                            }}
                            selectedKeys={selectedAccount}
                            isLoading={isAccountListLoading}
                            isDisabled={isAccountListLoading}
                            onChange={(e) => {
                              setSelectedAccount([e.target.value])
                              clearRecentlyVisitedJobPostsList()
                              localStorage.setItem('accountId', e.target.value)
                              setSelectedJobPostId('')
                              setIsSidebarOpen(false)
                              nav('/app')
                            }}
                          >
                            {accountList.map((account) => (
                              <SelectItem key={account.Id} value={account.Id}>
                                {account.Name}
                              </SelectItem>
                            ))}
                          </Select>
                        )}
                      </li>
                      <li className=" cursor-pointer">
                        <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800  cursor-pointer">
                          <img className="h-6 w-6 rounded-full bg-gray-800" src={EasyHealthImage} alt="" />
                          <span className="sr-only">Your profile</span>
                          <div className="flex flex-col">
                            <span aria-hidden="true">{contact?.Name}</span>
                            <span className="text-xs font-thin">{contact?.Email}</span>
                          </div>
                        </a>
                      </li>

                      <li
                        onClick={() => {
                          signOut(auth)
                          localStorage.removeItem('accountId')
                        }}
                        className=" cursor-pointer"
                      >
                        <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800 cursor-pointer">
                          <VscSignOut className="h-6 w-6 shrink-0" aria-hidden="true" />
                          <span aria-hidden="true">Sign out</span>
                        </a>
                      </li>
                    </div>

                    {/* REGION => FOOTER END */}
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
