import { useEffect } from 'react'
import { useJobProfilesPageStore } from '../store/jobProfilesPageStore'
import { JobProfileList } from '../components/JobProfilesList'
import { useAppLayoutStore } from '@/layout/appLayout/AppLayout.store'

export function JobProfilesPage() {
  const { getJobProfiles, searchJobProfiles } = useJobProfilesPageStore()

  useEffect(() => {
    getJobProfiles()
  }, [])

  // Search functionality
  const { searchKeywords, setSearchKeywords } = useAppLayoutStore()

  useEffect(() => {
    searchJobProfiles(searchKeywords)
  }, [searchKeywords])

  useEffect(() => {
    setSearchKeywords('')
  }, [])
  //

  return (
    <main>
      <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:h-[89px]">
        <h3 className="text-2xl font-bold tracking-tight">Job profiles</h3>

        <div className="flex items-center gap-x-4"></div>
      </header>
      <JobProfileList />
    </main>
  )
}
