import { useNavigate } from 'react-router-dom'

export function NotFoundPage() {
  const nav = useNavigate()

  return (
    <div className="w-screen bg-slate-900">
      <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8 text-background h-screen">
        <p className="text-base font-semibold leading-8 text-warning-400">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Page not found :(</h1>
        <p className="mt-6 text-base leading-7 text-indigo-400">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10">
          <a
            href=""
            className="text-sm font-semibold leading-7 text-warning-400"
            onClick={() => {
              nav('/auth')
            }}
          >
            <span aria-hidden="true">&larr;</span> Back to home
          </a>
        </div>
      </main>
    </div>
  )
}
