import {
  NewRubricEvaluationCriteria,
  RubricEvaluationCriteria,
} from '@/common/types/RubricTypes/RubricEvaluationCriteria.ts'

export const RubricEvaluationCriterionMapper = (
  type: RubricEvaluationCriteria['type'],
  evaluationCriterion?: RubricEvaluationCriteria[]
) => {
  return (
    evaluationCriterion?.map((el) => ({
      ...el,
      type,
    })) ?? []
  )
}

export const NewRubricEvaluationCriterionMapper = (
  type: RubricEvaluationCriteria['type'],
  evaluationCriterion?: NewRubricEvaluationCriteria[]
) => {
  return (
    evaluationCriterion?.map((el) => ({
      score: el.score ?? 0,
      reason_description: el.reasonDescription,
      guideline: el.requirement,
      type,
    })) ?? []
  )
}
