import { auth } from '@/config/firebase'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { sendPasswordResetEmail } from 'firebase/auth'

export const signUpUsingEmailAndPassword = async (email: string, password: string, rePassword: string) => {
  if (password !== rePassword) {
    throw new Error('Passwords do not match.')
  }

  return await createUserWithEmailAndPassword(auth, email, password)
}

export const updatePasswordForExistingUser = async (email: string) => {
  // Send password reset email
  return await sendPasswordResetEmail(auth, email)
}
