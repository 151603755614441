import { Button } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

export default function BottomBanner() {
  const nav = useNavigate()

  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
      <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-warning-400 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p className="text-sm leading-6 text-white">
          <a href="#">
            <strong className="font-semibold mx-2">You're not logged in!</strong>
            For arranging an interview with the applicant please login to your dashboard.&nbsp;
            <span aria-hidden="true">&rarr;</span>
          </a>
        </p>
        <Button
          color="warning"
          variant="faded"
          onClick={() => {
            nav('/')
          }}
          className="bg-gray-900"
        >
          Go back to login page.
        </Button>
      </div>
    </div>
  )
}
