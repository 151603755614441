import { Line } from '@ant-design/charts'
import { Analytics } from '../store/analyticsPageStore'

type Props = {
  data: Analytics['totalShowcasesPerDay']
}

export const ShowcasesPerDayChart = (props: Props) => {
  const config = {
    data: props.data.map((el) => ({ ...el, Date: new Date(el.Date) })),
    xField: 'Date',
    yField: 'Showcases',
    shapeField: 'smooth',

    style: {
      radiusTopLeft: 8,
      radiusTopRight: 8,
      stroke: '#eab308',
      lineJoin: 'round',
    },
  }

  return (
    <Line
      className="max-h-full p-0"
      {...config}
      scale={{
        x: {
          type: 'time',
          mask: 'MM-DD',
          tickCount: props.data.length,
        },
      }}
      axis={{
        x: {
          labelFill: '#fff',
          lineStroke: '#64748b',
          line: true,
          animate: true,
          labelAutoWrap: false,
          labelAutoRotate: false,
          transform: [{ type: 'fixedOverlap' }],
          labelFormatter: (date: Date, index: number) => {
            if (index === 0) {
              return '           ' + date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' })
            }
            if (index === props.data.length - 1) {
              return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' }) + '           '
            }
            return ''
          },
        },
        y: {
          labelFill: '#fff',
          grid: true,
          gridStroke: '#fff',
        },
      }}
    />
  )
}
