import { create } from 'zustand'
import {
  BookOpenIcon,
  BriefcaseIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
} from '@heroicons/react/24/outline'
import { Contact } from '@/common/types/Contact'
import { JobPosting } from '@/presentation/JobPosts/types/JobPosting'

export type NavigationItem = {
  name: string // should be unique among other items
  href: string
  icon: typeof FolderIcon // react function component reference
  current: boolean
}

export type RecentItem = {
  id: number // should be unique among other items
  name: string
  href: string
  initial: string
  current: boolean
}

type AppLayoutStore = {
  isSidebarOpen: boolean
  toggleSidebar: () => void
  setIsSidebarOpen: (isOpen: boolean) => void
  currentSidebarItem: string // points to the name of the current sidebar item
  navigationItems: NavigationItem[]
  recentItems: RecentItem[]
  markNavigationItemAsCurrent: (name: string) => void
  setRecentItems: (recentItems: RecentItem[]) => void
  addToRecentItem: (recentItem: RecentItem) => void
  contact: Contact | null
  setContact: (contact: Contact) => void

  recentlyVisitedJobPostList: JobPosting[]
  getRecentlyVisitedJobPostsList: () => JobPosting[]
  clearRecentlyVisitedJobPostsList: () => void
  addToRecentlyVisitedJobPostsList: (jobPosting: JobPosting) => void

  searchKeywords: string
  setSearchKeywords: (keywords: string) => void
}

export const useAppLayoutStore = create<AppLayoutStore>((set, get) => ({
  currentSidebarItem: 'Showcases',
  isSidebarOpen: false,
  navigationItems: [
    {
      name: 'Showcases',
      href: '/app/showcases',
      icon: FolderIcon,
      current: false,
    },
    {
      name: 'Job posts',
      href: '/app/job-posts',
      icon: BriefcaseIcon,
      current: true,
    },
    {
      name: 'Job profiles',
      href: '/app/job-profiles',
      icon: BookOpenIcon,
      current: false,
    },
    { name: 'Analytics', href: '/app/analytics', icon: ServerIcon, current: false },
    { name: 'Settings', href: '', icon: SignalIcon, current: false },
    { name: 'Contact us', href: '', icon: GlobeAltIcon, current: false },
  ],
  toggleSidebar: () => set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),

  setIsSidebarOpen: (isOpen) => set({ isSidebarOpen: isOpen }),

  markNavigationItemAsCurrent(name: string) {
    set((state) => ({
      navigationItems: state.navigationItems.map((item) => ({
        ...item,
        current: item.name === name,
      })),
    }))

    set(() => ({
      currentSidebarItem: name,
    }))
  },

  recentItems: [],

  setRecentItems(recentItems: RecentItem[]) {
    localStorage.setItem('recentItems', JSON.stringify(recentItems))
    set(() => ({ recentItems }))
  },

  /**
   *
   * @description if the item is already in the list, we have to move it to the top of the list
   * @description if the item is not in the list, we have to add it to the top of the list and remove the last one (if the list length is bigger than 3)
   */
  addToRecentItem(item: RecentItem) {
    const recentItems = JSON.parse(JSON.stringify(get().recentItems))
    const setRecentItems = get().setRecentItems

    if (recentItems.find((i: RecentItem) => i.id === item.id)) {
      recentItems.sort((a: RecentItem, b: RecentItem) => {
        if (a.id === item.id) return -1
        if (b.id === item.id) return 1
        return 0
      })

      setRecentItems(recentItems)
    } else {
      const newRecentItems = [item, ...recentItems.slice(0, 2)]
      setRecentItems(newRecentItems)
    }
  },

  getRecentItems() {},

  contact: null,
  setContact(contact) {
    localStorage.setItem('contact', JSON.stringify(contact))
    set({ contact })
  },

  recentlyVisitedJobPostList: [],
  clearRecentlyVisitedJobPostsList() {
    localStorage.setItem('recentlyVisitedJobPostList', '[]')
    set({ recentlyVisitedJobPostList: [] })
  },
  getRecentlyVisitedJobPostsList() {
    try {
      const storeData = get().recentlyVisitedJobPostList
      const localStorageData = localStorage.getItem('recentlyVisitedJobPostList')

      if (storeData.length > 0) {
        return storeData
      } else {
        return localStorageData ? (JSON.parse(localStorageData) as JobPosting[]) : []
      }
    } catch (_) {
      return []
    }
  },
  addToRecentlyVisitedJobPostsList(jobPost: JobPosting) {
    try {
      const storeData =
        get().recentlyVisitedJobPostList.length > 0
          ? (JSON.parse(JSON.stringify(get().recentlyVisitedJobPostList)) as JobPosting[])
          : (JSON.parse(localStorage.getItem('recentlyVisitedJobPostList') ?? '[]') as JobPosting[])

      if (storeData.length >= 3 && storeData.findIndex((i) => i.Id === jobPost.Id) === -1) {
        storeData.pop()
      }

      if (storeData.findIndex((i) => i.Id === jobPost.Id) === -1) {
        storeData.unshift(jobPost)
      } else {
        storeData.sort((a, b) => {
          if (a.Id === jobPost.Id) return -1
          if (b.Id === jobPost.Id) return 1
          return 0
        })
      }

      localStorage.setItem('recentlyVisitedJobPostList', JSON.stringify(storeData))

      set({ recentlyVisitedJobPostList: storeData })
    } catch (_) {
      const storeData = [jobPost]
      localStorage.setItem('recentlyVisitedJobPostList', JSON.stringify(storeData))
      set({ recentlyVisitedJobPostList: storeData })
    }
  },

  /**
   *
   * @description Because of the fact that the search input is common for all the pages, we have to store the search keywords in the global store
   * @description This way, we can access the search keywords from any page
   */
  searchKeywords: '',
  setSearchKeywords: (keywords) => set(() => ({ searchKeywords: keywords })),
}))
