export const seekToTime = (time: string) => {
  const timeParts = time.split(':')
  const hoursToSeconds = parseInt(timeParts[0], 10) * 3600
  const minutesToSeconds = parseInt(timeParts[1], 10) * 60
  const seconds = parseFloat(timeParts[2]) // Includes milliseconds

  const totalTimeInSeconds = hoursToSeconds + minutesToSeconds + seconds

  return totalTimeInSeconds
}
