import { create } from 'zustand'
import { Showcase } from '../types/Showcase'
import { api } from '@/config/api'
import { JobPosting } from '../types/JobPosting'
import { getJobPostingList } from '@/common/services/getJobPostingList'
import { Selection } from '@nextui-org/react'
import { trimId } from '@/common/utils/trimId'

type ShowcasesPageStore = {
  originalShowcaseList?: Showcase[]
  showcaseList: Showcase[]
  loading: boolean

  sortJobPostsField: Selection
  setSortJobPostsField: (field: Selection) => void
  searchJobPost: (key: string) => void

  showcaseListLoading: boolean
  setShowcaseList: (showCaseList: Showcase[]) => void

  showcaseListForJobPost: Showcase[]
  getShowcaseListByJobPostId: (jobPostId?: string) => Promise<Showcase[]> // gets showcases from salesforce

  getShowCaseListByAccountId: () => Promise<Showcase[]>
  showcaseSortField: 'score' | 'createdAt' | 'status'
  sortShowcaseList: (sortField: 'score' | 'createdAt' | 'status', order: 'asc' | 'desc') => void

  selectedJobPostId?: string
  setSelectedJobPostId: (roleId: string | undefined) => void
  getSelectedJobPostId: () => string | undefined

  selectedShowCase?: Showcase | null
  setSelectedShowcase: (showcase: Showcase | null) => void

  originalJobPostList?: JobPosting[]
  jobPostList?: JobPosting[]
  setJobPostList: (jobPostList: JobPosting[]) => void
  getJobPosts: () => Promise<JobPosting[]>
  sortJobPost: (sortField: string) => void

  // jobProfileList?: JobProfile[]
  // getJobProfiles: () => Promise<JobProfile[]>

  setShowcaseListForJobPost: (showCaseList: Showcase[]) => void

  searchShowcase: (key: string) => void
}

const sortJobPostsBasedOnStatus = (jobPostList: JobPosting[]) => {
  return jobPostList.sort((a) => {
    if (a.Status__c?.toLowerCase() === 'active') {
      return -1
    }

    return 1
  })
}

const sortShowcasesBasedOnStatus = (showcaseList: Showcase[]) => {
  const showcaseStatusValueTable: Record<string, number> = {
    'send for client review': 2,
    client_approved: 1,
    client_rejected: 0,
  }

  return showcaseList.sort((a, b) => {
    return (
      showcaseStatusValueTable[b.Status__c?.toLowerCase() ?? 'pending'] -
      showcaseStatusValueTable[a.Status__c?.toLowerCase() ?? 'pending']
    )
  })
}

export const useShowcasesPageStore = create<ShowcasesPageStore>((set, get) => ({
  loading: false,
  getShowcaseListError: null,
  sortJobPostsField: new Set(['status']),
  setSortJobPostsField(field: Selection) {
    get().sortJobPost([...field][0] as string)
    set({ sortJobPostsField: field })
  },

  // REGION START => Handling showcaseList logic.
  originalShowcaseList: [],
  showcaseList: [],
  showcaseListLoading: false,
  showcaseListForJobPost: [],

  setShowcaseListForJobPost: (showCaseList) => set({ showcaseListForJobPost: showCaseList }),

  setShowcaseList: (showCaseList) => set({ showcaseList: showCaseList }),

  async getShowcaseListByJobPostId(jobPostId = get().selectedJobPostId) {
    set({ showcaseListLoading: true })

    const res = await api
      .post<Showcase[]>('/getShowcaseListByJobPostId', {
        jobId: jobPostId,
      })
      .then((res) => {
        res.data.forEach((item) => {
          item.Id = trimId(item.Id)
        })
        return res
      })

    set({
      showcaseListForJobPost: res.data.filter(
        // REFACTOR => Consider a refactor for such a behavior in our application.
        // NOTE => to exclude inner statuses such as pending and recruiter reviewed.
        (el) => el.Status__c?.toLowerCase() !== 'pending' && el.Status__c?.toLowerCase() !== 'recruiter reviewed'
      ),
    })
    set({ showcaseListLoading: false })
    return res.data
  },

  async getShowCaseListByAccountId() {
    set({ showcaseListLoading: true })

    const accountId = localStorage.getItem('accountId')

    const res = await api.get<Showcase[]>(`/getShowcaseListByAccountId?ac=${accountId}`).then((res) => {
      res.data.forEach((item) => {
        item.Id = trimId(item.Id)
      })
      return res
    })

    set({
      showcaseList: res.data.filter(
        // REFACTOR => Consider a refactor for such a behavior in our application.
        // NOTE => to exclude inner statuses such as pending and recruiter reviewed.
        (el) => el.Status__c?.toLowerCase() !== 'pending' && el.Status__c?.toLowerCase() !== 'recruiter reviewed'
      ),
      originalShowcaseList: res.data.filter(
        // REFACTOR => Consider a refactor for such a behavior in our application.
        // NOTE => to exclude inner statuses such as pending and recruiter reviewed.
        (el) => el.Status__c?.toLowerCase() !== 'pending' && el.Status__c?.toLowerCase() !== 'recruiter reviewed'
      ),
    })
    set({ showcaseListLoading: false })
    return res.data
  },

  showcaseSortField: 'createdAt',
  sortShowcaseList(sortField: 'score' | 'createdAt' | 'status', order: 'asc' | 'desc') {
    set({ showcaseSortField: sortField })

    if (sortField === 'status') {
      set({
        showcaseList: sortShowcasesBasedOnStatus(get().showcaseList),
      })
    }

    if (sortField === 'score') {
      set({
        showcaseList: get().showcaseList.sort((a, b) => {
          if (order === 'asc') {
            return a.Resume_Match_Score__c - b.Resume_Match_Score__c
          }

          return b.Resume_Match_Score__c - a.Resume_Match_Score__c
        }),
      })
    }

    if (sortField === 'createdAt') {
      set({
        showcaseList: get().showcaseList.sort((a, b) => {
          if (order === 'asc') {
            return (
              new Date(a.Job_Applicant__r.CreatedDate).getTime() - new Date(b.Job_Applicant__r.CreatedDate).getTime()
            )
          }

          return new Date(b.Job_Applicant__r.CreatedDate).getTime() - new Date(a.Job_Applicant__r.CreatedDate).getTime()
        }),
      })
    }
  },
  // REGION END => End of handling showcaseList logic.

  selectedJobPostId: '',
  getSelectedJobPostId: () => get().selectedJobPostId,
  setSelectedJobPostId: (jobPostId) => set({ selectedJobPostId: jobPostId }),

  selectedShowCase: null,
  setSelectedShowcase: (showcase) => set({ selectedShowCase: showcase }),

  originalJobPostList: [],
  jobPostList: [],
  setJobPostList: (jobPostList) => set({ jobPostList }),
  async getJobPosts() {
    set({ loading: true })

    const res = await getJobPostingList()

    if (res) {
      set({ jobPostList: sortJobPostsBasedOnStatus(res ?? []) })
      set({ originalJobPostList: res })
    }

    set({ loading: false })
    return res
  },
  searchJobPost(key: string) {
    if (!key) {
      set({ jobPostList: get().originalJobPostList })
      return
    }

    set({
      jobPostList: get().originalJobPostList?.filter(
        (el) =>
          el.Name.toLowerCase().includes(key.toLowerCase()) || el.Status__c.toLowerCase().includes(key.toLowerCase())
      ),
    })
  },
  sortJobPost(sortField: string) {
    if (sortField === 'status') {
      set({
        jobPostList: sortJobPostsBasedOnStatus(get().jobPostList ?? []),
      })
    }
    if (sortField === 'createdAt') {
      set({
        jobPostList: get().jobPostList?.sort((a, b) => {
          return new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime()
        }),
      })
    }
  },

  searchShowcase(key: string) {
    if (!key) {
      set({ showcaseList: get().originalShowcaseList })
      return
    }

    set({
      showcaseList: get().originalShowcaseList?.filter(
        (el) =>
          el.Job_Applicant__r.Name.toLowerCase().includes(key.toLowerCase()) ||
          el.Job_Post__r.Name.toLowerCase().includes(key.toLowerCase())
      ),
    })
  },
  // jobProfileList: [],
  // async getJobProfiles() {
  //   set({ loading: true })

  //   const res = await getJobProfileList()

  //   if (res) {
  //     set({ jobProfileList: res })
  //   }

  //   set({ loading: false })
  //   return res
  // },
}))
