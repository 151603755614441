import { Button, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ShowcaseModalBody } from './ShowcaseModalBody'
import { useShowcasesPageStore } from '../../store/jobPostsPageStore.ts'
import { IoShareSocialSharp } from 'react-icons/io5'
import { UAlert } from '@/common/components/UAlert.tsx'
import { useParams } from 'react-router-dom'
import { useService } from '@/common/hooks/useService.ts'
import { getShareSc } from '@/common/services/getShareSc.ts'
import { SentryHelper } from '@/common/utils/SentryHelper.ts'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onClose?: () => void
}

export const ShowcaseModal = ({ isOpen, setIsOpen, ...props }: Props) => {
  const [size /*setSize*/] = useState<'5xl' | 'full'>('full')

  const { selectedShowCase } = useShowcasesPageStore()

  const [displayAlert, setDisplayAlert] = useState(false)

  const { showcaseId } = useParams()

  const { call: getShareId, isLoading } = useService(getShareSc)

  useEffect(() => {
    if (selectedShowCase?.Id) {
      console.log('TRIGGERED')
      SentryHelper.logShowcaseView(
        `Showcase Modal Opened: ${selectedShowCase?.Job_Applicant__r?.Name ?? ''}`,
        selectedShowCase?.Id ?? '',
        selectedShowCase?.Job_Post__r?.Name ?? ''
      )
    }
  }, [selectedShowCase])

  return (
    <>
      <UAlert message="Copied to clipboard." open={displayAlert} type="success" setOpen={setDisplayAlert} />
      <Modal size={size} isOpen={isOpen} hideCloseButton onClose={() => setIsOpen(false)} className="bg-gray-900">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex gap-1 items-center justify-between">
                <div className="text-white">Showcase: {selectedShowCase?.Job_Applicant__r?.Name}</div>

                <div className="flex items-center gap-1">
                  <Button
                    isIconOnly
                    variant="light"
                    color="secondary"
                    size="sm"
                    isLoading={isLoading}
                    onClick={() => {
                      getShareId(selectedShowCase?.Id ?? showcaseId).then((res) => {
                        navigator.clipboard
                          .writeText(`${window.location.origin}/showcase?sc=${res.sc}`)
                          .then(() => {
                            setDisplayAlert(true)
                          })
                          .catch((error) => console.error(error))
                      })
                    }}
                  >
                    <IoShareSocialSharp className="h-6 w-6" />
                  </Button>
                  <Button
                    isIconOnly
                    variant="light"
                    color="secondary"
                    size="sm"
                    onClick={() => {
                      onClose && onClose()
                      props?.onClose && props.onClose()
                    }}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </Button>
                  {/* Close icon */}
                </div>
              </ModalHeader>
              <ModalBody
                className={`overflow-y-auto overflow-x-hidden ${size === '5xl' ? 'max-h-[calc(100vh-312px)] min-h-[calc(100vh-312px)]' : 'h-full'}`}
              >
                <ShowcaseModalBody />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
