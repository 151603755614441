import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Checkbox, Slider } from '@nextui-org/react'
import { FaPlus } from 'react-icons/fa6'
import { RubricGuideline } from '@/presentation/JobPosts/types/JobPosting.ts'
import { useService } from '@/common/hooks/useService'
import { patchJobProfile } from '@/common/services/patchJobProfile'
import { useJobProfilesPageStore } from '../store/jobProfilesPageStore'
import { TableInput } from '@/presentation/JobPosts/components/TableInput'
import RubricTableToolbox from '@/presentation/JobPosts/components/RubricTableToolbox'
import { RelatedJobPostList } from '../components/RelatedJobPostList'
import { ULoadingAnimation } from '@/common/components/ULoading/ULoadingAnimation.tsx'

type RubricGuidelineC = { requirements: RubricGuideline[] }

const replaceNewlinesWithBr = (input: string): string => {
  return input.replace(/\\n/g, '')
}

export function JobProfilePage() {
  const { selectedJobProfile, getJobProfiles, setSelectedJobProfile, jobProfilesListLoading } =
    useJobProfilesPageStore()

  const { call: patchJobProfileById } = useService(patchJobProfile)

  const { jobProfileId } = useParams<{ jobProfileId: string }>()

  const [rubricGuideline, setRubricGuideline] = useState<RubricGuideline[]>([])

  useEffect(() => {
    if ((selectedJobProfile === null || selectedJobProfile === undefined) && jobProfileId) {
      // Fetch job profile by id
      getJobProfiles().then((jobProfile) => {
        const selectedJobProfile = jobProfile.find((jp) => jp.Id === jobProfileId)
        if (selectedJobProfile) {
          setSelectedJobProfile(selectedJobProfile)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (selectedJobProfile) {
      const rubricGuideline = JSON.parse(selectedJobProfile?.Rubric_Guideline__c ?? '{}') as RubricGuidelineC

      const rg =
        rubricGuideline?.requirements?.map((el) => ({
          ...el,
          scoringGuideline: replaceNewlinesWithBr(el.scoringGuideline),
          requirement: replaceNewlinesWithBr(el.requirement),
          importance: el.importance ?? 0,
          id: Math.random().toString(),
        })) ?? []

      setRubricGuideline(rg)
    }
  }, [selectedJobProfile])

  const [selectedGuidelines, setSelectedGuidelines] = useState<string[]>([])

  const handleDeleteGuideline = (selectedIds: string[]) => {
    // rubricGuideline
    const rubricGuidelinesClone = JSON.parse(JSON.stringify(rubricGuideline))
    const rubricGuidelineAfterDeletion = rubricGuidelinesClone?.filter(
      (el: RubricGuideline) => !selectedIds.includes(el.id)
    )
    setRubricGuideline(rubricGuidelineAfterDeletion)

    setSelectedGuidelines([])

    // Sync data with server whenver data modification is done by the user.
    if (selectedJobProfile?.Id) {
      patchJobProfileById(selectedJobProfile.Id, {
        Rubric_Guideline__c: JSON.stringify({ requirements: rubricGuidelineAfterDeletion }),
      })
    }
  }

  // REGION: Sync data with server whenver data modification is done by the user.

  useEffect(() => {
    const handleSync = () => {
      if (selectedJobProfile?.Id) {
        patchJobProfileById(selectedJobProfile.Id, {
          Rubric_Guideline__c: JSON.stringify({ requirements: rubricGuideline }),
        })
      }
    }

    const debounceTimeout = setTimeout(() => {
      handleSync()
    }, 3_000) // 10 seconds debounce

    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [rubricGuideline, selectedJobProfile?.Id])

  // REGION: Handle data modification

  const handleSettingMustHave = (id: string, isChecked: boolean) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              mustHave: isChecked,
            }
          : el2
      )
    )
  }

  const handleSettingImportance = (id: string, value: number) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              importance: value,
            }
          : el2
      )
    )
  }

  const handleCheckTableRow = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedGuidelines((prev) => [...prev, id])
    }
    if (!isChecked) {
      setSelectedGuidelines((prev) => prev.filter((sgl) => sgl !== id))
    }
  }

  const handleSettingRequirement = (id: string, value: string) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              requirement: value,
            }
          : el2
      )
    )
  }

  const handleSettingGuideline = (id: string, value: string) => {
    setRubricGuideline((prev) =>
      prev?.map((el2) =>
        el2.id === id
          ? {
              ...el2,
              scoringGuideline: value,
            }
          : el2
      )
    )
  }

  const handleAddingNewGuideline = () => {
    setRubricGuideline((prev) => [
      ...prev,
      {
        requirement: '',
        scoringGuideline: '',
        importance: 0,
        mustHave: false,
        id: Math.random().toString(),
      },
    ])
  }

  // REGION END: Handle data modification

  return jobProfilesListLoading ? (
    <div
      className={`divide-y w-full divide-white/5 h-[calc(100vh-64px)] flex flex-col gap-y-2 items-center justify-center overflow-y-hidden`}
    >
      <ULoadingAnimation />
      Loading...
    </div>
  ) : (
    <main className="h-[calc(100vh-64px)] overflow-y-auto">
      <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:h-[89px]">
        <h3 className="text-2xl font-bold tracking-tight">{selectedJobProfile?.Name}</h3>
      </header>

      <div className="min-h-max">
        <div className="w-full px-4 py-4 sm:px-6 lg:px-8">
          <h2 className="text-xl font-bold tracking-tight">Related job posts</h2>
          <div className="bg-slate-800 rounded-l-2xl rounded-r-2xl mt-8 max-h-[40vh] overflow-y-auto custom-showcases-card-scrollbar">
            <RelatedJobPostList jobProfileId={jobProfileId as string} />
          </div>
        </div>

        <div className="py-10 px-4 sm:px-6 lg:px-8 lg:py-6">
          <div className="flex items-center gap-x-4">
            <h2 className="text-xl font-bold tracking-tight">Guidelines</h2>
            <p className="text-sm text-gray-500">Click to edit each column</p>
          </div>
          {/*REGION TABLE*/}
          <div className="mt-8 sm:-mx-0 p-4 bg-slate-800 rounded-2xl">
            <table className="h-full w-full">
              <thead>
                <tr className="bg-slate-900 shadow-lg">
                  <th scope="col" className="w-[24px]  rounded-l-2xl">
                    <span className="sr-only">Select</span>
                  </th>

                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold rounded-r-2xl sm:rounded-r-none "
                  >
                    Requirement
                  </th>

                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                    <div className="flex items-center gap-x-2 min-w-max">Guideline</div>
                  </th>

                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                    Importance
                  </th>

                  <th
                    scope="col"
                    className="hidden px-3 text-center py-3.5 text-sm font-semibold sm:table-cell rounded-r-2xl"
                  >
                    Must have
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="h-1" key={Math.random().toString()}></tr>
                {rubricGuideline?.map((el) => (
                  <tr key={el.id} className="border-b-1 border-gray-200/10 last:border-0">
                    <td className="align-center max-w-6">
                      <dt className="sr-only">Select</dt>
                      <dd>
                        <Checkbox
                          size="sm"
                          className="max-w-6"
                          onChange={(eve) => handleCheckTableRow(el.id, eve.target.checked)}
                        />
                      </dd>
                    </td>

                    <td className="py-0 px-4 lg:px-2 text-sm font-normal sm:w-[256px] align-top pt-2">
                      <dt className="lg:hidden lg:sr-only font-bold text-gray-50">Requirement</dt>
                      <dd>
                        <TableInput
                          value={el.requirement}
                          onChange={(value) => handleSettingRequirement(el.id, value)}
                        />
                      </dd>

                      <dl className="lg:hidden">
                        <dt className="font-bold text-gray-50">Guideline</dt>
                        <dd>
                          <TableInput
                            value={el.scoringGuideline}
                            onChange={(value) => handleSettingGuideline(el.id, value)}
                          />
                        </dd>

                        <dt className="font-bold text-gray-50">Importance</dt>
                        <dd className="my-2">
                          <Slider
                            size="lg"
                            color="primary"
                            classNames={{
                              thumb: 'after:bg-gray-100',
                            }}
                            defaultValue={0}
                            value={el.importance as number}
                            className="w-full"
                            aria-label={'Importance'}
                            maxValue={100}
                            minValue={0}
                            step={1}
                            onChange={(value) => handleSettingImportance(el.id, value as number)}
                          />
                        </dd>

                        <dt className="sr-only font-bold text-gray-50">Must have</dt>
                        <dd className="sm:hidden pb-2 flex items-top gap-2 mt-8">
                          <div className="font-bold text-gray-50">Must have:</div>
                          <Checkbox
                            size="lg"
                            isSelected={el.mustHave}
                            onChange={(eve) => handleSettingMustHave(el.id, eve.target.checked)}
                          />
                        </dd>
                      </dl>
                    </td>

                    <td className="hidden max-w-0 py-0 pl-2 pr-2 text-sm font-normal sm:w-auto lg:table-cell align-top pt-2">
                      <TableInput
                        value={el.scoringGuideline}
                        onChange={(value) => handleSettingGuideline(el.id, value)}
                      />
                    </td>

                    <td className="hidden py-0 pl-2 pr-2 text-sm font-normal sm:w-[128px]  lg:table-cell pt-2 align-middle">
                      <Slider
                        size="lg"
                        color="primary"
                        classNames={{
                          thumb: 'after:bg-gray-100',
                        }}
                        defaultValue={100}
                        className="max-w-[128px]"
                        aria-label={'Importance'}
                        maxValue={100}
                        minValue={0}
                        step={1}
                        value={el.importance as number}
                        onChange={(value) => handleSettingImportance(el.id, value as number)}
                      />
                    </td>

                    <td className="hidden py-0 pl-2 pr-2 text-sm font-normal sm:w-[128px] sm:table-cell text-center">
                      <Checkbox
                        size="lg"
                        isSelected={el.mustHave}
                        onChange={(eve) => handleSettingMustHave(el.id, eve.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Button
              variant="light"
              className="flex items-center gap-x-1 font-light ml-6"
              onClick={() => handleAddingNewGuideline()}
            >
              <FaPlus />
              Add new guideline
            </Button>
          </div>
          {/*REGION TABLE*/}
        </div>
      </div>

      <RubricTableToolbox
        onDelete={() => handleDeleteGuideline(selectedGuidelines)}
        display={selectedGuidelines.length > 0}
      />
    </main>
  )
}
